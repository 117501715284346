import { Campaign } from "types";
import SuggestedCampaignCard from "../Rotunda/SuggestedCampaignCard";
import { Flex, Box, Text } from "@chakra-ui/react";

export default function FavCampaigns({ favoriteCampaigns }: { favoriteCampaigns: Campaign[] }) {

  let favcampaigns = <></>;
  if (favoriteCampaigns === undefined) {
  }
  favcampaigns = (
    <>
      {(favoriteCampaigns !== undefined && favoriteCampaigns.length === 0) ||
      favoriteCampaigns === undefined ? (
        <Text fontSize="xl" mt="25px">No favorites here yet...</Text>
      ) : (
        <Flex wrap="wrap" justifyContent="center">
          {(favoriteCampaigns as Campaign[]).map((favcampaign) => (
            <Box cursor="pointer" mr="20px" mt="45px">
              <SuggestedCampaignCard
                key={favcampaign}
                overrideFavButton={true}
                tags={favcampaign.policyPriorities}
                campaign={favcampaign}
                rotundapage={false}
                favorite={true}
              />
            </Box>
          ))}
        </Flex>
      )}
    </>
  );

  return (
    <Box width="82%" mt="25px">
      {favcampaigns}
    </Box>
  );
}

import axios from "axios";

import { Campaign, JobListing } from "types";
import { Thunkction } from "..";
import { auth } from "../../firebase";

export enum CampaignActionType {
  FETCH_CAMPAIGN = "FETCH_CAMPAIGN",
  FETCHED_CAMPAIGN = "FETCHED_CAMPAIGN",
  FAILED_CAMPAIGN = "FAILED_CAMPAIGN",
}

export type CampaignAction =
  | {
      type: CampaignActionType.FETCH_CAMPAIGN;
    }
  | {
      type: CampaignActionType.FETCHED_CAMPAIGN;
      payload: Campaign;
    }
  | {
      type: CampaignActionType.FAILED_CAMPAIGN;
      payload: string;
    };

const fetchCampaign = (): CampaignAction => {
  return {
    type: CampaignActionType.FETCH_CAMPAIGN,
  };
};

const fetchedCampaign = (payload: Campaign): CampaignAction => {
  return {
    type: CampaignActionType.FETCHED_CAMPAIGN,
    payload: payload,
  };
};

const failedFetchCampaign = (payload): CampaignAction => {
  return {
    type: CampaignActionType.FAILED_CAMPAIGN,
    payload: payload,
  };
};

export const getCampaign =
  (id: string, setLoading: boolean = false): Thunkction =>
  async (dispatch) => {
    if (setLoading) dispatch(fetchCampaign());
    try {
      const response = await axios.get("/campaign", {
        params: { uid: id, detailLevel: 1 },
      });
      await dispatch(fetchedCampaign(response.data as Campaign));
    } catch (err: any) {
      dispatch(failedFetchCampaign(err.message));
    }
  };

// export const getFavorites =
//   (campaign: Campaign): Thunkction =>
//   async () => {
//     try {
//       const response = await axios.post("/campaign/getFavorites", {
//         campaigns: campaign.favoriteCampaigns,
//         jobListings: campaign.favoriteJobs,
//         users: campaign.favoriteUsers,
//       });
//       campaign.favoriteUsers = response.data.users;
//       campaign.favoriteJobs = response.data.jobListings;
//       campaign.favoriteCampaigns = response.data.campaigns;
//     } catch (err: any) {}
//   };

export const updateCampaignProfile =
  (data: Partial<Campaign>): Thunkction =>
  async (dispatch) => {
    await axios.post("/campaign/profile", { ...data });
    await dispatch(getCampaign(auth.currentUser?.uid as string));
  };

// export const addFavoriteJob =
//   (job: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/profile", { favoriteJobs: [job] });
//   };
// export const addFavoriteCampaign =
//   (campaign: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/profile", { favoriteCampaigns: [campaign] });
//   };
// export const addFavoriteUser =
//   (user: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/profile", { favoriteUsers: [user] });
//   };
// export const deleteFavoriteUser =
//   (user: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/delete", { favoriteUsers: [user] });
//   };
// export const deleteFavoriteJob =
//   (job: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/delete", { favoriteJobs: [job] });
//   };
// export const deleteFavoriteCampaign =
//   (campaign: string): Thunkction =>
//   async () => {
//     await axios.post("/campaign/delete", { favoriteCampaigns: [campaign] });
//   };

export const deleteCampaignProfile =
  (data: { [field: string]: string[] }, refetch: boolean = true): Thunkction =>
  async (dispatch) => {
    await axios.post("/campaign/delete", data);
    if (refetch) await dispatch(getCampaign(auth.currentUser?.uid as string));
  };

export const updateCampaignProfilePic =
  (url: string): Thunkction =>
  async () => {
    const data = new FormData();
    data.append("profilePic", await (await fetch(url)).blob());
    await axios.post("/campaign/profilePic", data);
  };

export const updateCampaignBannerPic =
  (url: string): Thunkction =>
  async () => {
    const data = new FormData();
    data.append("bannerPic", await (await fetch(url)).blob());
    await axios.post("/campaign/bannerPic", data);
  };

export const updateCampaignMedia =
  (url: string): Thunkction =>
  async () => {
    const data = new FormData();
    data.append("media", await (await fetch(url)).blob());
    await axios.post("/campaign/media", data);
  };

export const updateCampaignJobListing =
  (data: Partial<JobListing>): Thunkction =>
  async (dispatch) => {
    await axios.post("/job/upsert", { ...data });
    await dispatch(getCampaign(auth.currentUser?.uid as string));
  };

import {
  Box,
  Text,
  VStack,
  AccordionIcon,
  Flex,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  Heading,
  Stack,
  Skeleton,
  SkeletonText,
  Image,
  useTheme
} from "@chakra-ui/react";
import { MedalOutline } from "react-ionicons";

import useUser from "../../hooks/useUser";
import EditExperience from "./EditProfile/EditExperience";
import EditExperienceFormik from "./EditProfile/EditExperienceFormik";
import ProfileCard from "./ProfileCard";
import { Experience } from "types";

function formatDate(date) {
  return new Date(date).toLocaleString("default", { month: "long", year: "numeric" });
}

function ExperienceGroup({ experiences }) {
  const theme = useTheme();
  if (!experiences) return null;
  return (
    <Stack spacing={2}>
      {experiences.map((experience: Experience, i) => (
        <Flex key={experience.id}>
          <VStack spacing={2}>
            <Box
              borderWidth={experience.image ? "6px" : "4px"}
              borderColor="politiq.lavender"
              borderRadius="full"
              w="56px"
              h="56px"
            >
              {experience.image &&
                <Image
                  objectFit="cover"
                  borderRadius="full"
                  minW="calc(100% + 4px)"
                  w="calc(100% + 4px)"
                  h="calc(100% + 4px)"
                  ml={-0.5}
                  mt={-0.5}
                  {...(experience.image && { src: experience.image + "?" + new Date().getTime() })}
                />
              }
            </Box>
            {i < experiences.length - 1 &&
              <>
                <Box h={3} w="4px" bg="politiq.lavender" />
                <Box h={6} w="4px" bg="politiq.lavender" />
                <Box h={3} w="4px" bg="politiq.lavender" />
              </>
            }
          </VStack>
          <Box ml={4} w="100%">
            <Stack direction="row">
              <Text fontSize="lg" fontWeight="bold">
                {experience.title}
              </Text>
              {experience.politiqEarned &&
                <MedalOutline
                  color={theme.colors.politiq.magenta}
                />
              }
            </Stack>
            <EditExperience experience={experience} />
            <Text maxW="90%">{experience.employer}</Text>
            <Text color="grey">
              {experience.city + ", " + experience.state}
            </Text>
            <Text color="grey">
              {formatDate(experience.startDate) + " - " + (experience.endDate ? formatDate(experience.endDate) : "Present")}
            </Text>
            {experience.description &&
              <Accordion boxShadow="none" allowToggle>
                <AccordionItem border="none" mt={-8}>
                  <AccordionButton
                    position="relative"
                    left="calc(100% - 36px)"
                    top={1}
                    w={0}
                    h={9}
                    pt={0}
                    _hover={{}}
                    _focus={{}}
                  >
                    <AccordionIcon
                      boxSize={8}
                      p={1}
                      _hover={{ bg: "politiq.lightgray", transition: "0.3s" }}
                      borderRadius="full"
                    />
                  </AccordionButton>
                  <AccordionPanel pt={1} w="100%" pl={0}>
                    {experience.description}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            }
          </Box>
        </Flex>
      ))}
    </Stack>
  );
}

function ExperienceSkeleton() {
  const { loading } = useUser();
  return (
    <Flex>
      <Skeleton
        isLoaded={!loading}
        my={loading ? 4 : 0}
        h={loading ? "56px" : 0}
        w={loading ? "56px" : 0}
        borderRadius="full"
      />
      <SkeletonText
        noOfLines={4}
        spacing={6}
        isLoaded={!loading}
        my={loading ? 4 : 0}
        flex="1"
        ml={4} />
    </Flex>
  );
}

export default function Experiences() {
  const { user, loading } = useUser();

  const experiences = user?.experiences?.sort((a, b) => {
    const aEndDate = a.endDate ? new Date(a.endDate).getTime() : Infinity;
    const bEndDate = b.endDate ? new Date(b.endDate).getTime() : Infinity;
    if (bEndDate !== aEndDate) {
      return bEndDate - aEndDate;
    } else {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
  });

  return (
    <ProfileCard>
      <Stack h={loading ? "100%" : 0} spacing={4}>
        <ExperienceSkeleton />
        <ExperienceSkeleton />
        <ExperienceSkeleton />
      </Stack>
      <Skeleton h={loading ? 0 : "100%"} isLoaded={!loading}>
        <EditExperience />
        {/* <EditExperienceFormik /> */}
        <Heading fontSize="2xl" mb={4}>Experiences</Heading>
        {!experiences?.length &&
          <Text fontStyle="italic" color="politiq.darkgray" mb={2} mt={-1}>
            There's nothing here...yet.
          </Text>
        }
        <ExperienceGroup experiences={user?.experiences?.slice(0, 3)} />
        {experiences && experiences.length > 3 &&
          <Accordion boxShadow="none" allowToggle mt={2}>
            <AccordionItem border="none">
              <AccordionButton p={0} w="115px" borderRadius={12}>
                <AccordionIcon boxSize="40px" borderRadius="full" />
                See More
              </AccordionButton>
              <AccordionPanel pt={4} w="100%" pl={0}>
                <ExperienceGroup experiences={experiences.slice(3)} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        }
      </Skeleton>
    </ProfileCard>
  );
}

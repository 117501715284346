import {
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
  Button,
  Box,
  Checkbox,
  Flex,
  Spacer,
  Radio,
  RadioGroup,
  Grid,
  Text,
  Wrap,
  SimpleGrid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";

import EditProfileContainer from "./EditCampaignProfileContainer";
import * as Types from "types";
import useValidatedForm from "../../../hooks/useValidatedForm";
import { useAppDispatch } from "../../../hooks";
import FormInput from "../../FormInput";
import FormTextArea from "../../FormTextArea";
import {
  deleteCampaignProfile,
  updateCampaignJobListing,
} from "../../../app-state/actions/campaign";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type EditJobListingFieldValues = Omit<
  Types.JobListing,
  "id" | "image" | "startDate" | "endDate"
> & {
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  payment: string;
};

export default function EditJobListing({
  jobListing,
  city,
  state,
  maxSkillsOrTools,
  categories,
}: {
  jobListing?: Types.JobListing;
  city: string;
  state: string;
  maxSkillsOrTools: number;
  categories: { [category: string]: Types.SkillOrTool[] };
}) {
  const { register, isValid, handleSubmit, formProps, reset } =
    useValidatedForm<EditJobListingFieldValues>();

  const [isOneTime, setIsOneTime] = useState(!Boolean(jobListing?.endDate));
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const preselected = jobListing?.skills as Types.SkillOrTool[];
  const [activeCategory, setActiveCategory] = useState("");
  const [selected, setSelected] = useState(preselected || []);

  function resetSelections() {
    setIsOneTime(true);
    setSelected([]);
    setActiveCategory("");
    reset();
  }

  useEffect(() => setSelected(preselected || []), [preselected]);

  function addSkillOrTool(item) {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
      setErrorMessage("");
    } else if (selected.length < maxSkillsOrTools) {
      setSelected([item, ...selected]);
    } else {
      setErrorMessage(`You can only select up to ${maxSkillsOrTools} skills/tools.`);
    }
  }
  const startDate = jobListing && new Date(jobListing.startDate);
  const endDate = jobListing?.endDate && new Date(jobListing.endDate);

  const createUpdateObject = (data: Partial<Types.JobListing>): Partial<Types.JobListing> => {
    if (!jobListing) return data;
    let newObject: Partial<Types.JobListing> = {};
    for (const key in data) {
      if (key === "campaign") {
        continue; // can't update the campaign.
      }
      if (data[key] !== jobListing[key]) {
        newObject[key] = data[key];
      }
    }
    newObject.id = data.id;
    return newObject;
  };
  async function editJobListing({
    startMonth,
    startYear,
    endMonth,
    endYear,
    payment,
    ...data
  }: EditJobListingFieldValues) {
    if (!isOneTime && (!endMonth || !endYear)) {
      setErrorMessage("You must enter both an end month and year.");
      return;
    } else {
      const newJobListing: Types.JobListing = {
        ...data,
        startDate: new Date(Number(startYear), months.indexOf(startMonth), 1).toISOString(),
        ...(!isOneTime && {
          endDate: new Date(Number(endYear), months.indexOf(endMonth), 2).toISOString(),
        }),
        ...(jobListing && { id: jobListing.id }),
        skills: selected,
        pay: payment === "true",
      };
      if (newJobListing.endDate && newJobListing.startDate > newJobListing.endDate) {
        return setErrorMessage("End date must be after the start date.");
      }
      setLoading(true);
      setErrorMessage("");
      const updateObject = createUpdateObject(newJobListing);
      if (updateObject) {
        // ensure that city and state are always coupled in updates (for geolocation updating)
        if (updateObject.city && !updateObject.state) {
          updateObject.state = jobListing?.state as Types.USState;
        } else if (updateObject.state && !updateObject.city) {
          updateObject.city = jobListing?.city as string;
        }

        try {
          await dispatch(updateCampaignJobListing(updateObject));
          reset();
          if (!jobListing) {
            resetSelections();
          }
        } catch (e: any) {
          setErrorMessage("An unknown error occurred. Please try again.");
        }
      }
      setLoading(false);
    }
  }

  async function deleteJobListing() {
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(deleteCampaignProfile({ jobListings: [jobListing?.id as string] }));
    } catch {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  const monthItems = months.map((month) => <option key={month}>{month}</option>);

  const currentYear = new Date().getFullYear();
  const yearItems = Array.from({ length: 100 }, (_, i) => (
    <option key={i}>{currentYear - i}</option>
  ));

  return (
    <EditProfileContainer
      name={(jobListing ? "Edit" : "Add") + " Job Listing"}
      {...(!jobListing && {
        openButton: (onOpen) => (
          <Button
            onClick={onOpen}
            w={1}
            bg="white"
            _hover={{ bg: "rgba(0, 0, 0, 0.05)" }}
            borderRadius="full"
            position="absolute"
            top={-1}
            left="100%"
          >
            <AddIcon boxSize={5} />
          </Button>
        ),
      })}
      {...(jobListing && {
        deleteDialogName: "Job Listing",
        onDelete: deleteJobListing,
        top: -2,
      })}
      saveBtnSide="right"
      onSave={handleSubmit(editJobListing)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Stack spacing={4}>
        <FormInput
          name="title"
          label="Job Title"
          defaultValue={jobListing?.title}
          rules={{
            required: true,
            maxLength: 50,
          }}
          {...formProps}
        />
        <FormControl isRequired>
          <FormLabel>Start Date</FormLabel>
          <HStack spacing={3}>
            <Select
              placeholder="Month"
              focusBorderColor="politiq.lavender"
              defaultValue={startDate && months[startDate.getMonth()]}
              {...register("startMonth", { required: true })}
            >
              {monthItems}
            </Select>

            <Select
              placeholder="Year"
              focusBorderColor="politiq.lavender"
              defaultValue={startDate && startDate.getFullYear()}
              {...register("startYear", { required: true })}
            >
              {yearItems}
            </Select>
          </HStack>
        </FormControl>

        <Box>
          <Flex>
            <FormLabel>End Date</FormLabel>
            <Spacer />
            <Checkbox
              colorScheme="purple"
              defaultChecked={!jobListing?.endDate}
              onChange={(e) => setIsOneTime(!isOneTime)}
            >
              One-Time Job
            </Checkbox>
          </Flex>

          <HStack spacing={3}>
            <Select
              placeholder="Month"
              focusBorderColor="politiq.lavender"
              {...(isOneTime && { value: "Month" })}
              defaultValue={endDate && months[endDate.getMonth()]}
              isDisabled={isOneTime}
              {...register("endMonth")}
            >
              {monthItems}
            </Select>

            <Select
              placeholder="Year"
              focusBorderColor="politiq.lavender"
              {...(isOneTime && { value: "Year" })}
              defaultValue={endDate && endDate.getFullYear()}
              isDisabled={isOneTime}
              {...register("endYear")}
            >
              {yearItems}
            </Select>
          </HStack>
        </Box>

        <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
          <FormInput
            name="city"
            label="City"
            defaultValue={city}
            {...formProps}
            rules={{
              required: true,
              pattern: /^[a-z ,."-]+$/i,
            }}
          />
          <FormControl isRequired>
            <FormLabel>State</FormLabel>
            <Select
              placeholder=" "
              focusBorderColor="politiq.lavender"
              defaultValue={state}
              {...register("state", { required: true })}
            >
              {Object.values(Types.USState).map((state) => (
                <option key={state}>{state}</option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>

        <FormTextArea
          name="description"
          label="Description"
          defaultValue={jobListing?.description}
          rules={{ maxLength: 300 }}
          {...formProps}
        />

        <Box>
          <FormControl isRequired>
            <HStack spacing={3}>
              <FormLabel>Payment Type</FormLabel>
              <RadioGroup
                colorScheme="purple"
                defaultValue={"" + jobListing?.pay}
                {...formProps}
                rules={{
                  required: true,
                  maxLength: 50,
                }}
              >
                <Radio
                  isRequired={true}
                  {...register("payment", { required: true })}
                  ml={5}
                  value={"true"}
                >
                  Paid
                </Radio>
                <Radio
                  isRequired={true}
                  {...register("payment", { required: true })}
                  ml={5}
                  value={"false"}
                >
                  Unpaid
                </Radio>
              </RadioGroup>
            </HStack>
          </FormControl>
        </Box>

        <Box>
          <FormControl isRequired>
            <HStack spacing={3}>
              <FormLabel>Format</FormLabel>
              <RadioGroup colorScheme="purple" defaultValue={"" + jobListing?.type} {...formProps}>
                <Radio {...register("type", { required: true })} ml={5} value={"Virtual"}>
                  Virtual
                </Radio>
                <Radio {...register("type", { required: true })} ml={5} value={"In Person"}>
                  In Person
                </Radio>
              </RadioGroup>
            </HStack>
          </FormControl>
        </Box>

        <Box>
          <FormControl isRequired>
            <HStack spacing={3}>
              <FormLabel>Job Type</FormLabel>
              <RadioGroup
                colorScheme="purple"
                defaultValue={"" + jobListing?.commitment}
                {...formProps}
              >
                <Radio {...register("commitment", { required: true })} ml={5} value={"Full Time"}>
                  Full Time
                </Radio>
                <Radio {...register("commitment", { required: true })} ml={5} value={"Part Time"}>
                  Part Time
                </Radio>
                <Radio {...register("commitment", { required: true })} ml={5} value={"Volunteer"}>
                  Volunteer
                </Radio>
              </RadioGroup>
            </HStack>
          </FormControl>
        </Box>

        <Grid templateColumns={{ base: "1fr", sm: "80px 1fr" }} columnGap={6} rowGap={4}>
          <Text mt={1}>Skill/Tool Categories</Text>
          <Select
            maxW="300px"
            placeholder="Select category"
            onChange={(e) => setActiveCategory(e.target.value)}
          >
            {Object.keys(categories).map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Select>
          {categories[activeCategory] && (
            <>
              <Text>Tags</Text>
              <Box>
                <Wrap mt={1}>
                  {categories[activeCategory].map((item: Types.SkillOrTool) => (
                    <Button
                      key={item}
                      size="xs"
                      bg={selected.includes(item) ? "politiq.darkgray" : "white"}
                      color={selected.includes(item) ? "white" : "politiq.darkgray"}
                      _hover={{
                        bg: selected.includes(item) ? "politiq.gray" : "politiq.brightgray",
                      }}
                      border="1px"
                      borderColor="politiq.darkgray"
                      borderRadius="full"
                      mr={2}
                      mb={2}
                      onClick={() => addSkillOrTool(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </Wrap>
              </Box>
            </>
          )}
        </Grid>
      </Stack>
    </EditProfileContainer>
  );
}

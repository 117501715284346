import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Grid,
  GridItem,
  Center,
  Box,
  SlideFade
} from "@chakra-ui/react";
import { useEffect } from "react";

import FavJobs from "../Favorite/FavJobs";
import FavCampaigns from "../Favorite/FavCampaigns";
import FavPeople from "../Favorite/FavPeople";

import FavoritesHeader from "./FavoritesHeader";

//   import useCampaign from "../../hooks/useCampaign"; // from un-merged main files for CampaignProfile.tsx
import { useAppDispatch } from "../../hooks";
// import * as CampaignActions from "../../app-state/actions/campaign";
// import * as UserActions from "../../app-state/actions/user";
import { getFavorites } from "../../app-state/actions/favorites";
import { auth } from "../../firebase";
import useIsCampaign from "../../hooks/useIsCampaign";
import useFavorites from "../../hooks/useFavorites";
import * as Types from "types";
const tabs = ["JOBS", "CAMPAIGNS", "PEOPLE"];

// Auth protected route -- therefore, only real users can enter this page.
export default function Favorites() {
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  useEffect(() => {
    if (isCampaign !== undefined && auth.currentUser?.uid) {
      const accountType = isCampaign ? "campaign" : "user";
      dispatch(getFavorites(accountType));
    }
  }, [isCampaign]);
  const { favorites, error } = useFavorites();
  // const { campaign, loading, error } = useCampaign();

  // useEffect(() => {
  //   dispatch(getCampaign(id, true));
  // }, [id]);

  // if (loading) {
  //   return (
  //     <Center mt="calc(50vh - 80px)">
  //       <Spinner size="lg" color="politiq.lavender" />
  //     </Center>
  //   );
  // }

  // if (campaign) {
  //   window.history.replaceState(null, "", "/campaign/" + campaign.username);
  // }

  // if (error) {
  //   return <NotFound />;
  // }

  return (
    // in={Boolean(campaign)} before
    <Box bg="politiq.brightgray">
      <SlideFade in={Boolean(true)} transition={{ enter: { duration: 0.3 } }}>
      <FavoritesHeader />
      <Tabs defaultIndex={0} variantColor="politiq.brightgray" borderColor="transparent">
        <TabList justifyContent="center" bg="none" mt="-70px">
          <Grid templateColumns="repeat(3, 1fr)" style={{ width: "80%" }}>
            {tabs.map((tab, i) => (
              <GridItem
                colSpan={1}
                borderRightWidth={i === tabs.length - 1 ? 0 : 1}
                borderRightColor="transparent"
                key={i}
                color="white"
              >
                <Center>
                  <Tab
                    _selected={{
                      color: "white",
                      borderBottom: "4px white solid",
                      outline: "none",
                    }}
                    _focus={{ outline: 0 }}
                    width="100%"
                    fontSize="20px"
                  >
                    {tab}
                  </Tab>
                </Center>
              </GridItem>
            ))}
          </Grid>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Center>
              <FavJobs favoriteJobs={favorites?.favoriteJobs as Types.JobListing[]} />
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <FavCampaigns favoriteCampaigns={favorites?.favoriteCampaigns as Types.Campaign[]} />
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <FavPeople favoriteUsers={favorites?.favoriteUsers as Types.User[]} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </SlideFade>
    </Box>
  );
}

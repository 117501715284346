import { Box } from "@chakra-ui/react";
import { lazy, Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";

const CometChatUI = lazy(() => {
  return import("../../cometchat-pro-react-ui-kit/CometChatWorkspace/src")
    .then(module => ({ default: module.CometChatUI }));
});

export default function ChatWindow() {
  const { id } = useParams<{ id?: string }>();

  return (
    <Box w="100%" h="calc(100vh - 80px)" bg="white">
      <Suspense fallback={<Box />}>
        <CometChatUI chatWithUser={id} />
      </Suspense>
    </Box>
  );
}

import { Campaign } from "types";
import { CampaignActionType } from "../actions/campaign";

export type CampaignState = {
  loading: boolean;
  data: Campaign | null;
  error: string;
  isCurrentCampaign?: boolean;
};

export const campaign = (
  state: CampaignState = {
    loading: false,
    data: null,
    error: ""
  },
  { type, payload }
): CampaignState => {
  switch (type) {
    case CampaignActionType.FETCH_CAMPAIGN:
      return {
        loading: true,
        data: null,
        error: ""
      };
    case CampaignActionType.FETCHED_CAMPAIGN:
      return {
        loading: false,
        data: payload,
        error: ""
      };
    case CampaignActionType.FAILED_CAMPAIGN:
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};

import { Heading, Box, Flex, Skeleton, SkeletonText, Stack, Text } from "@chakra-ui/react";

import ProfileCard from "./ProfileCard";
import SingleEndorsement from "./SingleEndorsement";
import AddEndorsement from "./EditProfile/AddEndorsement";
import EditEndorsements from "./EditProfile/EditEndorsements";
import { Endorsement, User } from "types";
import useUser from "../../hooks/useUser";
import { auth } from "../../firebase";
import useIsCampaign from "../../hooks/useIsCampaign";

function isCurrentUserEndorsement(endorsement: Endorsement) {
  return (endorsement.endorser as User).uid === auth.currentUser?.uid;
}

function getEndorsementPriority(endorsement: Endorsement) {
  const timeGiven = new Date(endorsement.dateGiven).getTime();
  return timeGiven + (isCurrentUserEndorsement(endorsement) ? 1e30 : 0);
}

export function sortEndorsements(endorsements: Endorsement[]) {
  return endorsements.sort((a, b) => {
    return getEndorsementPriority(b) - getEndorsementPriority(a);
  });
}

function EndorsementSkeleton() {
  const { loading } = useUser();
  return (
    <Box>
      <Flex>
        <Skeleton
          isLoaded={!loading}
          my={loading ? 4 : 0}
          h={loading ? "60px" : 0}
          w={loading ? "60px" : 0}
          borderRadius="full"
          mr={4}
        />
        <SkeletonText noOfLines={2} spacing={6} isLoaded={!loading} my={loading ? 5 : 0} flex="1" />
      </Flex>
      <SkeletonText noOfLines={5} spacing={6} isLoaded={!loading} my={loading ? 5 : 0} flex="1" />
    </Box>
  );
}

export default function Endorsements() {
  const { user, loading, isCurrentUser } = useUser();
  const isCampaign = useIsCampaign();

  const endorsements = user?.endorsements && sortEndorsements(user?.endorsements);
  const currentUserEndorsement = endorsements?.find(isCurrentUserEndorsement);
  const visible = endorsements?.filter((endorsement) => !endorsement.isHidden);

  return (
    <ProfileCard>
      <Stack h={loading ? "100%" : 0} spacing={4}>
        <EndorsementSkeleton />
        <EndorsementSkeleton />
        <EndorsementSkeleton />
      </Stack>
      <Skeleton h={loading ? 0 : "100%"} isLoaded={!loading}>
        <Heading fontSize="2xl" mb={3}>
          Endorsements
        </Heading>
        {isCurrentUser ? (
          <EditEndorsements />
        ) : (
          !isCampaign && <AddEndorsement currentUserEndorsement={currentUserEndorsement} />
        )}
        {!visible?.length && (
          <Text fontStyle="italic" color="politiq.darkgray" my={2}>
            There's nothing here...yet.
          </Text>
        )}
        {visible?.map((endorsement) => (
          <SingleEndorsement key={(endorsement.endorser as User).uid} endorsement={endorsement} />
        ))}
      </Skeleton>
    </ProfileCard>
  );
}

import { Tag, TagLabel, TagCloseButton, Spinner } from "@chakra-ui/react";
import { useState } from "react";

import useUser from "../hooks/useUser";

interface TagGroupProps {
  removeItem?: (item: string) => void;
  items?: string[];
}

interface TagItemProps {
  item: string;
  removeItem: TagGroupProps["removeItem"];
}

function TagItem({ item, removeItem }: TagItemProps) {
  const { isCurrentUser } = useUser();
  const [loading, setLoading] = useState(false);

  async function removeItemWithLoading() {
    if (removeItem) {
      setLoading(true);
      removeItem(item);
    }
  }

  return (
    <Tag
      size="md"
      variant="solid"
      bg="politiq.lavender"
      borderRadius="full"
      mr={2}
      mb={2}
    >
      <TagLabel>{item}</TagLabel>
      {isCurrentUser && removeItem && (loading ?
        <Spinner size="xs" ml="10px" /> :
        <TagCloseButton onClick={removeItemWithLoading} />
      )}
    </Tag>
  );
}

export default function TagGroup({ removeItem, items }: TagGroupProps) {
  return <>
    {items && items.map(item =>
      <TagItem
        key={item}
        item={item}
        removeItem={removeItem}
      />
    )}
  </>;
}
import SuggestedCampaignCard from "./SuggestedCampaignCard";
import { Box, Text, Flex, Link } from "@chakra-ui/react";
import { Campaign } from "types";
import useIsCampaign from "../../hooks/useIsCampaign";
import { auth } from "../../firebase";

export default function CampaignSuggest({
  favorites,
  suggestions,
}: {
  favorites: Set<string>;
  suggestions: Campaign[];
}) {
  // TODO: Need to figure out why this doesn't work.
  // axios.get("/campaign/suggest").then((result) => {
  //   console.log(result);
  // });

  // const [suggestedCampaigns, setCampaigns] = useState<Array<Campaign>>([]);
  // const [value, setValue] = useState<Number>(0);

  // TODO: turn suggestions into Redux code. Then, inside the redux code, properly format results from suggest to include uid (or do this on backend).
  // useEffect(() => {
  //   async function getSuggestions() {
  //     await fetch("http://localhost:8080/campaign/suggest")
  //       .then((response) => response.json())
  //       .then((data) => setCampaigns(data.results))
  //       .then(() => {
  //         console.log("SUGGESTED CAMPAIGNS", suggestedCampaigns);
  //       });
  //   }
  //   getSuggestions();
  // }, [value]);
  const isCampaign = useIsCampaign();
  if (isCampaign) {
    suggestions = suggestions.filter((val) => val.uid !== auth.currentUser?.uid);
  }
  if (suggestions && suggestions.length > 3) {
    suggestions = suggestions.slice(0, 3);
  }
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={10}
    ml={ suggestions.length === 0 ? { base: "-20%" , sm: "-90px", cm: "0px" } : { base: "0px"} }>
      <Box alignItems="start">
        <Text
          fontSize="34px"
          w="90%"
          ml={{ base: "5%", sm: "0%" }}
          fontWeight="bold"
          alignSelf="flex-start"
          mb="1em"
        >
          Suggested Campaigns
        </Text>
        <Flex
          position="relative"
          flexDirection={{ base: "column", cm: "row" }}
          justifyContent="space-between"
          w={{ cm: "1280px" }}
        >
          {suggestions.map((campaign) => (
            <Box mb="45px">
              <SuggestedCampaignCard
                key={campaign.uid}
                tags={campaign.policyPriorities}
                campaign={campaign}
                rotundapage={true}
                favorite={favorites.has(campaign.uid as string)}
              />
            </Box>
          ))}
        </Flex>
        <Link
          ml={{ base: "5%", sm: "0%" }}
          href="/explore"
          color="politiq.darkgray"
          fontSize="20px"
          w="fit-content"
        >
          See more campaigns →
        </Link>
      </Box>
    </Box>
  );
}

export interface Campaign
  extends Omit<SignUpCredentialsCampaign, "password">,
    Omit<SignUpInfo, "registeredToVote"> {
  uid?: string; // only incuded during GET user so we can identify whether it's the current campaign
  bannerPic?: string;
  profilePic?: string;
  headline: string;
  biography: string;
  // maintain IDs to job applications, as these are synced between campaign AND user
  // thus can't be stored in a subcollection
  jobApplications: string[] | JobApplication[];
  positionLevel: PositionLevel;
  officeLevel: OfficeLevel;
  websiteUrl: string;
  media: string[];
  platformPoints?: PlatformPoint[];
  policyPriorities: PolicyPriority[];
  // maintain IDs to job listings, as these are stored in separate collection
  jobListings?: string[] | JobListing[];
  teamMembers?: string[] | User[];
  favoriteJobs?: string[] | JobListing[];
  favoriteCampaigns?: string[] | Campaign[];
  favoriteUsers?: string[] | User[];
}

export interface Favorites {
  favoriteUsers: User[];
  favoriteJobs: JobListing[];
  favoriteCampaigns: Campaign[];
}
export interface Suggestions {
  suggestedJobs: JobListing[];
  suggestedCampaigns: Campaign[];
}

export function isCampaign(arg: any): arg is Campaign {
  return (
    arg &&
    arg.campaignName !== undefined &&
    arg.campaignName !== null &&
    typeof arg.campaignName == "string"
  );
}

export interface PlatformPoint {
  title: string;
  description: string;
  icon: Icon;
  id?: string;
}

export enum EconomicPolicyPriority {
  Taxes = "Taxes",
  EntitlementPrograms = "Entitlement Programs",
  MonetaryPolicy = "Monetary Policy",
  GovernmentSpending = "Government Spending",
  WagesAndIncome = "Wages and Income",
  BusinessRegulations = "Business Regulations",
}

export enum SocialPolicyPriority {
  EquityAndInclusion = "Equity & Inclusion",
  Immigration = "Immigration",
  VeteransAffairs = "Veterans Affairs",
  LaborAndUnions = "Labor/Unions",
  CriminalJustice = "Criminal Justice",
  GenderAndMinorityRights = "Gender/Minority Rights",
}

export enum HealthcarePolicyPriority {
  QualityOfHealthcare = "Quality of Healthcare",
  AccessToHealthcare = "Access to Healthcare",
  CostOfHealthcare = "Cost of Healthcare",
  MedicinesAndDrugs = "Medicines/Drugs",
  PublicHealth = "Public Health",
}

export enum DomesticPolicyPriority {
  Education = "Education",
  ElectionsAndVoting = "Elections/Voting",
  JudicialReform = "Judicial Reform",
  FreeSpeech = "Free Speech",
  ArmsRights = "Arms Rights",
}

export enum TechnologyPolicyPriority {
  TechnologyInvestment = "Technology Investment",
  ScientificInvestment = "Scientific Investment",
  TelecommunicationsAndBroadband = "Telecommunications/Broadband",
  InternetRegulation = "Internet Regulation",
  SpaceExploration = "Space Exploration",
}

export enum InfrastructurePolicyPriority {
  Infrastructure = "Infrastructure",
  Housing = "Housing",
  Transportation = "Transportation",
}

export enum EnergyPolicyPriority {
  EnergyGeneration = "Energy Generation",
  EnergyDistribution = "Energy Distribution",
}

export enum EnvironmentalPolicyPriority {
  NationalParksAndWildlife = "National Parks & Wildlife",
  ResourceConservation = "Resource Conservation",
  EmissionsAndPollution = "Emissions & Pollution",
}

export enum ReligiousAndCommunityPolicyPriority {
  ReligiousFreedom = "Religious Freedom",
}

export enum InternationalPolicyPriority {
  NationalSecurityAndDefense = "National Security/Defense",
  ForeignPolicy = "Foreign Policy",
}

export enum StatePolicyPriority {
  StateEducation = "State - K-12 Education",
  StateHigherEducation = "State - Higher Education",
  StateBudget = "State - Budget",
  StateCriminalJustice = "State - Criminal Justice",
  StateHealthcare = "State - Healthcare",
  StateTransportation = "State - Transportation", // split apart into 2 because of length
  StateInfrastructure = "State - Infrastructure",
  StateEnvironment = "State - Environment",
}

export enum LocalPolicyPriority {
  CrimeAndPolicing = "Crime/Policing",
}

export type PolicyPriority =
  | EconomicPolicyPriority
  | SocialPolicyPriority
  | HealthcarePolicyPriority
  | DomesticPolicyPriority
  | TechnologyPolicyPriority
  | InfrastructurePolicyPriority
  | EnergyPolicyPriority
  | EnvironmentalPolicyPriority
  | ReligiousAndCommunityPolicyPriority
  | InternationalPolicyPriority
  | StatePolicyPriority
  | LocalPolicyPriority;

export enum DigitalOutreachSkill {
  EmailCampaign = "Email Campaigns",
  DigitalAds = "Digital Ads",
}

export enum DigitalInternalSkill {
  GraphicDesignLiterature = "Graphic Design/Literature",
  WebsiteCreation = "Website Creation",
  CampaignSoftware = "Campaign Software",
  SocialMedia = "Social Media",
  DataManagement = "Data Management",
}

export enum ManagementSkill {
  CampaignManagement = "Campaign Management",
  CampaignStrategy = "Campaign Strategy",
  CampaignFinance = "Campaign Finance",
  VolunteerManagement = "Volunteer Management",
  Fundraising = "Fundraising",
  Sponsorships = "Sponsorships",
}

export enum ExecutionSkill {
  Canvassing = "Canvassing",
  EventPlanning = "Event Planning",
  PublicSpeaking = "Public Speaking",
  Phonebanking = "Phonebanking",
  Speechwriting = "Speechwriting",
  PublicRelations = "Public Relations",
  MediaEngagements = "Media Engagements",
}

export type Skill = DigitalOutreachSkill | DigitalInternalSkill | ManagementSkill | ExecutionSkill;

export enum DataAppTool {
  Advantage = "Advantage",
  ngpVAN = "ngpVAN",
  i360 = "i360",
  ORVS = "ORVS",
  CroudSkout = "CroudSkout",
  eCanvasser = "eCanvasser",
  PoliticalDataInc = "Political Data Inc",
}

export enum OutreachAppTool {
  RedDialer = "Red Dialer",
  GOPEnvoy = "GOP Envoy",
  Polis = "Polis",
  CallHub = "CallHub",
  Mobilize = "Mobilize",
  OutVote = "OutVote",
}

export enum ManagementAppTool {
  NationBuilder = "Nation Builder",
}

export enum GeneralTechTool {
  MicrosoftExcel = "Microsoft Excel",
  AdobePremiere = "Adobe Premiere",
  Canva = "Canva",
  Websites = "Websites",
  HTMLAndCSS = "HTML/CSS",
  Java = "Java",
  CPlusPlus = "C++",
  Python = "Python",
}

export type Tool = DataAppTool | OutreachAppTool | ManagementAppTool | GeneralTechTool;

export type SkillOrTool = Skill | Tool;
export enum USState {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District Of Columbia",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export enum PositionLevel {
  Local = "Local",
  County = "County",
  Regional = "Regional",
  State = "State",
  Federal = "Federal",
}

export enum LocalPosition {
  Mayor = "Mayor",
  CityCouncil = "City Council",
  SchoolBoard = "School Board",
  LocalCourt = "Local Court",
  LocalOffice = "Local Office",
}

export enum CountyPosition {
  CountyCommissioner = "County Commissioner",
  CountyCourt = "County Court",
  Sheriff = "Sheriff",
  CountywideOffice = "Countywide Office",
}

export enum RegionalPosition {
  DistrictAttorney = "District Attorney",
  CourtOfAppeals = "Court of Appeals",
  DistrictCourt = "District Court",
  RegionalOffice = "Regional Office",
}

export enum StatePosition {
  Governor = "Governor",
  LieutenantGovernor = "Lieutenant Governor",
  AttorneyGeneral = "Attorney General",
  SecretaryOfState = "Secretary of State",
  StateSupremeCourt = "State Supreme Court",
  StateSenate = "State Senate",
  StateHouse = "State House",
  StateAssembly = "State Assembly",
  StatewideOffice = "Statewide Office",
}

export enum FederalPosition {
  USSenate = "U.S. Senate",
  USHouse = "U.S. House",
  President = "President",
}

export type OfficeLevel =
  | LocalPosition
  | CountyPosition
  | RegionalPosition
  | StatePosition
  | FederalPosition;

export enum Availability {
  FullTime = "Full Time",
  PartTime = "Part Time",
  Volunteer = "Volunteer",
}

export interface SignUpCredentials {
  firstName: string;
  lastName: string;
  email: string;
  password?: string; // absence of the password property means that OAuth was used
  signUpAsCampaign: boolean;
  verification?: string;
}

export interface SignUpCredentialsCampaign {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  campaignName: string;
}

export interface SignUpInfo {
  username: string;
  phone: string;
  city: string;
  state: USState;
  registeredToVote: boolean;
}

export interface CampaignSignUpInfo {
  campaignName: string;
  headline: string;
  websiteUrl?: string;
  positionLevel: PositionLevel;
  officeLevel: OfficeLevel;
  verification: boolean;
}

export interface User
  extends Omit<SignUpCredentials, "password" | "signUpAsCampaign" | "verification">,
    SignUpInfo {
  uid?: string; // only incuded during GET user so we can identify whether it's the current user
  profilePic?: string;
  biography: string;
  policyPriorities?: PolicyPriority[];
  endorsements?: Endorsement[];
  jobApplications: string[] | JobApplication[];
  resumeLink: string; // TODO: resume-upload service
  portfolioLink: string;
  availability: Availability;
  skills: Skill[];
  tools: Tool[];
  experiences?: Experience[];
  headline: string;
  favoriteJobs?: string[] | JobListing[];
  favoriteCampaigns?: string[] | Campaign[];
  favoriteUsers?: string[] | User[];
}
export function isUser(arg: any): arg is User {
  return (
    arg &&
    arg.portfolioLink !== undefined &&
    arg.portfolioLink !== null &&
    typeof arg.portfolioLink == "string"
  );
}
export interface Endorsement {
  endorser: string | User;
  message: string;
  dateGiven: string | Date;
  relationship: string; // Relationship of endorser (common workplace)
  isHidden: boolean; // defaults to False
  id?: string;
}

export interface Experience {
  city?: string;
  state?: string;
  title: string;
  startDate: string | Date;
  endDate?: string | Date;
  description?: string;
  politiqEarned: boolean;
  image?: string;
  employer: string;
  id?: string;
}

export enum JobType {
  Virtual = "Virtual",
  InPerson = "In Person",
}

// All icons provided by Icons8
export enum Icon {
  Money = "https://img.icons8.com/wired/64/000000/money.png",
  Earth = "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/452/external-earth-space-vitaliy-gorbachev-lineal-vitaly-gorbachev.png",
  Judge = "https://img.icons8.com/wired/64/000000/law.png",
  Computer = "https://img.icons8.com/external-photo3ideastudio-lineal-photo3ideastudio/452/external-computer-gadget-photo3ideastudio-lineal-photo3ideastudio.png",
  Healthcare = "https://img.icons8.com/external-kiranshastry-solid-kiranshastry/64/000000/external-health-medical-kiranshastry-solid-kiranshastry.png",
  GenderEquality = "https://img.icons8.com/ios/452/gender-equality.png",
  GeneralLedger = "https://img.icons8.com/ios/50/000000/general-ledger.png",
  ExchangeDollar = "https://img.icons8.com/dotty/452/exchange-dollar.png",
  Tax = "https://img.icons8.com/ios/452/tax.png",
  Welfare = "https://img.icons8.com/wired/452/welfare.png",
  Exchange = "https://img.icons8.com/wired/452/exchange.png",
  BankBuilding = "https://img.icons8.com/ios/452/bank-building.png",
  RulesBook = "https://img.icons8.com/ios/452/rules-book.png",
  Debt = "https://img.icons8.com/dotty/452/debt.png",
  Cost = "https://img.icons8.com/ios/452/cost.png",
  PermanentJob = "https://img.icons8.com/ios/452/permanent-job.png",
  Transaction = "https://img.icons8.com/ios/452/refund-2.png",
  InitiateMoneyTransfer = "https://img.icons8.com/dotty/452/initiate-money-transfer.png",
  ReceiveCash = "https://img.icons8.com/dotty/452/receive-cash.png",
  MortgageInterest = "https://img.icons8.com/ios/452/mortgage-interest.png",
  ConnectedPeople = "https://img.icons8.com/dotty/452/connected-people.png",
  People = "https://img.icons8.com/ios-filled/452/conference-call.png",
  MulticulturalPeople = "https://img.icons8.com/ios/452/multicultural-people.png",
  Group = "https://img.icons8.com/ios-filled/452/group-foreground-selected.png",
  Children = "https://img.icons8.com/ios/452/children--v1.png",
  DogTag = "https://img.icons8.com/ios/452/dog-tag.png",
  Soldier = "https://img.icons8.com/ios/452/soldier-man.png",
  Customs = "https://img.icons8.com/ios/452/customs.png",
  Worker = "https://img.icons8.com/ios/452/worker-male.png",
  Work = "https://img.icons8.com/ios/452/work.png",
  Handcuffs = "https://img.icons8.com/ios/452/handcuffs.png",
  Jail = "https://img.icons8.com/ios/452/jail-cell-door--v2.png",
  Police = "https://img.icons8.com/wired/452/policeman-male.png",
  PoliceBadge = "https://img.icons8.com/ios/452/police-badge.png",
  Robbery = "https://img.icons8.com/wired/452/bank-robbery.png",
  Prisoner = "https://img.icons8.com/ios/452/prisoner.png",
  Racism = "https://img.icons8.com/ios/452/racism.png",
  GayMarriage = "https://img.icons8.com/ios/452/lgbt-men.png",
  SameSexMarriage = "https://img.icons8.com/ios/452/lgbt-women.png",
  WomanHead = "https://img.icons8.com/ios/452/woman-head.png",
  MedicalID = "https://img.icons8.com/ios/452/medical-id.png",
  PillBottle = "https://img.icons8.com/ios/452/prescription-pill-bottle.png",
  HealthCheckup = "https://img.icons8.com/ios/452/health-checkup.png",
  Doctor = "https://img.icons8.com/ios/452/medical-doctor.png",
  Clinic = "https://img.icons8.com/ios/452/clinic.png",
  Hospital = "https://img.icons8.com/ios/452/hospital-3.png",
  Caduceus = "https://img.icons8.com/ios/452/caduceus.png",
  MommyAndMe = "https://img.icons8.com/wired/452/mommy-and-me-classes.png",
  Baby = "https://img.icons8.com/wired/452/baby.png",
  Education = "https://img.icons8.com/dotty/452/education.png",
  University = "https://img.icons8.com/ios/452/university.png",
  School = "https://img.icons8.com/ios/452/school.png",
  Students = "https://img.icons8.com/wired/344/students.png",
  Elections = "https://img.icons8.com/ios/452/elections.png",
  Parliament = "https://img.icons8.com/ios/452/parliament.png",
  Law = "https://img.icons8.com/ios/452/law.png",
  Scales = "https://img.icons8.com/ios/452/scales--v1.png",
  CityHall = "https://img.icons8.com/ios/452/city-hall.png",
  USA = "https://img.icons8.com/external-dreamstale-lineal-dreamstale/452/external-united-states-of-america-flags-dreamstale-lineal-dreamstale.png",
  Lecturer = "https://img.icons8.com/ios/452/podium-with-speaker.png",
  Communicate = "https://img.icons8.com/ios/452/talk-male.png",
  Hunter = "https://img.icons8.com/ios/452/hunter.png",
  Gun = "https://img.icons8.com/ios/452/gun.png",
  Satellite = "https://img.icons8.com/dotty/452/satellite.png",
  RadioTower = "https://img.icons8.com/ios/452/radio-tower.png",
  WithoutInternet = "https://img.icons8.com/ios/452/without-internet.png",
  Internet = "https://img.icons8.com/ios/452/internet--v1.png",
  ScienceApplication = "https://img.icons8.com/ios/452/science-application.png",
  Investment = "https://img.icons8.com/ios/452/investment.png",
  Biotech = "https://img.icons8.com/wired/452/biotech.png",
  SpaceshipLaunchDocumentation = "https://img.icons8.com/dotty/452/spaceship-launch-documentation.png",
  SpaceXSpaceship = "https://img.icons8.com/pastel-glyph/452/spacex-starship.png",
  SpaceShuttle = "https://img.icons8.com/pastel-glyph/452/space-shuttle--v2.png",
  SatelliteSignal = "https://img.icons8.com/ios/452/satellite-sending-signal.png",
  Bridge = "https://img.icons8.com/ios/452/bridge.png",
  Structural = "https://img.icons8.com/wired/452/structural.png",
  Airport = "https://img.icons8.com/ios/452/airport-building.png",
  PublicTransit = "https://img.icons8.com/ios/452/ground-transportation.png",
  RealEstate = "https://img.icons8.com/ios/452/real-estate.png",
  HouseWithGarden = "https://img.icons8.com/ios/452/house-with-a-garden.png",
  Apartment = "https://img.icons8.com/ios/452/apartment.png",
  Rent = "https://img.icons8.com/ios/452/sell-property--v2.png",
  TrafficJam = "https://img.icons8.com/ios/452/traffic-jam.png",
  Train = "https://img.icons8.com/ios/452/train.png",
  PowerPlant = "https://img.icons8.com/ios/452/power-plant.png",
  RenewableEnergy = "https://img.icons8.com/ios/452/-electricity-triangle-sign.png",
  SolarEnergy = "https://img.icons8.com/external-kiranshastry-solid-kiranshastry/344/external-solar-energy-energy-kiranshastry-solid-kiranshastry-1.png",
  Light = "https://img.icons8.com/pastel-glyph/344/light--v1.png",
  NuclearEnergy = "https://img.icons8.com/ios/452/nuclear-power-plant.png",
  TransmissionTower = "https://img.icons8.com/ios/452/transmission-tower.png",
  Factory = "https://img.icons8.com/ios/452/factory-1.png",
  OilRig = "https://img.icons8.com/ios/452/oil-rig.png",
  OilPump = "https://img.icons8.com/ios/452/oil-pump-jack.png",
  BulkGasTanker = "https://img.icons8.com/ios/452/bulk-gas-tanker.png",
  Dam = "https://img.icons8.com/ios/452/dam.png",
  EnvironmentalPlanning = "https://img.icons8.com/dotty/452/environmental-planning.png",
  CO2Reduction = "https://img.icons8.com/ios/452/co2-reduction.png",
  Ecosystem = "https://img.icons8.com/wired/452/ecosystem.png",
  Forest = "https://img.icons8.com/wired/452/forest.png",
  GreenhouseEffect = "https://img.icons8.com/wired/452/greenhouse-effect.png",
  MarinePollution = "https://img.icons8.com/wired/452/marine-polution.png",
  Wildfire = "https://img.icons8.com/wired/452/wildfire--v2.png",
  Butterfly = "https://img.icons8.com/wired/452/butterfly.png",
  Pray = "https://img.icons8.com/ios/452/pray.png",
  Defense = "https://img.icons8.com/ios/452/knight-shield.png",
  Missile = "https://img.icons8.com/ios/452/missile.png",
  Tank = "https://img.icons8.com/dotty/452/tank.png",
  Diplomacy = "https://img.icons8.com/windows/452/diplomacy.png",
  Handshake = "https://img.icons8.com/ios/452/handshake--v1.png",
  Fence = "https://img.icons8.com/ios/452/defensive-wood-wall.png",
  Cybersecurity = "https://img.icons8.com/dotty/452/cyber-security.png",
  WarRefugee = "https://img.icons8.com/ios/452/war_refugee.png",
  Battleship = "https://img.icons8.com/ios/452/battleship.png",
  Musket = "https://img.icons8.com/ios/452/blunderbuss.png",
}

// NOTE: We don't really use this interface currently, as all job apps are just processed as messages.
export interface JobApplication {
  applicant: string;
  job: string;
  message: string;
  applicationDate: string | Date;
  id?: string;
  // no employerAccepted/candidateAccepted with new job app flow
}

export interface JobListing {
  title: string;
  startDate: string;
  endDate?: string;
  type: JobType;
  commitment: Availability;
  description: string;
  pay: boolean;
  skills: SkillOrTool[];
  applicants: string[];
  campaign: string; // id of the campaign
  id?: string;
  city: string; // defaults to campaign location
  state: string;
}
export function isJobListing(arg: any): arg is JobListing {
  return arg && arg.title !== undefined && arg.title !== null && typeof arg.title == "string";
}

export interface PriceKeys {
  [key: string]: string;
}

export interface newsArticles {
  url: string;
  title: string;
  urlToImage: string;
  content: string;
  publishedAt: string;
  source: {
    name: string;
  };
  description: string;
}

import { Box, BoxProps } from "@chakra-ui/react";

export default function ProfileCard({ children, ...props }: BoxProps) {
  // a container for profile content components (doesn't render any text or icons by default)
  // includes a rounded gray border, a white background, and some default padding 
  return (
    <Box
      bg="white"
      border="2px solid"
      borderColor="politiq.gray"
      borderRadius={10}
      px={9}
      py={6}
      {...props}
    >
      {children}
    </Box>
  );
}
import { Heading, Link, Box, SkeletonText, Text } from "@chakra-ui/react";

import useUser from "../../hooks/useUser";
import EditMoreDetails from "./EditProfile/EditMoreDetails";
import ProfileCard from "./ProfileCard";

export default function MoreDetails() {
  const { user, loading } = useUser();

  return (
    <ProfileCard>
      <SkeletonText noOfLines={3} spacing={6} isLoaded={!loading} py={loading ? 4 : 0}>
        <EditMoreDetails />
        <Heading fontSize="2xl" mb={2}>More Details</Heading>
        {!user?.resumeLink && !user?.portfolioLink &&
          <Text fontStyle="italic" color="politiq.darkgray" mb={2}>
            There's nothing here...yet.
          </Text>
        }
        {user?.resumeLink &&
          <Box mb={1}>
            <Link color="politiq.lavender" href={user?.resumeLink} isExternal={true}>Resume</Link>
          </Box>
        }
        {user?.portfolioLink &&
          <Link color="politiq.lavender" href={user?.portfolioLink} isExternal={true}>Portfolio</Link>
        }
      </SkeletonText>
    </ProfileCard>
  );
}
import HitProps from "./HitProps";
import { Box, Button, Text, Center, Spinner } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { User } from "types";
import SkeletonAvatar from "../SkeletonAvatar";
import { useAppDispatch } from "../../hooks";
import useCampaign from "../../hooks/useCampaign";
import { leaveCampaignTeam } from "../../app-state/actions/user";
import { auth } from "../../firebase";
import FavButton from "../Favorite/FavButton";
import useIsCampaign from "../../hooks/useIsCampaign";
import { addFavoriteUser, deleteFavoriteUser } from "../../app-state/actions/favorites";

export default function UserHit({ hit }: HitProps) {
  const history = useHistory();
  const { campaign } = useCampaign();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteUser(accountType, hit as User));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteUser(accountType, hit as User));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  const isCurrentUser = auth?.currentUser?.uid === hit.objectID;

  const handleIconClick = async () => {
    setLoading(true);
    await dispatch(leaveCampaignTeam(campaign!.uid as string));
  };

  return isCurrentUser ? (
    <></>
  ) : (
    <Box borderRadius="xl" p={4} borderWidth="1px" bg="white" maxW="280px">
      <Box justifyContent="center">
        <Box cursor="pointer" w="100%" align="right" onClick={handleIconClick}>
          {campaign && isCurrentUser ? (
            loading ? (
              <Spinner size="lg" color="politiq.lightgray" />
            ) : (
              <CloseIcon mr="5px" mt="5px" w="8" h="8" color="politiq.lightgray" />
            )
          ) : (
            <Box mr="5px" mt="5px" w="8" h="8">
              <FavButton
                type="user"
                customOnClick={toggleFavorite}
                id={hit.objectID}
                initialFavorited={true}
              />
            </Box>
          )}
        </Box>
        <Box px={4} py={6}>
          <Link to={"/profile/" + hit.objectID}>
            <Center>
              <Box mt={2}>
                <SkeletonAvatar boxSize="130px" src={hit.profilePic} alt={hit.name} />
              </Box>
            </Center>
            <Text
              fontSize={{ base: "25px", lg: "25px" }}
              decoration="bold"
              py="10px"
              textAlign="center"
              mb={-2}
            >
              {hit.firstName + " " + hit.lastName}
            </Text>
          </Link>

          <Text textAlign="center" mt={0} color="politiq.darkgray" fontSize="17px">
            {hit.headline}
          </Text>
          {!isCurrentUser && (
            <Center>
              <Button
                borderRadius="full"
                w="80%"
                h="40px"
                bg="politiq.darkgray"
                _hover={{ bg: "politiq.gray" }}
                color="white"
                mt={4}
                fontSize="15px"
                onClick={() => history.push("/chat/" + hit.objectID)}
              >
                Message
              </Button>
            </Center>
          )}
        </Box>
      </Box>
    </Box>
  );
}

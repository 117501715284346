import { JobListing } from "types";
import { Flex, Box, Text, useDisclosure } from "@chakra-ui/react";
import SuggestedJobCard from "../Rotunda/SuggestedJobCard";

export default function FavJobs({ favoriteJobs }: { favoriteJobs: JobListing[] }) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const history = useHistory();
  // function formatDate(mdate) {
  //   const date = new Date(mdate);
  //   const now = new Date();
  //   return Math.round((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
  // }

  let favjobs = (
    <>
      {(favoriteJobs !== undefined && favoriteJobs.length === 0) || favoriteJobs === undefined ? (
        <Text fontSize="xl" mt="-20px">No favorites here yet...</Text>
      ) : (
        <Flex wrap="wrap" justifyContent="center">
          {(favoriteJobs as JobListing[]).map((job) => (
            <Box cursor="pointer" mr="20px">
              <SuggestedJobCard
                overrideFavButton={true}
                tags={job.skills}
                favorited={true}
                // daysPosted={formatDate(job.lastmodified)}
                rotundapage={true}
                job={job}
              />
            </Box>
          ))}
        </Flex>
      )}
    </>
  );

  return (
    <Box width="82%" mt="70px">
      {favjobs}
    </Box>
  );
}

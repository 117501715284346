import {
  Box,
  Text,
  Button,
  Link,
  Flex,
  Wrap,
  WrapItem,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import useCampaign from "../../hooks/useCampaign";
import Carousel from "nuka-carousel";

export default function AboutCampaign() {
  const history = useHistory();
  const { campaign, isCurrentCampaign } = useCampaign();
  const slidesToShow = useBreakpointValue({ base: 2, md: 3 }) as number;
  return (
    <Box px={{ base: 6, md: 24, lg: 48 }} pb={4}>
      <Text fontSize="40px" fontWeight="bold" textAlign="left" mt="1.5rem">
        Campaign Bio
      </Text>
      <Wrap style={{ width: "100%" }}>
        <WrapItem key={0}>
          <Flex direction="row" textAlign="left" mr={{ base: "0em", md: "4em" }}>
            <Text fontSize="20px" mt="1rem" style={{ whiteSpace: "pre-wrap" }}>
              {campaign?.biography}
            </Text>
          </Flex>
        </WrapItem>

        <WrapItem width="20%" key={1}>
          <Flex mt="20px">
            {!isCurrentCampaign && (
              <Button
                variant="lavender"
                px={8}
                mr={4}
                onClick={() => history.push("/chat/" + campaign?.uid)}
              >
                Message
              </Button>
            )}
            <Link
              isExternal
              href={campaign?.websiteUrl}
              bg="politiq.lavender"
              borderRadius="6px"
              color="white"
              px={8}
              py={2}
              fontWeight="bold"
              textDecoration="none"
              textAlign="center"
              _hover={{ bg: "politiq.lightpurple" }}
            >
              Website
            </Link>
          </Flex>
        </WrapItem>
      </Wrap>
      {campaign?.media && campaign.media.length > 0 && (
        <Box>
          <Text fontSize="40px" fontWeight="bold" textAlign="left" mt="2rem">
            Media
          </Text>
          <Box w="100%">
            <Carousel
              style={{ padding: "16px 0px 48px 0px" }}
              slidesToShow={slidesToShow}
              cellSpacing={6}
              enableKeyboardControls={true}
              heightMode="max"
              initialSlideHeight={40}
              autoplay={campaign?.media.length !== 1}
              wrapAround={true}
              renderCenterLeftControls={null}
              renderCenterRightControls={null}
            >
              {campaign?.media.map((mediaItem) => (
                <Image key={mediaItem} border="2px solid" borderRadius="xl" src={mediaItem} />
              ))}
            </Carousel>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { FormControl, FormLabel, SimpleGrid, Avatar, Center, Select } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState, ChangeEvent } from "react";

import EditProfileContainer from "./EditProfileContainer";
import { User, USState } from "types";
import FormInput from "../../FormInput";
import FormTextArea from "../../FormTextArea";
import useValidatedForm from "../../../hooks/useValidatedForm";
import useUser from "../../../hooks/useUser";
import { useAppDispatch } from "../../../hooks";
import { updateUserProfilePic, updateUserProfile } from "../../../app-state/actions/user";

type EditProfileFieldValues = Pick<
  User,
  "firstName" | "lastName" | "headline" | "phone" | "city" | "state" | "biography"
>;

export default function EditProfile() {
  const { user } = useUser();
  const { register, isValid, handleSubmit, formProps } = useValidatedForm<EditProfileFieldValues>();
  const [profilePic, setProfilePic] = useState<string>(
    (user?.profilePic && user.profilePic + "?" + new Date().getTime()) || ""
  );
  const [changedProfilePic, setChangedProfilePic] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const createUpdateObject = (data: EditProfileFieldValues): Partial<EditProfileFieldValues> => {
    if (!user) return data;
    let newObject = {};
    for (const key in data) {
      if (data[key] !== user[key]) {
        newObject[key] = data[key];
      }
    }
    return newObject;
  };
  async function editProfile(data: EditProfileFieldValues) {
    setLoading(true);
    setErrorMessage("");
    try {
      if (changedProfilePic) {
        await dispatch(updateUserProfilePic(profilePic));
        setChangedProfilePic(false);
      }
      const updateObject = createUpdateObject(data);
      if (updateObject) {
        // ensure that city and state are always coupled in updates (for geolocation updating)
        if (updateObject.city && !updateObject.state) {
          updateObject.state = user?.state as USState;
        } else if (updateObject.state && !updateObject.city) {
          updateObject.city = user?.city as string;
        }

        // ensure that firstName and lastName are always coupled in updates (for cometchat updating)
        if (updateObject.firstName && !updateObject.lastName) {
          updateObject.lastName = user?.lastName;
        } else if (updateObject.lastName && !updateObject.firstName) {
          updateObject.firstName = user?.firstName;
        }
        await dispatch(updateUserProfile(updateObject));
      }
    } catch (e: any) {
      if (e.response?.status === 409) {
        setErrorMessage("The username you've entered is already in use.");
      } else {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    }
    setLoading(false);
  }

  function changeProfilePic(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setProfilePic(URL.createObjectURL(e.target.files[0]));
      setChangedProfilePic(true);
    }
  }

  return (
    <EditProfileContainer
      name="Edit Profile"
      saveBtnSide="right"
      top={-6}
      onSave={handleSubmit(editProfile)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Center minW="100%">
        <label htmlFor="profile-upload">
          <Avatar
            size="2xl"
            _hover={{ opacity: 0.8 }}
            transition="0.2s"
            bg={profilePic || user?.profilePic ? "white" : undefined}
            src={profilePic || user?.profilePic}
            cursor="pointer"
          >
            <AddIcon
              position="absolute"
              bottom={0}
              right="10px"
              borderRadius="full"
              bg="politiq.gray"
              boxSize={7}
              p={1}
            />
          </Avatar>
        </label>
        <input
          id="profile-upload"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          style={{ display: "none" }}
          onChange={changeProfilePic}
        />
      </Center>
      <SimpleGrid mt={4} columns={{ base: 1, xl: 3 }} spacing={3}>
        <FormInput
          name="firstName"
          label="First Name"
          defaultValue={user?.firstName}
          rules={{
            required: true,
            pattern: /^[a-z ,.'-]+$/i,
          }}
          {...formProps}
        />
        <FormInput
          name="lastName"
          label="Last Name"
          defaultValue={user?.lastName}
          rules={{
            required: true,
            pattern: /^[a-z ,.'-]+$/i,
          }}
          {...formProps}
        />
        <FormInput
          name="username"
          label="Username"
          defaultValue={user?.username}
          rules={{
            required: true,
            pattern: /^[a-z\d_.-]+$/i,
          }}
          {...formProps}
        />
      </SimpleGrid>
      <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
        <FormInput
          name="headline"
          label="Headline"
          defaultValue={user?.headline}
          rules={{ maxLength: 100 }}
          {...formProps}
        />
        <FormInput
          name="phone"
          label="Phone Number"
          defaultValue={user?.phone}
          rules={{
            required: true,
            pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
          }}
          {...formProps}
        />
      </SimpleGrid>
      <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
        <FormInput
          name="city"
          label="City"
          defaultValue={user?.city}
          {...formProps}
          rules={{
            required: true,
            pattern: /^[a-z ,."-]+$/i,
          }}
        />
        <FormControl isRequired>
          <FormLabel>State</FormLabel>
          <Select
            placeholder=" "
            focusBorderColor="politiq.lavender"
            defaultValue={user?.state}
            {...register("state", { required: true })}
          >
            {Object.values(USState).map((state) => (
              <option key={state}>{state}</option>
            ))}
          </Select>
        </FormControl>
      </SimpleGrid>
      <FormTextArea
        label="Biography"
        name="biography"
        defaultValue={user?.biography}
        rules={{ maxLength: 300 }}
        {...formProps}
      />
    </EditProfileContainer>
  );
}

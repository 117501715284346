import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Success() {
  const { success_id, verification } = useParams<{ success_id: string; verification: string }>();
  const history = useHistory();
  const [valid, setValid] = useState(true);
  useEffect(() => {
    const checkValidity = async () => {
      try {
        await axios.post("/auth/verifySession", { id: success_id });
      } catch (e: any) {
        setValid(false);
      }
    };
    checkValidity();
  }, []);
  return valid ? (
    <Box position="relative" height="100%">
      <Heading color="politiq.lavender" fontSize="2xl" pb={6}>
        Thank you for completing payment!
      </Heading>
      <Text mt="25px">Verification Code: {verification}</Text>
      <Text mt="10px">
        Please write this code down as you will need it to complete the sign-up process.
      </Text>
      <Box mt={{ base: "50px", lg: "250px" }} align="right" w={{ base: "100%", lg: "255px" }}>
        <Button
          onClick={() => {
            history.push("/signup");
          }}
          w="100%"
          variant="lavender"
        >
          Complete sign-up
        </Button>
      </Box>
    </Box>
  ) : (
    <Redirect to="/intro" />
  );
}

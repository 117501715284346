import {
  Stack,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";

export default function ExploreHeader() {
  return (
    <Box
      h={250}
      bgGradient="linear(180deg, rgba(180, 148, 255, 1) 0%, rgba(89, 24, 242, 1) 100%)"
      color="white"
      fontWeight="bold"
    >
      <Flex pt={95} pl="12.5%">
        <Box>
          <Stack spacing={2} direction="column" align="left">
            <Text fontSize="4xl">Explore</Text>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

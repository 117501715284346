import { FieldValues } from "react-hook-form";
import FormInput, { FormInputProps } from "../FormInput";

export default function AuthFormInput<T extends FieldValues>(props: FormInputProps<T>) {
  return (
    <FormInput
      variant="filled"
      bg="politiq.lightgray"
      border="2px"
      borderRadius={0}
      pl={3}
      persistentBorder
      {...props}
    />
  );
}

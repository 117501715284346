import { Box, Heading, FormControl, FormLabel, Select, Button, HStack } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import AuthFormInput from "./AuthFormInput";
import * as Types from "types";
import useValidatedForm from "../../hooks/useValidatedForm";

interface CampaignInfoFormProps {
  name: string;
  position: string;
}

export default function CampaignInfoForm({ name, position }: CampaignInfoFormProps) {
  // form rendered during the third (optional, only for campaigns) stage of signup which collects campaign info described by SignUpInfo
  // uses form validation with FormInput and react-hook-form
  const { register, isValid, handleSubmit, formProps } =
    useValidatedForm<Types.CampaignSignUpInfo>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [activePositionLevel, setActivePositionLevel] = useState(position);
  const history = useHistory();
  const categories = {
    Local: Object.values(Types.LocalPosition),
    County: Object.values(Types.CountyPosition),
    Regional: Object.values(Types.RegionalPosition),
    State: Object.values(Types.StatePosition),
    Federal: Object.values(Types.FederalPosition),
    " ": [" "],
  };
  const positionLevelDefault = register("positionLevel", { required: true });
  async function initCampaign(data: Types.CampaignSignUpInfo) {
    // issues a POST request to /campaign/profile with the new data and expects a 409 error if the username is already in use
    setLoading(true);
    setErrorMessage("");
    try {
      await axios.post("/campaign/profile", data);
      await history.push("/rotunda");
    } catch (e: any) {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Heading color="politiq.lavender" fontSize="2xl" mb={6}>
        Welcome, {name}!
      </Heading>
      <form onSubmit={handleSubmit(initCampaign)}>
        <Box>
          <AuthFormInput
            name="campaignName"
            label="Campaign Name"
            {...formProps}
            rules={{
              required: true,
              pattern: /^\w+( \w+)*$/i,
            }}
          />
        </Box>
        <Box mt={2}>
          <AuthFormInput
            name="headline"
            label="Headline/Slogan"
            {...formProps}
            rules={{
              required: true,
              maxLength: 100,
            }}
          />
        </Box>
        <Box mt={2}>
          <AuthFormInput
            name="websiteUrl"
            label="Website"
            {...formProps}
            rules={{
              required: false,
              pattern:
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
            }}
          />
        </Box>
        <Box borderBottom="1px solid" borderColor="politiq.lavender" py="3"></Box>
        <HStack mb={5} mt={4} spacing="5%">
          <FormControl isRequired>
            <FormLabel>Position Level</FormLabel>
            <Select
              focusBorderColor="politiq.lavender"
              backgroundColor="politiq.lightgray"
              borderRadius={0}
              w="100%"
              onChange={(e) => {
                setActivePositionLevel(e.target.value);
                positionLevelDefault.onChange(e);
              }}
              name={positionLevelDefault.name}
              onBlur={positionLevelDefault.onBlur}
              ref={positionLevelDefault.ref}
              isReadOnly={true}
            >
              <option>{position}</option>
            </Select>
          </FormControl>

          {categories[activePositionLevel].length && (
            <FormControl isRequired>
              <FormLabel>Office Level</FormLabel>
              <Select
                focusBorderColor="politiq.lavender"
                backgroundColor="politiq.lightgray"
                borderRadius={0}
                w="100%"
                {...register("officeLevel", { required: true })}
              >
                {categories[activePositionLevel].map((officeLevel) => (
                  <option key={officeLevel}>{officeLevel}</option>
                ))}
              </Select>
            </FormControl>
          )}
        </HStack>
        <Box color="red.500" mb={4}>
          {errorMessage}
        </Box>
        <Box align="right" mt="2">
          <Button
            type="submit"
            variant="lavender"
            width={{ base: "100%", lg: "245px" }}
            disabled={!isValid || loading}
            isLoading={loading}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
}

import {
  InputGroup,
  Textarea,
  TextareaProps,
  InputRightElement,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";
import { FieldValues, Path } from "react-hook-form";

import { FormFieldProps } from "./FormInput";

type FormTextAreaProps<T> = FormFieldProps<T> & TextareaProps;

export default function FormTextArea<T extends FieldValues>({
  name,
  label,
  register,
  watch,
  rules,
  errors,
  touchedFields,
  dirtyFields,
  persistentBorder = false,
  defaultValue,
  ...props
}: FormTextAreaProps<T>) {
  const hasError = errors && errors[name];
  const currentValue = watch(name as Path<T>);

  const hasIcon = rules && (dirtyFields[name] || touchedFields[name]);

  const [isMaxHeight, setIsMaxHeight] = useState(false);
  useEffect(() => {
    const textarea = document.getElementById(name as string);
    if (textarea) {
      textarea.style.height = "0px";
      const maxHeight = 210;
      const height = Math.min(maxHeight, textarea?.scrollHeight + 10);
      textarea.style.height = height + "px";
      setIsMaxHeight(height === maxHeight);
    }
  }, [currentValue]);

  let borderColor;
  if (persistentBorder) {
    if (hasError) borderColor = "red.500";
    else if (rules ? touchedFields[name] : currentValue) {
      borderColor = "politiq.lavender";
    }
  }

  return (
    <FormControl isInvalid={errors && errors[name]} isRequired={Boolean(rules?.required)}>
      <FormLabel htmlFor={name} fontSize="md">
        {label}
      </FormLabel>
      <InputGroup>
        <Textarea
          id={name}
          border={borderColor ? "2px" : "1px"}
          borderColor={borderColor}
          focusBorderColor={hasError ? "red.500" : "politiq.lavender"}
          defaultValue={defaultValue}
          {...register(name as Path<T>, rules)}
          {...props}
        />
        {
          <InputRightElement w={10} mr={isMaxHeight ? 4 : 0}>
            {hasIcon &&
              (hasError ? <WarningIcon color="red.500" /> : <CheckIcon color="politiq.lavender" />)}
          </InputRightElement>
        }
      </InputGroup>
    </FormControl>
  );
}

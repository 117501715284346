import { Route, RouteProps, Redirect } from "react-router-dom";

import { auth } from "../firebase";

export default function ProtectedRoute({ path, children, ...props }: RouteProps) {
  // used exactly the same as react-router's Route, except it only shows the route to users who are logged in
  // users who aren't logged in are redirected to the login page
  // after logging in they're redirected back to the page they came from
  return (
    <Route path={path} {...props} render={() => {
      if (!auth.currentUser) {
        return <Redirect to={"/login?redirect=" + path} />;
      }
      return children;
    }} />
  );
}
import { Box, Flex, Center, Stack, chakra, Text } from "@chakra-ui/react";

import SkeletonAvatar from "../SkeletonAvatar";
import { Endorsement, User } from "types";
import useUser from "../../hooks/useUser";
import { Link } from "react-router-dom";

const ChakraLink = chakra(Link);

export default function SingleEndorsement({ endorsement }: { endorsement: Endorsement }) {
  const endorser = endorsement.endorser as User;
  const { user } = useUser();

  return (
    <Box align="left" mt={5}>
      <Flex>
        <SkeletonAvatar minW="60px" w="60px" h="60px" src={endorser.profilePic} />
        <Center pl={4}>
          <Stack spacing={0}>
            <ChakraLink
              to={"/profile/" + endorser.username}
              color="black"
              fontWeight="bold"
              _hover={{ color: "politiq.darkgray" }}
              transition="0.2s"
            >
              {`${endorser.firstName.toUpperCase()} ${endorser.lastName.toUpperCase()}`}
            </ChakraLink>
            <Text noOfLines={2} fontSize="sm">
              {endorser.headline}
            </Text>
          </Stack>
        </Center>
      </Flex>
      <Box my={3}>{endorsement.message}</Box>
      {endorsement.relationship !== "" && (
        <Box color="politiq.lavender" fontWeight="bold">
          {endorser.firstName} worked with {user?.firstName} at {endorsement.relationship}
        </Box>
      )}
    </Box>
  );
}

import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Text,
  Grid,
  Select,
  Wrap,
  Button,
  Box,
} from "@chakra-ui/react";

import EditProfileContainer from "./EditProfileContainer";
import { useAppDispatch } from "../../../hooks";
import { updateUserProfile } from "../../../app-state/actions/user";

interface AddItemProps {
  name: string;
  profileField: string;
  maxItems: number;
  categories: {
    [category: string]: string[];
  };
  preselected: string[];
}

export default function AddItem({
  name,
  profileField,
  maxItems,
  categories,
  preselected,
}: AddItemProps) {
  const [activeCategory, setActiveCategory] = useState("");
  const [selected, setSelected] = useState(preselected);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => setSelected(preselected), [preselected]);

  async function editItems() {
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(updateUserProfile({ [profileField]: selected }));
    } catch (e: any) {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  function addItem(item) {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
      setErrorMessage("");
    } else if (selected.length < maxItems) {
      setSelected([item, ...selected]);
    } else {
      setErrorMessage(`You can only select up to ${maxItems} ${name.toLowerCase()}.`);
    }
  }

  return (
    <EditProfileContainer
      name={"Add " + name}
      saveBtnSide="center"
      inline
      onSave={editItems}
      loading={loading}
      errorMessage={errorMessage}
      openButton={(onOpen) => (
        <Button
          leftIcon={<AddIcon />}
          size="xs"
          variant="outline"
          onClick={onOpen}
          borderRadius="full"
          mr={2}
          mb={2}
        >
          Add
        </Button>
      )}
    >
      <Grid
        templateColumns={{ base: "1fr", sm: "80px 1fr" }}
        columnGap={6}
        rowGap={4}
        px={{ base: 0, sm: 12, md: 24 }}
      >
        <Text fontSize="xl" mt={1}>
          Category
        </Text>
        <Select
          maxW="300px"
          placeholder="Select category"
          onChange={(e) => setActiveCategory(e.target.value)}
        >
          {Object.keys(categories).map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </Select>
        {categories[activeCategory] && (
          <>
            <Text fontSize="xl">{name}</Text>
            <Box>
              <Wrap mt={1}>
                {categories[activeCategory].map((item) => (
                  <Button
                    key={item}
                    size="xs"
                    bg={selected.includes(item) ? "politiq.darkgray" : "white"}
                    color={selected.includes(item) ? "white" : "politiq.darkgray"}
                    _hover={{
                      bg: selected.includes(item) ? "politiq.gray" : "politiq.brightgray",
                    }}
                    border="1px"
                    borderColor="politiq.darkgray"
                    borderRadius="full"
                    mr={2}
                    mb={2}
                    onClick={() => addItem(item)}
                  >
                    {item}
                  </Button>
                ))}
              </Wrap>
            </Box>
          </>
        )}
      </Grid>
    </EditProfileContainer>
  );
}

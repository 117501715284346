import {
  Box,
  Heading,
  Button,
  Flex,
  Spacer,
  Text,
  Checkbox,
  HStack,
  chakra,
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import { auth } from "../../firebase";
import AuthFormInput from "./AuthFormInput";
import ProviderAuth from "./ProviderAuth";
import { AuthFormProps } from "./Auth";
import useValidatedForm from "../../hooks/useValidatedForm";
import { loginUserCometChat } from "../../cometchat";

interface LogInFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const ChakraLink = chakra(Link);

export default function LogInForm({ setName }: AuthFormProps) {
  const { register, isValid, handleSubmit, formProps } = useValidatedForm<LogInFormValues>();

  const history = useHistory();
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect") || "/rotunda";

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);

  async function login(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmit(async (data: LogInFormValues) => {
      setLoading(true);
      setErrorMessage("");
      try {
        await signInWithEmailAndPassword(auth, data.email, data.password);
        if (!data.rememberMe) {
          await setPersistence(auth, browserSessionPersistence);
        }
        const userAsToken = await auth.currentUser?.getIdTokenResult();
        if (userAsToken) {
          const cometchatAuthToken = userAsToken.claims.chatToken;
          if (cometchatAuthToken) {
            await loginUserCometChat(cometchatAuthToken as string);
          }
        }
        history.push(redirect);
      } catch (e: any) {
        setLoading(false);
        setErrorMessage("The email or password you've entered is incorrect.");
      }
    })(e);
  }

  return auth.currentUser ? (
    <Redirect to="/rotunda" />
  ) : (
    <Box>
      <Heading color="politiq.lavender" fontSize="2xl" pb={6}>
        Log In
      </Heading>
      <form onSubmit={login}>
        <Box py="3">
          <AuthFormInput {...formProps} name="email" label="Email" rules={{ required: true }} />
        </Box>
        <Box pb="3">
          <AuthFormInput
            {...formProps}
            name="password"
            label="Password"
            type="password"
            rules={{ required: true }}
          />
        </Box>
        <Flex>
          <HStack>
            <Checkbox defaultIsChecked colorScheme="purple" {...register("rememberMe")}>
              Remember Me
            </Checkbox>
          </HStack>
          <Spacer />
          <Text mt="-2px">
            {" "}
            <ChakraLink color="politiq.lavender" fontSize="14px" _hover={{}} to="/forgotpassword">
              Forgot Password?
            </ChakraLink>
          </Text>
        </Flex>
        <Box color="red.500" mb={4}>
          {errorMessage}
        </Box>
        <Box align="center" mt={4}>
          <Button
            type="submit"
            variant="lavender"
            w="100%"
            disabled={!isValid || loading}
            isLoading={loading}
          >
            Log In
          </Button>
        </Box>
      </form>
      <ProviderAuth
        prefix="Log in"
        setName={setName}
        setErrorMessage={setErrorMessage}
        redirect={redirect}
        enabled={true}
        login={true}
      />
      <Box align="center" mt="8">
        <Text>
          Don't have an account?{" "}
          <ChakraLink color="politiq.lavender" to="/intro">
            Sign up.
          </ChakraLink>
        </Text>
      </Box>
    </Box>
  );
}

import { Box } from "@chakra-ui/react";
import PolicyPlatform from "./PolicyPlatform";
import useCampaign from "../../hooks/useCampaign";
import AddNewButton from "./EditProfile/AddNewButton";

export default function Platform() {
  const { campaign, isCurrentCampaign } = useCampaign();

  return (
    <Box width={["90%", "80%", "60%"]}>
      <Box mb={5}>
        <AddNewButton isCurrentCampaign={isCurrentCampaign} buttonType="platformPoint" />
      </Box>
      <Box>
        {campaign?.platformPoints?.map((platform, i) => (
          <Box mb={8} key={i}>
            <PolicyPlatform platform={platform}></PolicyPlatform>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

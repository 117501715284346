import { Center, Button, Box, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useState } from "react";

import EditProfileContainer from "./EditProfileContainer";
import { useAppDispatch } from "../../../hooks";
import { useHistory, useLocation } from "react-router";
import { auth } from "../../../firebase";
import useValidatedForm from "../../../hooks/useValidatedForm";
import { Endorsement } from "types";
import FormTextArea from "../../FormTextArea";
import { addUserEndorsement } from "../../../app-state/actions/user";
import useUser from "../../../hooks/useUser";

export type AddEndorsementFieldValues = Pick<Endorsement, "relationship" | "message">;

interface AddEndorsementProps {
  currentUserEndorsement?: Endorsement;
}

export default function AddEndorsement({ currentUserEndorsement }: AddEndorsementProps) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUser();
  const name = user && user.firstName + " " + user.lastName;
  const { isValid, handleSubmit, formProps, register, reset } =
    useValidatedForm<AddEndorsementFieldValues>();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  async function updateEndorsement(data?: AddEndorsementFieldValues) {
    setLoading(true);
    setErrorMessage("");
    try {
      const visibleEndorsements = user?.endorsements?.filter((e) => !e.isHidden).length as number;
      await dispatch(addUserEndorsement(user?.uid as string, visibleEndorsements, data));
      if (!data) reset();
    } catch (e: any) {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  return (
    <EditProfileContainer
      name={(currentUserEndorsement ? "Edit" : "Add") + " Endorsement"}
      currentUserOnly={false}
      saveBtnSide="right"
      openButton={(onOpen) => (
        <Center>
          <Button
            w="100%"
            variant="outline"
            fontWeight="bold"
            onClick={auth?.currentUser ? onOpen : () => history.push("/login?redirect=" + pathname)}
          >
            {currentUserEndorsement ? (
              <>
                <EditIcon mr={2} />
                Edit Endorsement
              </>
            ) : (
              <>
                <AddIcon mr={2} />
                Add Endorsement
              </>
            )}
          </Button>
        </Center>
      )}
      onSave={handleSubmit((data) => updateEndorsement(data))}
      {...(currentUserEndorsement && {
        deleteDialogName: "Endorsement",
        onDelete: updateEndorsement,
      })}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Box mb={4}>
        Your endorsement will show up on the profile of{" "}
        <Box as="span" color="politiq.lavender">
          {name}
        </Box>
        .
      </Box>
      {user?.experiences?.length ? (
        <FormControl>
          <FormLabel>
            Relationship{" "}
            <Box as="span" color="politiq.darkgray">
              (common workplace)
            </Box>
          </FormLabel>
          <Select
            mb={4}
            placeholder="N/A"
            focusBorderColor="politiq.lavender"
            defaultValue={currentUserEndorsement?.relationship}
            {...register("relationship")}
          >
            {user?.experiences.map((experience, i) => (
              <option key={i} value={experience.employer}>
                {experience.employer}
              </option>
            ))}
          </Select>
        </FormControl>
      ) : null}
      <FormTextArea
        label="Message"
        name="message"
        placeholder="Enter your endorsement here!"
        defaultValue={currentUserEndorsement?.message}
        rules={{ maxLength: 300, required: true }}
        {...formProps}
      />
    </EditProfileContainer>
  );
}

import SuggestedJobCard from "./SuggestedJobCard";
import { JobListing } from "types";
// import { createBreakpoints } from '@chakra-ui/theme-tools';
// import { extendTheme } from '@chakra-ui/react'
import {
  Box,
  Text,
  Flex,
  Link,
  useDisclosure,
  LinkBox,
  WrapItem,
  Tag
} from "@chakra-ui/react";

// const breakpoints = createBreakpoints({
//   sm: '480px',
//   md: '768px',
//   lg: '992px',
//   xl: '1300px',
//   '2xl': '1536px',
// })

// const theme = extendTheme({ breakpoints })

export default function JobSuggest({
  favorites,
  suggestions,
}: {
  favorites: Set<string>;
  suggestions: JobListing[];
}) {
  const { onOpen } = useDisclosure();

  // const [suggestedJobs, setJobs] = useState<Array<JobListing>>([]);
  // const [locations, setLocations] = useState<Array<String>>([]);
  // const [value, setValue] = useState<Number>(0);

  // useEffect(() => {
  //   async function getSuggestions() {
  //     // await axios.get("/job/suggest").then((response) => response.data).then((data) => {
  //     //   console.log(data.results)
  //     // })

  //     await fetch("http://localhost:8080/job/suggest")
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setJobs(data.results);
  //         data.results.map((job) => {
  //           getLocation(job.campaign);
  //         });
  //       });

  //     // await axios.get("http://localhost:8080/job/suggest").then(response => {
  //     //   console.log(response);
  //     // })
  //   }

  //   async function getLocation(uid) {
  //     return await fetch("http://localhost:8080/campaign?uid=" + uid)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setLocations((locations) => [...locations, data.city + ", " + data.state]);
  //       });
  //   }

  //   getSuggestions();
  // }, [value]);

  // function formatDate(mdate) {
  //   const date = new Date(mdate);
  //   const now = new Date();
  //   return Math.round((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
  // }

  if (suggestions && suggestions.length > 3) {
    suggestions = suggestions.slice(0, 3);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      alignItems="center"
      mb={10}
      mt={{ base: "30px" }}
      ml={suggestions.length === 0 ? { base: "-42%", sm: "-170px", cm: "0px" } : { base: "0px" }}
    >
      <Box alignItems="start">
        <Text
          fontSize="34px"
          w="90%"
          ml={{ base: "5%", sm: "0%" }}
          fontWeight="bold"
          alignSelf="flex-start"
          mb="1em"
        >
          Suggested Jobs
        </Text>
        <Flex
          alignSelf="flex-start"
          position="relative"
          flexDirection={{ base: "column", cm: "row" }}
          justifyContent="space-between"
          w={{ cm: "1280px" }}
          mb="-10px"
        >
          {suggestions.map((job) => {
            return (
              <LinkBox key={job.id} onClick={onOpen} cursor="pointer">
                <SuggestedJobCard
                  tags={job.skills}
                  jobname={job.title}
                  favorited={favorites.has(job.id as string)}
                  paid={job.pay}
                  commitment={job.commitment}
                  location={job.city ? job.city + ", " + job.state : "Temp location"}
                  // daysPosted={formatDate(job.lastmodified)}
                  rotundapage={true}
                  job={job}
                />
              </LinkBox>
            );
          })}
        </Flex>
        <Link
          ml={{ base: "5%", sm: "0%" }}
          href="/explore"
          color="politiq.darkgray"
          fontSize="20px"
          w="fit-content"
        >
          See more jobs →
        </Link>
      </Box>
    </Box>
  );
}

import {
    Stack,
    Flex,
    Box,
    Text,
  } from "@chakra-ui/react";
  
  export default function FavoritesHeader() {
    return (
      <Box
        h={250}
        bgGradient="linear(180deg, rgba(180, 148, 255, 1) 0%, rgba(89, 24, 242, 1) 100%)"
        color="white"
        fontWeight="bold"
      >
        <Flex pt={95} pl="10%">
          <Box>
            <Stack spacing={10} direction="row" align="center">
              <Text fontSize="4xl">Your Favorites</Text>
            </Stack>
          </Box>
        </Flex>
      </Box>
    );
  };
  
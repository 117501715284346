import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Grid,
  GridItem,
  Center,
  Spinner,
  SlideFade,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import Platform from "./Platform";
import AboutCampaign from "./AboutCampaign";
import Team from "./Team";
import Jobs from "./Jobs";
import CampaignProfileHeader from "./CampaignProfileHeader";
import NotFound from "../NotFound";
import useCampaign from "../../hooks/useCampaign";
import { useAppDispatch } from "../../hooks";
import { getCampaign } from "../../app-state/actions/campaign";
import { getFavorites } from "../../app-state/actions/favorites";
import { auth } from "../../firebase";
import useFavorites from "../../hooks/useFavorites";
import useIsCampaign from "../../hooks/useIsCampaign";
import * as Types from "types";
const tabs = ["About", "Job", "Platform", "Team"];

export default function CampaignProfile() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { campaign, loading, error } = useCampaign();
  const isCampaign = useIsCampaign();
  useEffect(() => {
    dispatch(getCampaign(id, true));
  }, [id]);
  useEffect(() => {
    if (auth.currentUser?.uid) {
      if (isCampaign !== undefined) {
        const accountType = isCampaign ? "campaign" : "user";
        dispatch(getFavorites(accountType));
      }
    }
  }, [isCampaign]);
  const obj = useFavorites();
  const favorites = obj.favorites;
  // const favsError = obj.error;

  if (loading) {
    return (
      <Center mt="calc(50vh - 80px)">
        <Spinner size="lg" color="politiq.lavender" />
      </Center>
    );
  }

  if (campaign) {
    window.history.replaceState(null, "", "/campaign/" + campaign.username);
  }

  if (error || campaign === null) {
    return <NotFound />;
  }

  let favoritedJobSet = new Set<string>();
  let favoritedUserSet = new Set<string>();
  let isCampaignFavorited = false;
  if (favorites) {
    if (favorites?.favoriteJobs !== undefined && favorites?.favoriteJobs !== null) {
      for (const job of favorites?.favoriteJobs) {
        if (Types.isJobListing(job) && job?.id) {
          favoritedJobSet.add(job.id);
        }
      }
    }

    if (favorites?.favoriteUsers !== undefined && favorites?.favoriteUsers !== null) {
      for (const favoritedUser of favorites?.favoriteUsers) {
        if (Types.isUser(favoritedUser) && favoritedUser.uid) {
          favoritedUserSet.add(favoritedUser.uid);
        }
      }
    }

    // compare by id rather than using an 'in' statement because its cheaper to compare ids than full campaigns!
    if (favorites?.favoriteCampaigns !== undefined && favorites?.favoriteCampaigns !== null) {
      for (const favoritedCampaign of favorites.favoriteCampaigns) {
        if (favoritedCampaign.uid === campaign.uid) {
          isCampaignFavorited = true;
          break;
        }
      }
    }
  }

  return (
    <SlideFade in={Boolean(campaign)} transition={{ enter: { duration: 0.3 } }}>
      <CampaignProfileHeader isCampaignFavorited={isCampaignFavorited} />
      <Tabs defaultIndex={1} variantColor="politiq.darkgray">
        <TabList justifyContent="center" bg="white">
          <Grid templateColumns="repeat(4, 1fr)" style={{ width: "80%" }}>
            {tabs.map((tab, i) => (
              <GridItem
                colSpan={1}
                borderRightWidth={i === tabs.length - 1 ? 0 : 1}
                borderRightColor="politiq.lightgray"
                key={i}
              >
                <Center>
                  <Tab
                    _selected={{
                      color: "black",
                      borderBottom: "4px #969191 solid",
                      outline: "none",
                    }}
                    _focus={{ outline: 0 }}
                    width="80%"
                  >
                    {tab}
                  </Tab>
                </Center>
              </GridItem>
            ))}
          </Grid>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Center>
              <AboutCampaign />
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <Jobs favoritedSet={favoritedJobSet} />
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <Platform />
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <Team favoritedSet={favoritedUserSet} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SlideFade>
  );
}

import { Box, VStack, SimpleGrid, Image, Text, Heading } from "@chakra-ui/react";
import React, { useState } from "react";

import { DesktopOnly } from "../DesktopOnly";
import { MobileOnly } from "../MobileOnly";
import CampaignInfoForm from "./CampaignInfoForm";
import InfoForm from "./InfoForm";
import logo from "../../images/transparent-logo.png";
export interface AuthFormProps {
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCampaignFlow: React.Dispatch<React.SetStateAction<boolean>>;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
}

export default function Auth({ page }: { page: React.FC<AuthFormProps> }) {
  // renders the base layout of the login and signup pages
  // this includes the Politiq logo, feature highlights, and a white box where the forms are rendered
  const [name, setName] = useState<string>();
  const [campaignFlow, setCampaignFlow] = useState<boolean>(false);
  const [generalInfoFormFinished, setGeneralInfoFormFinished] = useState<boolean>();
  const [position, setPosition] = useState<string>("");

  const heading = (
    <>
      <Image src={logo} w={{ base: name ? 32 : 60, lg: 40 }} />
      <Text
        color="politiq.darkpurple"
        fontSize={{ base: "xl", lg: "2xl" }}
        textAlign={{ base: "center", lg: "left" }}
        mt={5}
        d={{ base: name ? "none" : "inline", lg: "inline" }}
      >
        Government at a touch.
      </Text>
    </>
  );

  return (
    <Box bg={{ base: "white", lg: "politiq.brightgray" }} minH="100vh">
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        pt={{ base: 4, lg: 12 }}
        pl={{ base: 10, lg: 24 }}
        pr={10}
        spacing={20}
      >
        <Box my={4}>
          <MobileOnly>
            <VStack>{heading}</VStack>
          </MobileOnly>
          <DesktopOnly>
            {heading}
            <Heading fontSize="2xl" mt={10}>
              Political Jobs and Internships
            </Heading>
            <Text mt={4} fontSize="md" lineHeight="35px">
              Find, apply for, and complete paid and volunteer opportunities with local political
              campaigns.
            </Text>
            <Heading fontSize="2xl" mt={12}>
              Educate Yourself and Your Community
            </Heading>
            <Text mt={4} fontSize="md" lineHeight="35px">
              Politics is about the issues: learn what your candidates care about and find others in
              your community who are passionate about the same things you are.
            </Text>
          </DesktopOnly>
        </Box>
        <Box
          bg="white"
          px={{ base: 0, lg: 12 }}
          py={{ base: 6, lg: 10 }}
          my={{ base: -24, lg: 0 }}
          borderRadius={10}
        >
          {name === undefined ? (
            page({ setName, setCampaignFlow, setPosition })
          ) : campaignFlow === true && generalInfoFormFinished === true ? (
            <CampaignInfoForm name={name} position={position} />
          ) : (
            <InfoForm
              setGeneralInfoFormFinished={setGeneralInfoFormFinished}
              name={name}
              campaignFlow={campaignFlow}
            />
          )}
        </Box>
      </SimpleGrid>
    </Box>
  );
}

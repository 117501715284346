import { Flex, Text, Box} from "@chakra-ui/react";

import CampaignTeamMember from "../CampaignProfile/CampaignTeamMember";
import { User } from "types";

export default function FavPeople({ favoriteUsers }: { favoriteUsers: User[] }) {

  let favusers = (
    <>
      {(favoriteUsers !== undefined && favoriteUsers.length === 0) ||
      favoriteUsers === undefined ? (
        <Text fontSize="xl">No favorites here yet...</Text>
      ) : (
        <Flex wrap="wrap" justifyContent="center">
          {favoriteUsers?.map((teamMember) => (
            <Box mr="20px" mb="25px">
              <CampaignTeamMember
                teamMember={teamMember as User}
                initialFavorited={true}
                overrideFavButton={true}
              />
            </Box>
          ))}
        </Flex>
      )}
    </>
  );
 

  return (
    <Box width="82%" mt="50px">
      {favusers}
    </Box>
  );
}

import {
  CheckIcon,
  ViewIcon,
  ViewOffIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  InputGroup,
  Input,
  InputProps,
  InputRightElement,
  FormControl,
  FormLabel,
  Button
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FieldErrors,
  FieldNamesMarkedBoolean,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
  UseFormWatch,
  Path,
} from "react-hook-form";

export interface FormFieldProps<T> {
  name: string;
  label: string;
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  defaultValue?: string;
  rules?: RegisterOptions;
  errors?: FieldErrors<T>;
  touchedFields: FieldNamesMarkedBoolean<T>;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  persistentBorder?: boolean;
  checkCode?;
}

export type FormInputProps<T> = FormFieldProps<T> & InputProps;

export default function FormInput<T extends FieldValues>({
  name,
  label,
  register,
  watch,
  rules,
  errors,
  touchedFields,
  dirtyFields,
  persistentBorder = false,
  defaultValue,
  ...props
}: FormInputProps<T>) {
  // renders an input component which combines Chakra's Input component and the validation capabilities of react-hook-form
  // border turns red and shows a warning icon when input is invalid, and border turns purple with a check icon when it's valid
  // you can see how to use this component with react-hook-form in components/Auth/SignUpForm.tsx
  // also automatically includes a show/hide button for password inputs

  // TODO: border sometimes doesn't change color when the fields are autofilled with Google
  const hasError = errors && errors[name];
  const isPassword = props.type === "password" || props.type === "verification";
  const currentValue = watch(name as Path<T>);

  const hasIcon = rules && (dirtyFields[name] || touchedFields[name]);

  let borderColor;
  if (persistentBorder) {
    if (hasError) borderColor = "red.500";
    else if (rules ? touchedFields[name] : currentValue) {
      borderColor = "politiq.lavender";
    }
  }

  const [show, setShow] = useState(false);
  let showAddon;
  if (isPassword) {
    const showAddonProps = {
      mr: 2,
      cursor: "pointer",
      _hover: {
        color: "politiq.lavender",
      },
    };
    if (show) {
      showAddon = (
        <ViewIcon {...showAddonProps} onClick={() => setShow(false)} />
      );
    } else {
      showAddon = (
        <ViewOffIcon {...showAddonProps} onClick={() => setShow(true)} />
      );
    }
  }

  return (
    <FormControl
      isInvalid={errors && errors[name]}
      isRequired={Boolean(rules?.required)}
    >
      <FormLabel htmlFor={name} fontSize="md">
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          border={borderColor ? "2px" : "1px"}
          borderColor={borderColor}
          focusBorderColor={hasError ? "red.500" : "politiq.lavender"}
          defaultValue={defaultValue}
          {...register(name as Path<T>, rules)}
          {...props}
          {...(isPassword && { type: show ? "text" : "password" })}
        />
        {
          <InputRightElement
            w={props.type === "verification" ? "120px" : isPassword && hasIcon ? 16 : 10}
          >
            {showAddon}
            {hasIcon &&
              (hasError ? <WarningIcon color="red.500" /> : <CheckIcon color="politiq.lavender" />)}
            {props.type === "verification" && (
              <Button
                w="fit-content"
                px="10px"
                borderRadius={0}
                backgroundColor="politiq.magenta"
                color="white"
                onClick={props.checkCode}
              >
                Check Code
              </Button>
            )}
          </InputRightElement>
        }
      </InputGroup>
    </FormControl>
  );
}

import { useState, useEffect, createContext } from "react";
import { storage } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

export const NewsContext = createContext({ bizNews: [], poliNews: [] });

export default function NewsProvider({ children }) {
  const [bizNews, setBizNews] = useState([]);
  const [poliNews, setPoliNews] = useState([]);

  async function testGetFile() {
    const bizRef = ref(storage, "news/business_news.txt");
    const bizUrl = await getDownloadURL(bizRef);
    const bizQuery = await fetch(bizUrl);
    const bizArticles = await bizQuery.json();
    setBizNews(bizArticles);
    const poliRef = ref(storage, "news/politics_news.txt");
    const poliUrl = await getDownloadURL(poliRef);
    const poliQuery = await fetch(poliUrl);
    const poliArticles = await poliQuery.json();
    setPoliNews(poliArticles);
  }
  useEffect(() => {
    testGetFile();
  }, []);

  return <NewsContext.Provider value={{ bizNews, poliNews }}>{children}</NewsContext.Provider>;
}

import { useRadio, Box } from "@chakra-ui/react"

export default function IconRadioButton(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getCheckboxProps()
    
    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          boxShadow='md'
          _checked={{
            bg: 'politiq.lightpurple',
            color: 'white',
            borderColor: 'politiq.lavender',
          }}
          px="10%"
          py="10%"
        >
          {props.children}
        </Box>
      </Box>
    )
  }
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Flex,
  Checkbox,
  Button,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import AuthFormInput from "./AuthFormInput";
import { SignUpInfo, USState } from "types";
import useValidatedForm from "../../hooks/useValidatedForm";

interface InfoFormProps {
  name: string;
  setGeneralInfoFormFinished: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  campaignFlow: boolean;
}

export default function InfoForm({
  name,
  setGeneralInfoFormFinished,
  campaignFlow,
}: InfoFormProps) {
  // form rendered during the second stage of signup which collects user info described by SignUpInfo
  // uses form validation with FormInput and react-hook-form
  const { register, isValid, handleSubmit, formProps } = useValidatedForm<SignUpInfo>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function initAccount(data: SignUpInfo) {
    // issues a POST request to /user or /campaign with the new data and expects a 409 error if the username is already in use
    setLoading(true);
    setErrorMessage("");
    try {
      let postUrl = "/user/profile";
      if (campaignFlow) {
        postUrl = "/campaign/profile";
      }
      await axios.post(postUrl, data);
      setGeneralInfoFormFinished(true);
      if (!campaignFlow) {
        await history.push("/rotunda");
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response?.status === 409) {
        setErrorMessage("The username you've entered is already in use.");
      } else {
        if (e.response.data.errors[0].param === "phone") {
          setErrorMessage("The phone number you've entered is invalid.");
        } else {
          setErrorMessage("An unknown error occurred. Please try again.");
        }
      }
    }
  }

  return (
    <Box>
      <Heading color="politiq.lavender" fontSize="2xl" mb={6}>
        Welcome, {name}!
      </Heading>
      <form onSubmit={handleSubmit(initAccount)}>
        <Box>
          <AuthFormInput
            name="username"
            label="Username"
            {...formProps}
            rules={{
              required: true,
              pattern: /^[a-z\d_.-]+$/i,
            }}
          />
        </Box>
        <Box borderBottom="1px solid" borderColor="politiq.lavender" py="5">
          <AuthFormInput
            name="phone"
            label="Phone Number"
            {...formProps}
            rules={{
              required: true,
              pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
            }}
          />
        </Box>
        <HStack mb={7} mt={4} spacing="5%">
          <AuthFormInput
            name="city"
            label="City"
            {...formProps}
            rules={{
              required: true,
              pattern: /^[a-z ,."-]+$/i,
            }}
          />
          <FormControl isRequired>
            <FormLabel>State</FormLabel>
            <Select
              placeholder=" "
              focusBorderColor="politiq.lavender"
              backgroundColor="politiq.lightgray"
              borderRadius={0}
              w="100%"
              {...register("state", { required: true })}
            >
              {Object.values(USState).map((state) => (
                <option key={state}>{state}</option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        {!campaignFlow && (
          <Flex>
            <Checkbox colorScheme="purple" defaultChecked {...register("registeredToVote")}>
              Are you registered to vote?
            </Checkbox>
          </Flex>
        )}
        <Box color="red.500" mb={4}>
          {errorMessage}
        </Box>
        <Box align="right" mt="2">
          <Button
            type="submit"
            variant="lavender"
            width={{ base: "100%", lg: "245px" }}
            disabled={!isValid || loading}
            isLoading={loading}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
}

import NewsSuggest from "../News/NewsSuggest";
import CampaignSuggest from "./CampaignSuggest";
import HeadlineArticle from "./HeadlineArticle";
import NewsProvider from "../News/NewsProvider";
import { Box, SlideFade } from "@chakra-ui/react";
import JobSuggest from "./JobSuggest";
import { useEffect } from "react";
import { getFavorites } from "../../app-state/actions/favorites";
import { auth } from "../../firebase";
import { useAppDispatch } from "../../hooks";
import useFavorites from "../../hooks/useFavorites";
import useIsCampaign from "../../hooks/useIsCampaign";
import * as Types from "types";
import { getSuggestions } from "../../app-state/actions/suggestions";
import useSuggestions from "../../hooks/useSuggestions";

export default function Rotunda() {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const userTopSkill = "topSkill";
  // const userTopPolicy = "topPolicy";
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  useEffect(() => {
    if (isCampaign !== undefined && auth.currentUser?.uid) {
      const accountType = isCampaign ? "campaign" : "user";
      dispatch(getFavorites(accountType));
      dispatch(getSuggestions(accountType));
    }
  }, [isCampaign]);

  const favObj = useFavorites();
  const favorites = favObj.favorites;
  // const favsError = favObj.error;

  const suggestionObj = useSuggestions();
  const suggestions = suggestionObj.suggestions;
  // const suggestionsError = suggestionObj.error;

  let favoritedJobSet = new Set<string>();
  let favoritedUserSet = new Set<string>();
  let favoritedCampaignSet = new Set<string>();
  if (favorites) {
    if (favorites?.favoriteJobs !== undefined && favorites?.favoriteJobs !== null) {
      for (const job of favorites?.favoriteJobs) {
        if (Types.isJobListing(job) && job?.id) {
          favoritedJobSet.add(job.id);
        }
      }
    }

    if (favorites?.favoriteUsers !== undefined && favorites?.favoriteUsers !== null) {
      for (const favoritedUser of favorites?.favoriteUsers) {
        if (Types.isUser(favoritedUser) && favoritedUser.uid) {
          favoritedUserSet.add(favoritedUser.uid);
        }
      }
    }

    if (favorites?.favoriteCampaigns !== undefined && favorites?.favoriteCampaigns !== null) {
      for (const favoritedCampaign of favorites?.favoriteCampaigns) {
        if (Types.isCampaign(favoritedCampaign) && favoritedCampaign.uid) {
          favoritedCampaignSet.add(favoritedCampaign.uid);
        }
      }
    }
  }
  return (
    <NewsProvider>
      <SlideFade in={Boolean(true)} transition={{ enter: { duration: 0.3 } }}>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#E5E5E5"
        >
          <HeadlineArticle />
          {auth.currentUser?.uid ? (
            <Box>
              <JobSuggest
                favorites={favoritedJobSet}
                suggestions={(suggestions?.suggestedJobs as Types.JobListing[]) || []}
              />
              <CampaignSuggest
                favorites={favoritedCampaignSet}
                suggestions={(suggestions?.suggestedCampaigns as Types.Campaign[]) || []}
              />
            </Box>
          ) : (
            <Box mb={10}></Box>
          )}

          <NewsSuggest />
        </Box>
      </SlideFade>
    </NewsProvider>
  );
}

import { user, UserState } from "./user";
import { combineReducers } from "redux";
import { campaign, CampaignState } from "./campaign";
import { favorites, FavoritesState } from "./favorites";
import { suggestions, SuggestionsState } from "./suggestions";

export default combineReducers<{
  user: UserState;
  campaign: CampaignState;
  favorites: FavoritesState;
  suggestions: SuggestionsState;
}>({
  user,
  campaign,
  favorites,
  suggestions,
});

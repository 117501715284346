import { useState } from "react";
import { Link, Box, Stack } from "@chakra-ui/react";
import { ViewOffIcon } from "@chakra-ui/icons";

import EditProfileContainer from "./EditProfileContainer";
import SingleEndorsement from "../SingleEndorsement";
import { sortEndorsements } from "../Endorsements";
import useUser from "../../../hooks/useUser";
import { useAppDispatch } from "../../../hooks";
import { updateUserProfile } from "../../../app-state/actions/user";
import { Endorsement, User } from "types";

export default function EditEndorsements() {
  // TODO: clicking an endorsement toggle it to hidden/visible
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useUser();
  const dispatch = useAppDispatch();

  const newEndorsements = user?.endorsements && sortEndorsements(user?.endorsements);
  const [endorsements, setEndorsements] = useState(newEndorsements);

  function toggleEndorsementVisibility(endorsement: Endorsement) {
    if (endorsements) {
      if (endorsements.filter(e => !e.isHidden).length === 3 && endorsement.isHidden) {
        setErrorMessage("You can only have up to 3 endorsements shown on your profile at a time.");
        return;
      }
      setErrorMessage("");
      const newEndorsements = endorsements.map(e => ({
        ...e,
        isHidden: e === endorsement ? !e.isHidden : e.isHidden
      }));
      setEndorsements(newEndorsements);
    }
  }

  async function editEndorsements() {
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(updateUserProfile({
        endorsements: endorsements?.map(({ endorser, ...endorsement }) => ({
          ...endorsement,
          endorser: (endorser as User).uid as string
        }))
      }));
    } catch (e: any) {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  if (!endorsements?.length) return null;

  return (
    <EditProfileContainer
      name="Edit Endorsements"
      saveBtnSide="center"
      onSave={editEndorsements}
      loading={loading}
      errorMessage={errorMessage}
      top={-10}
    >
      <Link
        style={{ textDecoration: "none" }}
        color={page == 0 ? "black" : "gray"}
        onClick={() => setPage(0)}
      >
        Show({endorsements.filter(e => !e.isHidden).length}){" "}
      </Link>
      <Link
        ml={2}
        style={{ textDecoration: "none" }}
        color={page == 1 ? "black" : "gray"}
        onClick={() => setPage(1)}
      >
        Hide({endorsements.filter(e => e.isHidden).length})
      </Link>
      <Stack spacing={5} mb={8} userSelect="none">
        {endorsements
          .filter((e) => (page == 0 ? !e.isHidden : e.isHidden))
          .map((e) => (
            <Box
              key={(e.endorser as User).uid}
              boxShadow="xl"
              borderRadius={20}
              px={6}
              pt={2}
              pb={6}
              mt={2}
              mb={4}
              position="relative"
              cursor="pointer"
              _hover={{ bg: "politiq.lightgray" }}
              _active={{ bg: "politiq.gray", transform: "scale(0.96)" }}
              transition="0.2s"
              onClick={() => toggleEndorsementVisibility(e)}
            >
              <Box
                borderRadius={20}
                position="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                bg="rgba(0, 0, 0, 0.3)"
                textAlign="center"
                d={e.isHidden ? "block" : "none"}
              >
                <ViewOffIcon
                  position="relative"
                  top="calc(50% - 40px)"
                  boxSize={20}
                />
              </Box>
              <SingleEndorsement endorsement={e} />
            </Box>
          ))}
      </Stack>
    </EditProfileContainer>
  );
}

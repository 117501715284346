import React, { useEffect, useState } from "react";
import Search from "./Search";

import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Grid,
  GridItem,
  Center,
  SlideFade,
} from "@chakra-ui/react";
import useQuery from "../../hooks/useQuery";
import UserHit from "./UserHit";
import JobHit from "./JobHit";
import CampaignHit from "./CampaignHit";
import ExploreHeader from "./ExploreHeader";
import { getFavorites } from "../../app-state/actions/favorites";
import { auth } from "../../firebase";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";
import useFavorites from "../../hooks/useFavorites";
import * as Types from "types";
const Explore = () => {
  const tabs = ["Jobs", "Campaigns", "People"];
  const queryParams = useQuery();
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  useEffect(() => {
    setSearchQuery(queryParams.get("searchQuery") || "");
  }, [queryParams]);

  useEffect(() => {
    if (auth.currentUser?.uid && isCampaign !== undefined) {
      const accountType = isCampaign ? "campaign" : "user";
      dispatch(getFavorites(accountType));
    }
  }, [isCampaign]);

  const obj = useFavorites();
  const favorites = obj.favorites;
  const favsError = obj.error;
  let favoritedJobSet = new Set<string>();
  let favoritedUserSet = new Set<string>();
  let favoritedCampaignSet = new Set<string>();
  if (favorites) {
    if (favorites?.favoriteJobs !== undefined && favorites?.favoriteJobs !== null) {
      for (const job of favorites?.favoriteJobs) {
        if (Types.isJobListing(job) && job?.id) {
          favoritedJobSet.add(job.id);
        }
      }
    }

    if (favorites?.favoriteUsers !== undefined && favorites?.favoriteUsers !== null) {
      for (const favoritedUser of favorites?.favoriteUsers) {
        if (Types.isUser(favoritedUser) && favoritedUser.uid) {
          favoritedUserSet.add(favoritedUser.uid);
        }
      }
    }

    if (favorites?.favoriteCampaigns !== undefined && favorites?.favoriteCampaigns !== null) {
      for (const favoritedCampaign of favorites?.favoriteCampaigns) {
        if (Types.isCampaign(favoritedCampaign) && favoritedCampaign.uid) {
          favoritedCampaignSet.add(favoritedCampaign.uid);
        }
      }
    }
  }

  return (
    <Box bg="politiq.brightgray" minH="100vh">
      <SlideFade in={Boolean(true)} transition={{ enter: { duration: 0.3 } }}>
        <ExploreHeader></ExploreHeader>
        <Tabs variantColor="politiq.darkgray" borderColor="transparent">
          <TabList justifyContent="center" bg="none" mt="-70px">
            <Grid templateColumns="repeat(3, 1fr)" style={{ width: "80%" }}>
              {tabs.map((tab, i) => (
                <GridItem
                  colSpan={1}
                  borderRightWidth={i == tabs.length - 1 ? 0 : 1}
                  borderRightColor="transparent"
                  color="white"
                >
                  <Center>
                    <Tab
                      _selected={{
                        color: "white",
                        borderBottom: "4px white solid",
                        outline: "none",
                      }}
                      _focus={{ outline: 0 }}
                      width="80%"
                      fontSize="20px"
                    >
                      {tab.toUpperCase()}
                    </Tab>
                  </Center>
                </GridItem>
              ))}
            </Grid>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Center>
                <Search
                  key="jobs"
                  searchQuery={searchQuery}
                  searchIndex="search-jobs"
                  hitComponent={JobHit}
                  favorites={favoritedJobSet}
                />
              </Center>
            </TabPanel>
            <TabPanel>
              <Center>
                <Search
                  key="campaigns"
                  searchQuery={searchQuery}
                  searchIndex="search-campaigns"
                  hitComponent={CampaignHit}
                  favorites={favoritedCampaignSet}
                />
              </Center>
            </TabPanel>
            <TabPanel>
              <Center mt={2}>
                <Search
                  key="users"
                  searchQuery={searchQuery}
                  searchIndex="search-users"
                  hitComponent={UserHit}
                  favorites={favoritedUserSet}
                />
              </Center>
            </TabPanel>
            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </SlideFade>
    </Box>
  );
};

export default Explore;

import { Box } from "@chakra-ui/react";
import Job from "./Job";
import useCampaign from "../../hooks/useCampaign";
import AddNewButton from "./EditProfile/AddNewButton";
import * as Types from "types";

export default function Jobs(props) {
  const { campaign, isCurrentCampaign } = useCampaign();
  return (
    <Box width={["90%", "80%", "60%"]}>
      <Box>
        <AddNewButton
          isCurrentCampaign={isCurrentCampaign}
          campaign={campaign as Types.Campaign}
          buttonType="job"
        ></AddNewButton>
      </Box>
      <Box>
        {(campaign?.jobListings as Types.JobListing[])
          // ?.filter((job) => !(auth.currentUser?.uid && auth.currentUser.uid in job.applicants))
          .map((job) => (
            <Job
              key={job.id as string}
              info={job}
              city={campaign!.city}
              state={campaign!.state}
              initialFavorited={props.favoritedSet.has(job?.id as string)}
            />
          ))}
      </Box>
    </Box>
  );
}

import { SimpleGrid, Button, Flex, Text, VStack, Box } from "@chakra-ui/react";
import { useState } from "react";

import useCampaign from "../../hooks/useCampaign";
import { User } from "types";
import { auth } from "../../firebase";
import { joinCampaignTeam } from "../../app-state/actions/user";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";
import { MobileOnly } from "../MobileOnly";
import { DesktopOnly } from "../DesktopOnly";
import ActualCampaignTeamMember from "./ActualCampaignTeamMember";

export default function Team(props) {
  const { campaign } = useCampaign();
  const isCampaign = useIsCampaign();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  let alreadyOnTeam = campaign?.teamMembers?.some(
    (member) => (member as User).uid === auth.currentUser?.uid
  );

  const join = async () => {
    setLoading(true);
    if (campaign !== undefined && campaign !== null) {
      await dispatch(joinCampaignTeam(campaign.uid as string));
      setLoading(false);
    }
  };

  const joinButton = !isCampaign && !alreadyOnTeam && auth.currentUser?.uid && (
    <Button variant="lavender" onClick={join} isLoading={loading}>
      Join Team
    </Button>
  );

  const teamMembers = (
    <>
      {campaign && (campaign.teamMembers === undefined || campaign.teamMembers.length === 0) ? (
        <Text fontSize="xl">No team members here yet...</Text>
      ) : (
        <SimpleGrid columns={alreadyOnTeam ? [1, 2, 2, 3, 4] : [1, 1, 2, 2, 3, 4]} spacing={6}>
          {campaign?.teamMembers?.map((teamMember) => (
            <ActualCampaignTeamMember
              key={teamMember.uid}
              teamMember={teamMember as User}
              initialFavorited={props.favoritedSet.has(teamMember?.uid as string)}
            />
          ))}
        </SimpleGrid>
      )}
    </>
  );
  return (
    <Box px={{ base: 0, md: 6 }}>
      <DesktopOnly>
        <VStack>
          <Flex mb="3.5rem">{teamMembers}</Flex>
          <Box mt={5} ml={6}>
            {joinButton}
          </Box>
        </VStack>
      </DesktopOnly>
      <MobileOnly>
        <VStack spacing={4}>
          {joinButton}
          {teamMembers}
        </VStack>
      </MobileOnly>
    </Box>
  );
}

import { Flex, Box, Spacer } from "@chakra-ui/react";

import {
  Campaign,
  DataAppTool,
  DigitalInternalSkill,
  DigitalOutreachSkill,
  ExecutionSkill,
  GeneralTechTool,
  ManagementAppTool,
  ManagementSkill,
  OutreachAppTool,
} from "types";
import EditJobListing from "./EditJobListing";
import EditPlatformPoint from "./EditPlatformPoint";

// styling copied from "Job" card.
export default function AddNewButton({
  isCurrentCampaign,
  campaign,
  buttonType,
}: {
  isCurrentCampaign: boolean;
  campaign?: Campaign;
  buttonType: string;
}) {
  if (!isCurrentCampaign) {
    return <></>;
  }
  return (
    <Box p="1rem 2rem" bg="white" borderRadius="lg">
      <Flex>
        <Box fontWeight="bold" fontSize="1.3em">
          {buttonType === "job" ? "Add a Job Listing" : "Add a Platform Point"}
        </Box>
        <Spacer />
        {buttonType === "job" ? (
          <EditJobListing
            maxSkillsOrTools={5}
            city={campaign?.city || ""}
            state={campaign?.state || ""}
            categories={{
              "Digital - Outreach": Object.values(DigitalOutreachSkill),
              "Digital - Internal": Object.values(DigitalInternalSkill),
              Management: Object.values(ManagementSkill),
              Execution: Object.values(ExecutionSkill),
              "Data Apps": Object.values(DataAppTool),
              "Outreach Apps": Object.values(OutreachAppTool),
              "Management Apps": Object.values(ManagementAppTool),
              "General Tech": Object.values(GeneralTechTool),
            }}
          />
        ) : (
          <EditPlatformPoint />
        )}
      </Flex>
    </Box>
  );
}

import { Stack, Button, Box, Grid, Text, Image, GridItem, useRadioGroup } from "@chakra-ui/react";
import { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";

import EditProfileContainer from "./EditCampaignProfileContainer";
import IconRadioButton from "./IconRadioButton";
import * as Types from "types";
import useValidatedForm from "../../../hooks/useValidatedForm";
import { useAppDispatch } from "../../../hooks";
import FormInput from "../../FormInput";
import FormTextArea from "../../FormTextArea";
import { updateCampaignProfile, deleteCampaignProfile } from "../../../app-state/actions/campaign";

type EditPlatformPointFieldValues = Omit<Types.PlatformPoint, "id">;

export default function EditPlatformPoint({
  platformPoint,
}: {
  platformPoint?: Types.PlatformPoint;
}) {
  const { isValid, handleSubmit, formProps, reset } =
    useValidatedForm<EditPlatformPointFieldValues>();
  const [errorMessage, setErrorMessage] = useState("");
  const [icon, setIcon] = useState(platformPoint?.icon || "");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const mappings = Object.values(Types.Icon);
  const { getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "Money",
    onChange: setIcon,
  });

  async function editPlatformPoint({ ...data }: EditPlatformPointFieldValues) {
    const iconEnum = icon as Types.Icon;
    const id = platformPoint?.id;
    const newPlatformPoint: Types.PlatformPoint = {
      ...data,
      icon: iconEnum,
      id,
    };
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(updateCampaignProfile({ platformPoints: [newPlatformPoint] }));
      reset();
    } catch (e: any) {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  async function deletePlatformPoint() {
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(deleteCampaignProfile({ platformPoints: [platformPoint?.id as string] }));
    } catch {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  return (
    <EditProfileContainer
      name={(platformPoint ? "Edit" : "Add") + " Platform Point"}
      {...(!platformPoint && {
        openButton: (onOpen) => (
          <Button
            onClick={onOpen}
            w={1}
            bg="white"
            _hover={{ bg: "rgba(0, 0, 0, 0.05)" }}
            borderRadius="full"
            position="absolute"
            top={-1}
            left="100%"
          >
            <AddIcon boxSize={5} />
          </Button>
        ),
      })}
      {...(platformPoint && {
        deleteDialogName: "Platform Point",
        onDelete: deletePlatformPoint,
        top: -2,
      })}
      saveBtnSide="right"
      onSave={handleSubmit(editPlatformPoint)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Stack spacing={4}>
        <FormInput
          name="title"
          label="Platform Title"
          defaultValue={platformPoint?.title}
          rules={{
            required: true,
            maxLength: 50,
          }}
          {...formProps}
        />
        <FormTextArea
          name="description"
          label="Platform Description"
          defaultValue={platformPoint?.description}
          rules={{
            required: true,
            maxLength: 10000,
          }}
          {...formProps}
        />

        <Box>
          <Text>Choose Icon:</Text>
          <Box overflowY="auto" maxHeight="300px">
            <Grid row={4} templateColumns="repeat(10, 0.1fr)" ml={[-2, -4, -4]}>
              {Object.values(mappings).map((value) => {
                const isChecked = platformPoint?.icon === value;
                const radio = getRadioProps({ value });
                return (
                  <GridItem key={value} colSpan={1} pl={[2, 4, 4]} align="center">
                    {isChecked ? (
                      <IconRadioButton key={value} {...radio} checked={true} isChecked>
                        <Image
                          src={value}
                          alt={value}
                          objectFit="fill"
                          fallbackSrc="https://via.placeholder.com/20"
                        />
                      </IconRadioButton>
                    ) : (
                      <IconRadioButton key={value} {...radio} checked={false}>
                        <Image
                          src={value}
                          alt={value}
                          objectFit="fill"
                          fallbackSrc="https://via.placeholder.com/20"
                        />
                      </IconRadioButton>
                    )}
                  </GridItem>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Stack>
    </EditProfileContainer>
  );
}

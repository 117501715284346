import { Heading, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import {
  DomesticPolicyPriority,
  EconomicPolicyPriority,
  EnergyPolicyPriority,
  EnvironmentalPolicyPriority,
  HealthcarePolicyPriority,
  InfrastructurePolicyPriority,
  InternationalPolicyPriority,
  ReligiousAndCommunityPolicyPriority,
  SocialPolicyPriority,
  StatePolicyPriority,
  TechnologyPolicyPriority
} from "types";
import { deleteUserProfile } from "../../app-state/actions/user";
import { useAppDispatch } from "../../hooks";

import useUser from "../../hooks/useUser";
import TagGroup from "../TagGroup";
import AddItem from "./EditProfile/AddItem";
import ProfileCard from "./ProfileCard";

export default function Interests() {
  const { user, loading, isCurrentUser } = useUser();
  const dispatch = useAppDispatch();

  async function removePolicyPriority(policyPriority) {
    await dispatch(deleteUserProfile({ policyPriorities: [policyPriority] }));

  }

  return (
    <ProfileCard>
      <Skeleton isLoaded={!loading} my={loading ? 2 : 0} h={loading ? "40px" : 0} borderRadius={10} />
      <SkeletonText noOfLines={3} spacing={6} isLoaded={!loading} py={loading ? 4 : 0}>
        <Heading fontSize="2xl" mb={3}>Political Interests</Heading>
        <TagGroup items={user?.policyPriorities} removeItem={removePolicyPriority} />
        {!isCurrentUser && !user?.policyPriorities?.length &&
          <Text fontStyle="italic" color="politiq.darkgray" mb={2}>
            There's nothing here...yet.
          </Text>
        }
        <AddItem
          name="Political Interests"
          maxItems={7}
          profileField="policyPriorities"
          categories={{
            "Economic Issues": Object.values(EconomicPolicyPriority),
            "Social Issues": Object.values(SocialPolicyPriority),
            "Healthcare Issues": Object.values(HealthcarePolicyPriority),
            "Domestic Issues": Object.values(DomesticPolicyPriority),
            "Technology Issues": Object.values(TechnologyPolicyPriority),
            "Infrastructure Issues": Object.values(InfrastructurePolicyPriority),
            "Energy Issues": Object.values(EnergyPolicyPriority),
            "Environmental Issues": Object.values(EnvironmentalPolicyPriority),
            "Religious/Community Issues": Object.values(ReligiousAndCommunityPolicyPriority),
            "International Issues": Object.values(InternationalPolicyPriority),
            "State Issues": Object.values(StatePolicyPriority)
          }}
          preselected={user?.policyPriorities as string[]}
        />
      </SkeletonText>
    </ProfileCard>
  );
}
import { Grid, Stack, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../hooks";
import { getUser } from "../../app-state/actions/user";
import MoreDetails from "./MoreDetails";
import Endorsements from "./Endorsements";
import Experiences from "./Experiences";
import Header from "./Header";
import Interests from "./Interests";
import Skills from "./Skills";
import NotFound from "../NotFound";
import { DesktopOnly } from "../DesktopOnly";
import { MobileOnly } from "../MobileOnly";
import useUser from "../../hooks/useUser";
import { getFavorites } from "../../app-state/actions/favorites";
import { auth } from "../../firebase";
import useFavorites from "../../hooks/useFavorites";
import useIsCampaign from "../../hooks/useIsCampaign";

export default function IndividualProfile() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  useEffect(() => {
    dispatch(getUser(id, true));
  }, [id]);

  useEffect(() => {
    if (isCampaign !== undefined && auth.currentUser?.uid) {
      const accountType = isCampaign ? "campaign" : "user";
      dispatch(getFavorites(accountType));
    }
  }, [isCampaign]);

  const { user, error } = useUser();
  const obj = useFavorites();
  const favorites = obj.favorites;
  // const favsError = obj.error;

  if (user) {
    window.history.replaceState(null, "", "/profile/" + user.username);
  }

  if (error) {
    return <NotFound />;
  }
  let isUserFavorited = false;
  // compare by id rather than using an 'in' statement because its cheaper to compare ids than full users!
  if (favorites && favorites?.favoriteUsers !== undefined && favorites?.favoriteUsers !== null) {
    for (const favoritedUser of favorites.favoriteUsers) {
      if (favoritedUser.uid === user?.uid) {
        isUserFavorited = true;
        break;
      }
    }
  }

  return (
    <Center pt={6} pb={12}>
      <Grid
        templateColumns={{ base: "1fr", lg: "2fr 3fr 2fr" }}
        mx="max(4%, min(20%, 50% - 500px))"
        gap={4}
        w="100%"
      >
        <DesktopOnly>
          <Stack spacing={4}>
            <Header isUserFavorited={isUserFavorited} />
            <Interests />
            <MoreDetails />
          </Stack>
          <Stack spacing={4}>
            <Skills />
            <Experiences />
          </Stack>
          <Stack spacing={4}>
            <Endorsements />
          </Stack>
        </DesktopOnly>
        <MobileOnly>
          <Stack spacing={4}>
            <Header isUserFavorited={isUserFavorited} />
            <Experiences />
            <Endorsements />
            <Skills />
            <Interests />
            <MoreDetails />
          </Stack>
        </MobileOnly>
      </Grid>
    </Center>
  );
}

import { Heading, SkeletonText, Skeleton, Text } from "@chakra-ui/react";

import ProfileCard from "./ProfileCard";
import TagGroup from "../TagGroup";
import AddItem from "./EditProfile/AddItem";
import {
  DataAppTool,
  DigitalInternalSkill,
  DigitalOutreachSkill,
  ExecutionSkill,
  GeneralTechTool,
  ManagementAppTool,
  ManagementSkill,
  OutreachAppTool
} from "types";
import useUser from "../../hooks/useUser";
import { useAppDispatch } from "../../hooks";
import { deleteUserProfile } from "../../app-state/actions/user";

export default function Skills() {
  const { user, loading, isCurrentUser } = useUser();
  const dispatch = useAppDispatch();

  async function removeSkill(skill) {
    await dispatch(deleteUserProfile({ skills: [skill] }));
  }

  async function removeTool(tool) {
    await dispatch(deleteUserProfile({ tools: [tool] }));
  }

  return (
    <ProfileCard>
      <Skeleton isLoaded={!loading} mt={loading ? 2 : 0} h={loading ? "40px" : 0} borderRadius={10} />
      <SkeletonText noOfLines={3} spacing={6} isLoaded={!loading} py={loading ? 6 : 0}>
        <Heading fontSize="2xl" mb={2}>Skills</Heading>
        {!isCurrentUser && !user?.skills?.length &&
          <Text fontStyle="italic" color="politiq.darkgray" mb={2}>
            There's nothing here...yet.
          </Text>
        }
        <TagGroup items={user?.skills} removeItem={removeSkill} />
        <AddItem
          name="Skills"
          maxItems={5}
          profileField="skills"
          categories={{
            "Digital - Outreach": Object.values(DigitalOutreachSkill),
            "Digital - Internal": Object.values(DigitalInternalSkill),
            "Management": Object.values(ManagementSkill),
            "Execution": Object.values(ExecutionSkill)
          }}
          preselected={user?.skills as string[]}
        />
      </SkeletonText>
      <Skeleton isLoaded={!loading} mt={loading ? 2 : 0} h={loading ? "40px" : 0} borderRadius={10} />
      <SkeletonText noOfLines={3} spacing={6} isLoaded={!loading} py={loading ? 4 : 0}>
        <Heading fontSize="2xl" my={2}>Tools</Heading>
        {!isCurrentUser && !user?.tools?.length &&
          <Text fontStyle="italic" color="politiq.darkgray" mb={2}>
            There's nothing here...yet.
          </Text>
        }
        <TagGroup items={user?.tools} removeItem={removeTool} />
        <AddItem
          name="Tools"
          maxItems={5}
          profileField="tools"
          categories={{
            "Data Apps": Object.values(DataAppTool),
            "Outreach Apps": Object.values(OutreachAppTool),
            "Management Apps": Object.values(ManagementAppTool),
            "General Tech": Object.values(GeneralTechTool)
          }}
          preselected={user?.tools as string[]}
        />
      </SkeletonText>
    </ProfileCard>
  );
}
import {
  Box,
  Heading,
  Button,
  SimpleGrid,
  chakra,
  Checkbox,
  Tooltip,
  Link,
  Input,
  Text,
} from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { FormEvent, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Router from "react-router-dom";

import { signInWithEmailAndPassword } from "firebase/auth";

import { SignUpCredentials } from "types";
import { AuthFormProps } from "./Auth";
import ProviderAuth from "./ProviderAuth";
import AuthFormInput from "./AuthFormInput";
import { auth } from "../../firebase";
import useValidatedForm from "../../hooks/useValidatedForm";
import { loginUserCometChat } from "../../cometchat";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const ChakraLink = chakra(Router.Link);

export default function SignUpForm({ setName, setCampaignFlow, setPosition }: AuthFormProps) {
  const doVerify = true; // Feature Flag to enable/disable the verify function from running on backend.
  // form rendered during the second stage of signup which collects user credentials described by SignUpCredentials
  // uses form validation with FormInput and react-hook-form

  const { isValid, handleSubmit, formProps } = useValidatedForm<SignUpCredentials>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [policiesAccepted, setPoliciesAccepted] = useState(false);
  const [campaignFlowLocal, setCampaignFlowLocal] = useState(false);
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [positionLevel, setPositionLevel] = useState("");

  async function createUser(e: FormEvent<HTMLFormElement>) {
    // issues a POST request to /register with the chosen password
    // this is to tell the server to create a user with auth.createUserWithEmailAndPassword
    e.preventDefault();
    handleSubmit(async (data: SignUpCredentials) => {
      try {
        let postRouteUrl = "/auth/register";
        if (campaignFlowLocal) {
          if (doVerify) {
            const verification = data.verification;
            await axios.post("/auth/verify", { verification });
          }
          postRouteUrl = "/auth/registerCampaign";
        }
        setLoading(true);
        setErrorMessage("");
        const res = (await axios.post(postRouteUrl, data)) as AxiosResponse<any>;
        const authToken = res.data.chatToken;
        await signInWithEmailAndPassword(auth, data.email, data.password as string);
        setName(data.firstName);
        const uid = auth.currentUser?.uid;
        if (uid) {
          // only if user actually logs in
          await loginUserCometChat(authToken);
        }
      } catch (e: any) {
        setLoading(false);
        if (e.response?.status === 409 || e.response?.status === 400) {
          setErrorMessage("The email you've entered is already in use.");
        } else if (e.response?.status === 405) {
          setErrorMessage(
            "Incorrect verification code. Please email politiqinc@gmail.com to set up an account."
          );
        } else {
          setErrorMessage("An unknown error occurred. Please try again.");
        }
      }
    })(e);
  }

  async function checkCode() {
    const res = (await axios.get(`/auth/sendPosition?code=${verifyCode}`)) as AxiosResponse<any>;
    const status = res.status;
    if (status === 200) {
      setPositionLevel(res.data.position);
    }
  }

  return auth.currentUser ? (
    <Redirect to="/rotunda" />
  ) : (
    <Box>
      <Heading color="politiq.lavender" fontSize="2xl" pb={6}>
        Sign Up
      </Heading>
      <form onSubmit={createUser}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="5%">
          <AuthFormInput
            {...formProps}
            name="firstName"
            label="First Name"
            rules={{
              required: true,
              pattern: /^[a-z ,.'-]+$/i,
            }}
          />
          <AuthFormInput
            {...formProps}
            name="lastName"
            label="Last Name"
            rules={{
              required: true,
              pattern: /^[a-z ,.'-]+$/i,
            }}
          />
        </SimpleGrid>
        <Box py="3">
          <AuthFormInput
            {...formProps}
            name="email"
            label="Email"
            rules={{
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            }}
          />
        </Box>
        <Box pb={3}>
          <AuthFormInput
            {...formProps}
            name="password"
            label="Password (at least 8 characters)"
            type="password"
            rules={{
              required: true,
              pattern: /^.{8,}$/i,
            }}
          />
        </Box>
        <Box>
          <Tooltip
            label="A campaign account has a price associated with it. With it, your organization can post job listings, interact with candidates, and advertise your campaign on our platform."
            placement="auto-start"
            aria-label="A tooltip"
          >
            <Box>
              <Checkbox
                value="campaignAccount"
                colorScheme="purple"
                onChange={(e) => {
                  setCampaignFlow(!campaignFlowLocal);
                  setCampaignFlowLocal(!campaignFlowLocal);
                }}
              >
                Sign up as a political campaign
              </Checkbox>
            </Box>
          </Tooltip>
        </Box>
        {campaignFlowLocal && (
          <Box my="10px">
            <AuthFormInput
              {...formProps}
              name="verification"
              label="Verification Code"
              type="verification"
              mb={3}
              onChange={(e) => setVerifyCode(e.target.value)}
              checkCode={checkCode}
            />
            <Text mb="10px">Position Level</Text>
            <Input
              isDisabled={true}
              value={positionLevel}
              variant="filled"
              bg="politiq.lightgray"
              pl={3}
              persistentBorder
              borderRadius={0}
            />
          </Box>
        )}
        <Checkbox
          isRequired={true}
          value="accept"
          colorScheme="purple"
          onChange={(e) => {
            setPoliciesAccepted(!policiesAccepted);
          }}
        >
          I accept the{" "}
          <Link
            color="teal.500"
            href="https://www.privacypolicygenerator.info/live.php?token=CEA8xJ9OhaHs5KE0JbjfExOfwiP7SCu0"
            isExternal
          >
            Politiq User Privacy Agreement <ExternalLinkIcon mx="2px" />
          </Link>
          &{" "}
          <Link
            color="teal.500"
            href="https://www.termsandconditionsgenerator.com/live.php?token=TWBSwRX8nckuRs7iRaONvNtlybqd6wgl"
            isExternal={true}
          >
            Terms and Conditions Agreement <ExternalLinkIcon mx="2px" />
          </Link>
        </Checkbox>
        <Box color="red.500" mb={4}>
          {errorMessage}
        </Box>
        <Box align="center">
          <Button
            type="submit"
            variant="lavender"
            width="100%"
            disabled={!isValid || loading || !policiesAccepted}
            isLoading={loading}
            onClick={() => {
              checkCode();
              setPosition(positionLevel);
            }}
          >
            Agree and Join
          </Button>
        </Box>
      </form>
      <ProviderAuth
        prefix="Join"
        setName={setName}
        setErrorMessage={setErrorMessage}
        redirect="/rotunda"
        enabled={policiesAccepted}
        campaign={campaignFlowLocal}
        verificationCode={verifyCode}
        setPosition={setPosition}
        login={false}
      />
      <Box align="center" mt={6}>
        Already on Politiq?{" "}
        <ChakraLink color="politiq.lavender" to="/login">
          Sign in.
        </ChakraLink>
      </Box>
    </Box>
  );
}

import { Suggestions } from "types";
import { SuggestionsActionType } from "../actions/suggestions";

export type SuggestionsState = {
  loading: boolean;
  data: Suggestions | null;
  error: string;
};

export const suggestions = (
  state: SuggestionsState = {
    loading: false,
    data: null,
    error: "",
  },
  { type, payload }
): SuggestionsState => {
  switch (type) {
    case SuggestionsActionType.FETCH_SUGGESTIONS:
      return {
        loading: true,
        data: null,
        error: "",
      };
    case SuggestionsActionType.FETCHED_SUGGESTIONS:
      return {
        loading: false,
        data: payload,
        error: "",
      };
    case SuggestionsActionType.FAILED_SUGGESTIONS:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

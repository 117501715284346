import { Box, Flex, Tag, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { addFavoriteCampaign, deleteFavoriteCampaign } from "../../app-state/actions/favorites";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";
import FavButton from "../Favorite/FavButton";

export default function SuggestedCampaignCard(props) {

  // put spaces between code sections so I can see what's going on! :)

  const tagArray = props.tags;
  const overrideFavButton = props.overrideFavButton;
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteCampaign(accountType, props.campaign));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteCampaign(accountType, props.campaign));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  return (
    <Box w="400px" mt="0px">
      <Box ml="320px" mb="-60px" position="relative" zIndex={2}>
        <FavButton
          type="campaign"
          initialFavorited={props.favorite}
          customOnClick={toggleFavorite}
          overrideButton={overrideFavButton}
        ></FavButton>
      </Box>
      <Link to={"/campaign/" + props.campaign.uid}>
        <Box
          bg="white"
          boxShadow="base"
          borderColor="politiq.gray"
          borderRadius={10}
          w="100%"
          fontSize="16px"
        >
          <Box>
            {/* Star for favorites */}

            <Box
              position="relative"
              bg={
                !props.campaign?.bannerPic
                  ? "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 71.35%)"
                  : ""
              }
              overflow="hidden"
              h="200px"
              borderTopRadius={10}
              zIndex={1}
            >
              {props.campaign?.bannerPic && (
                <Image
                  position="absolute"
                  top={0}
                  src={props.campaign?.bannerPic}
                  zIndex={1}
                  filter="brightness(50%)"
                />
              )}
            </Box>

            <Flex mt={-12} position="absolute" ml={4} zIndex={2}>
              <Flex wrap="wrap" maxW="380px" maxH="30px" overflow="hidden">
                {tagArray.map((name, index) => (
                  <Tag
                    key={index}
                    fontSize="16px"
                    color="black"
                    borderRadius="25px"
                    backgroundColor="politiq.gray"
                    px={5}
                    py={1}
                    mb={1}
                    mr={2}
                    maxW="45%"
                  >
                    <Text isTruncated>{name}</Text>
                  </Tag>
                ))}
              </Flex>
            </Flex>
          </Box>

          <Box px={9} pb={6} pt={4} fontSize="16px">
            <Flex mb={-1} mt={0} alignItems="center">
              <Box fontWeight="bold" fontSize="25px" mr={3} isTruncated align="left" w="70%">
                {props.campaign.campaignName}
              </Box>

              <Box w="70%" align="right">
                <Tag
                  fontSize="16px"
                  color="black"
                  borderRadius="25px"
                  backgroundColor="politiq.lightgray"
                  px="10%"
                  py={1}
                  textAlign="center"
                  whiteSpace="nowrap"
                >
                  {props.campaign.officeLevel}
                </Tag>
              </Box>
            </Flex>

            <Flex mt={4} justifyContent="space-between">
              <Box color="politiq.darkgray" w="50%">
                {/* US Congress */}
                <Text>{props.campaign.positionLevel}</Text>
              </Box>

              <Box color="politiq.darkgray">
                {/* Houston, TX */}
                <Text noOfLines={1}>{props.campaign.city + ", " + props.campaign.state}</Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}

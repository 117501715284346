import { Button, Text, Center, Heading, Box, SkeletonText } from "@chakra-ui/react";

import ProfileCard from "./ProfileCard";
import EditProfile from "./EditProfile/EditProfile";
import SkeletonAvatar from "../SkeletonAvatar";
import useUser from "../../hooks/useUser";
import { useHistory } from "react-router-dom";
import FavButton from "../Favorite/FavButton";
import { User } from "types";
import { deleteFavoriteUser, addFavoriteUser } from "../../app-state/actions/favorites";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";

export default function Header(props) {
  const { user, loading, isCurrentUser } = useUser();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteUser(accountType, user as User));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteUser(accountType, user as User));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  return (
    <ProfileCard pt={10}>
      {!loading &&
        (isCurrentUser ? (
          <EditProfile />
        ) : (
          <Box position="relative">
            <Box
              float="right"
              position="absolute"
              left="90%"
              top={-4}
              boxSize={6}
              color="politiq.lavender"
              cursor="pointer"
            >
              <FavButton
                type="user"
                customOnClick={toggleFavorite}
                id={user?.uid}
                initialFavorited={props.isUserFavorited}
              />
            </Box>
          </Box>
        ))}
      <Center>
        <SkeletonAvatar
          w="150px"
          h="150px"
          {...(user?.profilePic && { src: user.profilePic + "?" + new Date().getTime() })}
          isLoaded={!loading}
        />
      </Center>
      <SkeletonText noOfLines={8} spacing={6} isLoaded={!loading} py={loading ? 4 : 0}>
        <Heading fontSize="3xl" mt={3} mb={1} textAlign="center">
          {user?.firstName} {user?.lastName}
        </Heading>
        <Text fontSize="xl" textAlign="center" mt={0}>
          {user?.headline}
        </Text>
        <Text fontSize="lg" textAlign="center" mt={0} color="grey">
          {user?.city + ", " + user?.state}
        </Text>
        {!isCurrentUser && (
          <Center>
            <Button
              variant="lavender"
              w="100%"
              my="10px"
              onClick={() => history.push("/chat/" + user?.uid)}
            >
              Message
            </Button>
          </Center>
        )}
        <Text mt="15px">{user?.biography}</Text>
      </SkeletonText>
    </ProfileCard>
  );
}

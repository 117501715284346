import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Center,
  Text,
  Container,
  Box
} from "@chakra-ui/react"

interface DeleteDialogProps {
  name: string;
  onDelete?: () => void;
}

export default function DeleteDialog({ name, onDelete }: DeleteDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function closeAndDelete() {
    if (onDelete) {
      onClose();
      onDelete();
    }
  }

  return (
    <Box>
      <Button
        onClick={onOpen}
        variant="outline"
        borderColor="politiq.magenta"
        color="politiq.magenta"
        _hover={{ bg: "politiq.magenta", color: "white" }}
        ml={6}
        _active={{}}
        width="70px"
      >
        Delete
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent p={0} borderRadius="10px">
          <ModalHeader
            bg="politiq.lavender"
            color="white"
            fontWeight="normal"
            borderRadius="10px 10px 0px 0px"
          >
            Delete {name}
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody px={5} pb={3} pt={8}>
            <Center width="100%">
              <Text fontSize="md">
                You are deleting your {name.toLowerCase()}. Are you sure?
              </Text>
            </Center>

            <Container
              borderLeft="5px #d9105d solid"
              bg="#f7cddd"
              minW="320px"
              w="320px"
              mt={3}
            >
              <Center height="100%" py={2}>
                <Text fontSize="sm">
                  This {name.toLowerCase()} will be deleted permanently.
                </Text>
              </Center>
            </Container>
          </ModalBody>

          <ModalFooter mb={4}>
            <Center w="100%">
              <Button
                onClick={closeAndDelete}
                bg="politiq.magenta"
                color="white"
                size="sm"
                width="70px"
                _hover={{ bg: "#e86d9c" }}
                _active={{}}
                mr={12}
              >
                Delete
              </Button>
              <Button
                onClick={onClose}
                variant="outline"
                size="sm"
                width="70px"
                mr={3}
              >
                Cancel
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

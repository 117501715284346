import HitProps from "./HitProps";
import { Tag, WrapItem } from "@chakra-ui/react";
import { Box, Flex, useDisclosure, LinkBox } from "@chakra-ui/react";
import FavButton from "../Favorite/FavButton";
import { deleteFavoriteJob, addFavoriteJob } from "../../app-state/actions/favorites";
import useIsCampaign from "../../hooks/useIsCampaign";
import { useAppDispatch } from "../../hooks";
import { useHistory } from "react-router";
import { auth } from "../../firebase";

export default function JobHit({ hit }: HitProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const tagArray = hit.skills;
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteJob(accountType, hit));
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteJob(accountType, hit));
      } catch (e: any) {}
    }
  };
  console.log(auth.currentUser?.uid, hit.campaign);
  return auth.currentUser?.uid === hit.campaign ? (
    <></>
  ) : (
    <Box mt="5px">
      <Box ml="320px" mb="-67px" position="relative" zIndex={2}>
        <FavButton
          type="job"
          customOnClick={toggleFavorite}
          id={hit.id}
          initialFavorited={true}
        ></FavButton>
      </Box>

      <LinkBox
        onClick={() => {
          history.push(`/campaign/${hit.campaign}`);
        }}
        cursor="pointer"
        position="relative"
        zIndex={1}
      >
        <Box
          bg="white"
          boxShadow="base"
          borderColor="politiq.gray"
          borderRadius={10}
          px={9}
          py={6}
          w="400px"
          fontSize="16px"
          overflow="hidden"
          mb="45px"
        >
          <Flex mb={-1}>
            <Box fontWeight="bold" fontSize="30px" w="98%" mr={4}>
              {hit.title}
            </Box>
          </Flex>

          <Flex>
            {hit.paid ? (
              <Box color="politiq.darkgray" mr={1}>
                $
              </Box>
            ) : null}

            <Box color="politiq.darkgray" w="97%">
              {hit.commitment}
            </Box>
          </Flex>

          <Box color="politiq.darkgray" mb={20}>
            {hit.city + ", " + hit.state}
          </Box>

          <Flex mb={3} w="100%">
            <Flex spacing="10px" wrap="wrap">
              {tagArray.map((name, index) => (
                <WrapItem key={index}>
                  <Tag
                    fontSize="16px"
                    color="black"
                    borderRadius="25px"
                    backgroundColor="politiq.gray"
                    px={5}
                    py={1}
                    mr={2}
                    mb={2}
                  >
                    {name}
                  </Tag>
                </WrapItem>
              ))}
            </Flex>
          </Flex>

          <Box fontWeight="bold" color="politiq.darkgray">
          </Box>
        </Box>
      </LinkBox>
    </Box>
  );
}

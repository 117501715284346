import { CometChat } from "@cometchat-pro/chat";

export const loginUserCometChat = async (authToken: string) => {
  const user = await CometChat.getLoggedinUser();
  if (!user) {
    CometChat.login(authToken);
  }
};

export const logoutUserCometChat = async () => {
  const user = await CometChat.getLoggedinUser();
  if (user) {
    await CometChat.logout();
  }
};

export const sendMessageCometChat = async (receiverID: string, message: string) => {
  const user = await CometChat.getLoggedinUser();
  if (!user) {
    return; // fail to send message because user is not logged in to cometchat.
  }

  let receiverType = CometChat.RECEIVER_TYPE.USER;
  let textMessage = new CometChat.TextMessage(receiverID, message, receiverType);

  await CometChat.sendMessage(textMessage);
};

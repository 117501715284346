import { useAppSelector } from ".";
import { auth } from "../firebase";

export default function useUser() {
  const { data, ...userState } = useAppSelector((state) => state.user);
  return {
    user: data,
    isCurrentUser: data?.uid === auth.currentUser?.uid,
    ...userState,
  };
}

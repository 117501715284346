import axios from "axios";

import { Suggestions } from "types";
import { Thunkction } from "..";
let initialFetch = false;
export enum SuggestionsActionType {
  FETCH_SUGGESTIONS = "FETCH_SUGGESTIONS",
  FETCHED_SUGGESTIONS = "FETCHED_SUGGESTIONS",
  FAILED_SUGGESTIONS = "FAILED_SUGGESTIONS",
}
export type SuggestionType = "job" | "campaign";

export type SuggestionsAction =
  | {
      type: SuggestionsActionType.FETCH_SUGGESTIONS;
    }
  | {
      type: SuggestionsActionType.FETCHED_SUGGESTIONS;
      payload: Suggestions;
    }
  | {
      type: SuggestionsActionType.FAILED_SUGGESTIONS;
      payload: string;
    };

const fetchSuggestions = (): SuggestionsAction => {
  return {
    type: SuggestionsActionType.FETCH_SUGGESTIONS,
  };
};

const fetchedSuggestions = (payload: Suggestions): SuggestionsAction => {
  return {
    type: SuggestionsActionType.FETCHED_SUGGESTIONS,
    payload: payload,
  };
};

const failedFetchSuggestions = (payload): SuggestionsAction => {
  return {
    type: SuggestionsActionType.FAILED_SUGGESTIONS,
    payload: payload,
  };
};

export type AccountType = "user" | "campaign";

export const getSuggestions =
  (accountType: AccountType, setLoading: boolean = false, override: boolean = false): Thunkction =>
  async (dispatch) => {
    if (initialFetch && !override) {
      return;
    }

    if (setLoading) dispatch(fetchSuggestions());
    try {
      const campaignResponse = await axios.get("/campaign/suggest?accountType=" + accountType);
      const jobResponse = await axios.get("/job/suggest?accountType=" + accountType);
      const campaignResults = (campaignResponse.data as any).results as any[];
      // ensure that uids and ids exist for the data pulled from Algolia (by default, its called objectID instead of uid/id).
      for (const hit of campaignResults) {
        hit.uid = hit.objectID;
      }
      const jobResults = (jobResponse.data as any).results as any[];
      for (const hit of jobResults) {
        hit.id = hit.objectID;
      }
      const newSuggestions: Suggestions = {
        suggestedJobs: jobResults,
        suggestedCampaigns: campaignResults,
      };
      await dispatch(fetchedSuggestions(newSuggestions));
      initialFetch = true;
    } catch (err: any) {
      dispatch(failedFetchSuggestions(err.message));
    }
  };

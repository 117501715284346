import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Rotunda from "./components/Rotunda/Rotunda";
import Explore from "./components/Explore/Explore";
import Auth from "./components/Auth/Auth";
import LogInForm from "./components/Auth/LogInForm";
import SignUpForm from "./components/Auth/SignUpForm";
import AccountType from "./components/Auth/AccountType";
import Success from "./components/Auth/Success";
import ProtectedRoute from "./components/ProtectedRoute";
import IndividualProfile from "./components/IndividualProfile/IndividualProfile";
import CampaignProfile from "./components/CampaignProfile/CampaignProfile";
import NotFound from "./components/NotFound";
import NavbarPage from "./components/NavbarPage";
import Favorites from "./components/Favorite/Favorites";
import { onAuthStateChanged } from "firebase/auth";
import ChatWindow from "./components/Chat/ChatWindow";
import { CometChat } from "@cometchat-pro/chat";
import { auth } from "./firebase";
import ForgotPassword from "./components/Auth/ForgotPassword";

import "./index.css";
import "@fontsource/yantramanav/400.css";
import "@fontsource/yantramanav/700.css";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  cm: "1300px",
});

const theme = extendTheme({
  colors: {
    politiq: {
      brightgray: "#f0f0f0",
      lightgray: "#e5e5e5",
      gray: "#c4c4c4",
      darkgray: "#969191",
      lavender: "#5918f2",
      lightpurple: "#9167f6",
      darkpurple: "#3c139e",
      magenta: "#d9105d",
    },
  },
  fonts: {
    // TODO: change these to Avenir or another desired font
    heading: "Yantramanav, sans-serif",
    body: "Yantramanav, sans-serif",
  },
  breakpoints,
  components: {
    Box: {
      baseStyle: {
        overflowWrap: "anywhere",
      },
    },
    Text: {
      baseStyle: {
        overflowWrap: "anywhere",
      },
    },
    Button: {
      variants: {
        lavender: {
          bg: "politiq.lavender",
          color: "white",
          _hover: {
            bg: "politiq.lightpurple",
            _disabled: {
              bg: "politiq.lightpurple",
            },
          },
        },
        outline: {
          borderColor: "politiq.lavender",
          color: "politiq.lavender",
          _hover: {
            bg: "purple.50",
          },
        },
      },
    },
  },
});

export default function App() {
  const [waitingForAuth, setWaitingForAuth] = useState(true);

  useEffect(() => {
    // pages are no longer rendered at all until firebase initializes auth (e.g. firebase.auth().onAuthStateChanged is called)
    // this adds a split second extra load time but means that we can reliably check if a user is logged in with auth.currentUser
    // in the future, we could maybe change this to render something like the Politiq logo instead of nothing
    return onAuthStateChanged(auth, () => waitingForAuth && setWaitingForAuth(false));
  }, [waitingForAuth]);

  useEffect(() => {
    const appID = "2015029eff15a9a8";
    const region = "us";
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();
    CometChat.init(appID, appSetting);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        {waitingForAuth ? null : (
          <Switch>
            <Route path="/login">
              <Auth page={(props) => <LogInForm {...props} />} />
            </Route>
            <Route path="/signup/:type?">
              <Auth page={(props) => <SignUpForm {...props} />} />
            </Route>
            <Route path="/intro">
              <Auth page={(props) => <AccountType />} />
            </Route>
            <Route path="/forgotpassword">
              <Auth page={(props) => <ForgotPassword />} />
            </Route>
            <Route path="/success/:success_id/:verification">
              <Auth page={(props) => <Success />} />
            </Route>
            <Route path="*">
              <NavbarPage>
                <Switch>
                  <Route exact path="/">
                    <Rotunda />
                  </Route>
                  <Route path="/rotunda">
                    <Rotunda />
                  </Route>
                  <Route path="/profile/:id">
                    <IndividualProfile />
                  </Route>
                  <Route path="/campaign/:id">
                    <CampaignProfile />
                  </Route>
                  <ProtectedRoute path="/chat/:id?">
                    <ChatWindow />
                  </ProtectedRoute>
                  <ProtectedRoute path="/explore">
                    <Explore />
                  </ProtectedRoute>
                  <ProtectedRoute path="/favorites">
                    <Favorites />
                  </ProtectedRoute>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </NavbarPage>
            </Route>
          </Switch>
        )}
      </Router>
    </ChakraProvider>
  );
}

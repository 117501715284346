import { Skeleton, Image, Avatar, ImageProps, AvatarProps } from "@chakra-ui/react";
import { useState } from "react";

interface SkeletonImageProps extends ImageProps {
  isLoaded?: boolean;
}

export default function SkeletonAvatar({ w, h, isLoaded, ...props }: SkeletonImageProps) {
  // TODO: the image sometimes never loads due to a Cross-Origin Read Blocking error
  const [loaded, setLoaded] = useState(!props.src);
  const sharedProps = { w, h };

  return (
    <Skeleton
      isLoaded={(isLoaded === undefined || isLoaded) && loaded}
      borderRadius="full"
      {...sharedProps}
    >
      {props.src ?
        <Image
          {...props}
          {...sharedProps}
          borderRadius="full"
          onLoad={() => setLoaded(true)}
          objectFit="cover"
        /> :
        <Avatar
          {...(props.src && { bg: "white" })}
          {...(props as AvatarProps)}
          {...sharedProps}
        />
      }
    </Skeleton>
  );
}
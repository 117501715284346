import HitProps from "./HitProps";
import { Box, Flex, Tag, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { addFavoriteCampaign, deleteFavoriteCampaign } from "../../app-state/actions/favorites";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";
import FavButton from "../Favorite/FavButton";
import { auth } from "../../firebase";

export default function CampaignHit({ hit }: HitProps) {
  // put spaces between code sections so I can see what's going on! :)
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  if (!hit) {
    return <></>;
  }
  const tagArray = hit.policyPriorities;

  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteCampaign(accountType, hit));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteCampaign(accountType, hit));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  return auth.currentUser?.uid === hit.objectID ? (
    <></>
  ) : (
    <Box w="400px" mt="0px">
      <Box ml="320px" mb="-60px" position="relative" zIndex={2}>
        <FavButton
          type="campaign"
          initialFavorited={true}
          customOnClick={toggleFavorite}
        ></FavButton>
      </Box>
      <Link to={"/campaign/" + hit.objectID}>
        <Box
          bg="white"
          boxShadow="base"
          borderColor="politiq.gray"
          borderRadius={10}
          w="100%"
          fontSize="16px"
        >
          <Box>
            {/* Star for favorites */}

            <Box
              position="relative"
              bg={
                !hit?.bannerPic
                  ? "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 71.35%)"
                  : ""
              }
              overflow="hidden"
              h="200px"
              borderTopRadius={10}
              zIndex={1}
            >
              {hit?.bannerPic && (
                <Image
                  position="absolute"
                  top={0}
                  src={hit?.bannerPic}
                  zIndex={1}
                  filter="brightness(50%)"
                />
              )}
            </Box>

            <Flex mt={-12} position="absolute" ml={4} zIndex={2}>
              <Flex wrap="wrap" maxW="380px" maxH="30px" overflow="hidden">
                {tagArray.map((name, index) => (
                  <Tag
                    key={index}
                    fontSize="16px"
                    color="black"
                    borderRadius="25px"
                    backgroundColor="politiq.gray"
                    px={5}
                    py={1}
                    mb={1}
                    mr={2}
                    maxW="45%"
                  >
                    <Text isTruncated>{name}</Text>
                  </Tag>
                ))}
              </Flex>
            </Flex>
          </Box>

          <Box px={9} pb={6} pt={4} fontSize="16px">
            <Flex mb={-1} mt={0} alignItems="center" justifyContent="space-between">
              <Box fontWeight="bold" fontSize="25px" w="72%" mr={3}>
                {hit.campaignName}
              </Box>

              <Tag
                fontSize="16px"
                color="black"
                borderRadius="25px"
                backgroundColor="politiq.lightgray"
                px={5}
                py={1}
                textAlign="center"
              >
                {hit.officeLevel}
              </Tag>
            </Flex>

            <Flex mt={4} justifyContent="space-between">
              <Box color="politiq.darkgray" w="50%">
                {/* US Congress */}
                <Text>{hit.positionLevel}</Text>
              </Box>

              <Box color="politiq.darkgray">
                {/* Houston, TX */}
                <Text noOfLines={1}>{hit.city + ", " + hit.state}</Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}

import { useForm, UseFormProps } from "react-hook-form";

export default function useValidatedForm<FieldValues>(props?: UseFormProps<FieldValues>) {
  const {
    register,
    watch,
    formState: { errors, touchedFields, dirtyFields, isValid },
    handleSubmit,
    reset
  } = useForm<FieldValues>({ mode: "all", ...props });
  const formProps = { register, watch, touchedFields, dirtyFields, errors };
  return { register, isValid, handleSubmit, formProps, reset };
}

import { Tag, WrapItem } from "@chakra-ui/react";
import {
  Box,
  Flex,
  LinkBox,
  Image,
} from "@chakra-ui/react";
import FavButton from "../Favorite/FavButton";
import { deleteFavoriteJob, addFavoriteJob } from "../../app-state/actions/favorites";
import useIsCampaign from "../../hooks/useIsCampaign";
import { useAppDispatch } from "../../hooks";
import { useHistory } from "react-router";
import logo from "../../images/Dollar.png";
export default function SuggestedJobCard(props) {

  const history = useHistory();
  const dispatch = useAppDispatch();
  const tagArray = props.tags;
  const overrideFavButton = props.overrideFavButton;
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteJob(accountType, props.job));
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteJob(accountType, props.job));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  return (
    <Box mt="5px">
      <Box ml="320px" mb="-67px" position="relative" zIndex={2}>
        <FavButton
          type="job"
          overrideButton={overrideFavButton}
          customOnClick={toggleFavorite}
          id={props.job.id}
          initialFavorited={props.favorited}
        ></FavButton>
      </Box>

      <LinkBox
        onClick={() => {
          history.push(`/campaign/${props.job.campaign}`);
        }}
        cursor="pointer"
        position="relative"
        zIndex={1}
      >
        <Box
          bg="white"
          boxShadow="base"
          borderColor="politiq.gray"
          borderRadius={10}
          px={9}
          py={6}
          w={props.rotundapage ? "400px" : "90%"}
          fontSize="16px"
          overflow="hidden"
          mb="45px"
        >
          <Flex mb={-1}>
            <Box fontWeight="bold" fontSize="30px" w="98%" mr={4} isTruncated>
              {props.job.title}
            </Box>
          </Flex>

          <Flex color="politiq.darkgray" w="100%" textAlign="left">
            {props.job.pay ? (
              <Image src={logo} alt="Paid job" objectFit="cover" pr="5px" pb="5px" mt="3px" />
            ) : null}
            {props.job.commitment}
          </Flex>

          <Box color="politiq.darkgray" mb={20}>
            {props.job.city + ", " + props.job.state}
          </Box>

          {/* " # Openings" prop that we plan to bring back to RotundaJobCard in future */}
          {/*<Box
        color="politiq.magenta"
        fontWeight="bold"
        mb={20}>
        {props.openings} openings
      </Box>*/}

          <Flex mb={3} w="100%" h="70px" overflow="hidden">
            <Flex spacing="10px" wrap="wrap">
              {tagArray.map((name, index) => (
                <WrapItem key={index}>
                  <Tag
                    fontSize="16px"
                    color="black"
                    borderRadius="25px"
                    backgroundColor="politiq.gray"
                    px={5}
                    py={1}
                    mr={2}
                    mb={2}
                  >
                    {name}
                  </Tag>
                </WrapItem>
              ))}
            </Flex>
          </Flex>

          {/* <Box fontWeight="bold" color="politiq.darkgray">
            Posted {props.daysPosted} days ago
          </Box> */}
        </Box>
      </LinkBox>
    </Box>
  );
}

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import axios from "axios";

import "./mock";

const firebaseConfig = {
  apiKey: "AIzaSyAd_comt1rngWCDG50bAKuAj02znvrhSHs",
  authDomain: "politiq-fbee2.firebaseapp.com",
  projectId: "politiq-fbee2",
  storageBucket: "politiq-fbee2.appspot.com",
  messagingSenderId: "811986207571",
  appId: "1:811986207571:web:648f703452d2ac336b97a6",
  measurementId: "G-RFS8QHVEVZ",
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

axios.interceptors.request.use(async (config) => {
  // all outgoing axios requests now automatically have the JWT token identifying the logged in user added to headers.authorization
  // this means that the server can automatically verify requests which need users to be logged in
  if (auth.currentUser && config.headers) {
    config.headers.authorization = await auth.currentUser.getIdToken();
  }
  return config;
});

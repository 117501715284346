import { FormEvent, ReactNode, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Spacer,
  Center,
  ButtonProps,
  Box,
} from "@chakra-ui/react";

import DeleteDialog from "./DeleteDialog";
import useCampaign from "../../../hooks/useCampaign";

interface InteractWithCampaignProfileContainerProps extends ButtonProps {
  name: string;
  children: ReactNode;
  deleteDialogName?: string;
  onDelete?: () => void;
  saveBtnSide: string;
  openButton?: (onOpen: () => void) => JSX.Element;
  noSelfView?: boolean;
  onSave?: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  loading?: boolean;
  isValid?: boolean;
  errorMessage?: string;
  buttonName?: string;
}

export default function InteractWithCampaignProfileContainer({
  name,
  children,
  deleteDialogName,
  onDelete,
  saveBtnSide,
  openButton,
  noSelfView = true,
  onSave,
  loading,
  isValid,
  errorMessage,
  buttonName = "Apply",
}: InteractWithCampaignProfileContainerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!loading && !errorMessage) onClose();
  }, [loading]);

  const { isCurrentCampaign } = useCampaign();
  if (noSelfView && isCurrentCampaign) {
    return null;
  }

  const saveButton = (
    <Button
      type="submit"
      variant="lavender"
      width="80px"
      disabled={isValid === false || loading}
      isLoading={loading}
    >
      Save
    </Button>
  );

  const modalWidths = {
    base: "calc(0.9 * 100vw)",
    lg: "calc(0.7 * 100vw)",
    xl: "calc(0.5 * 100vw)",
  };

  function preventDefaultAndSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (onSave) onSave(e);
  }
  // const initialText = jobListing.applicants.includes(applicantId) ? "Reapply to " : "Apply to ";
  return (
    <Box w="65%" position="relative" alignItems="center" mt={4}>
      <Center>
        <Button onClick={onOpen} w="50%" variant="lavender">
          {buttonName}
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW={modalWidths} w={modalWidths} p={0} borderRadius="10px">
            <ModalHeader
              bg="politiq.lavender"
              color="white"
              fontWeight="normal"
              borderRadius="10px 10px 0px 0px"
            >
              {name}
            </ModalHeader>
            <ModalCloseButton color="white" mt={1} size="lg" />
            <form onSubmit={preventDefaultAndSubmit}>
              <ModalBody px={{ base: 8, lg: 12 }} py={5}>
                {children}
                {errorMessage && (
                  <Box color="red.500" mt={4}>
                    {errorMessage}
                  </Box>
                )}
              </ModalBody>
              <ModalFooter mr={6} mb={3}>
                {deleteDialogName || saveBtnSide == "right" ? (
                  <>
                    {deleteDialogName && (
                      <DeleteDialog name={deleteDialogName} onDelete={onDelete} />
                    )}
                    <Spacer />
                    {saveButton}
                  </>
                ) : (
                  <Center w="100%">{saveButton}</Center>
                )}
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Center>
    </Box>
  );
}

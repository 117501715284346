import axios from "axios";

import { Campaign, JobListing, User, Favorites } from "types";
import { Thunkction } from "..";

let initialFetch = false;
export enum FavoritesActionType {
  FETCH_FAVORITES = "FETCH_FAVORITES",
  FETCHED_FAVORITES = "FETCHED_FAVORITES",
  FAILED_FAVORITES = "FAILED_FAVORITES",
  ADD_FAVORITE = "ADD_FAVORITE",
  DELETE_FAVORITE = "DELETE_FAVORITE",
}
export type AccountType = "user" | "campaign";

export type FavoritesAction =
  | {
      type: FavoritesActionType.FETCH_FAVORITES;
    }
  | {
      type: FavoritesActionType.FETCHED_FAVORITES;
      payload: Favorites;
    }
  | {
      type: FavoritesActionType.FAILED_FAVORITES;
      payload: string;
    }
  | {
      type: FavoritesActionType.ADD_FAVORITE;
      payload: any;
    }
  | {
      type: FavoritesActionType.DELETE_FAVORITE;
      payload: any;
    };

const fetchFavorites = (): FavoritesAction => {
  return {
    type: FavoritesActionType.FETCH_FAVORITES,
  };
};

const fetchedFavorites = (payload: Favorites): FavoritesAction => {
  return {
    type: FavoritesActionType.FETCHED_FAVORITES,
    payload: payload,
  };
};

const failedFetchFavorites = (payload): FavoritesAction => {
  return {
    type: FavoritesActionType.FAILED_FAVORITES,
    payload: payload,
  };
};
const addFavorite = (payload): FavoritesAction => {
  return {
    type: FavoritesActionType.ADD_FAVORITE,
    payload: payload,
  };
};
const deleteFavorite = (payload): FavoritesAction => {
  return {
    type: FavoritesActionType.DELETE_FAVORITE,
    payload: payload,
  };
};
const prefix = (accountType: AccountType) => {
  return accountType === "user" ? "/user" : "/campaign";
};
export const getFavorites =
  (accountType: AccountType, setLoading: boolean = false): Thunkction =>
  async (dispatch) => {
    if (initialFetch) {
      return;
    }
    if (setLoading) dispatch(fetchFavorites());
    try {
      const response = await axios.get(prefix(accountType) + "/getFavorites");
      await dispatch(fetchedFavorites(response.data as Favorites));
      initialFetch = true;
    } catch (err: any) {
      dispatch(failedFetchFavorites(err.message));
    }
  };

export const addFavoriteJob =
  (accountType: AccountType, job: JobListing): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/addFavorites", { favoriteJobs: [job.id] });
    await dispatch(addFavorite({ favoriteJobs: job }));
  };
export const addFavoriteCampaign =
  (accountType: AccountType, campaign: Campaign): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/addFavorites", { favoriteCampaigns: [campaign.uid] });
    await dispatch(addFavorite({ favoriteCampaigns: campaign }));
  };
export const addFavoriteUser =
  (accountType: AccountType, user: User): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/addFavorites", { favoriteUsers: [user.uid] });
    await dispatch(addFavorite({ favoriteUsers: user }));
  };
export const deleteFavoriteUser =
  (accountType: AccountType, user: User): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/deleteFavorites", { favoriteUsers: [user.uid] });
    await dispatch(deleteFavorite({ favoriteUsers: user }));
  };
export const deleteFavoriteJob =
  (accountType: AccountType, job: JobListing): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/deleteFavorites", { favoriteJobs: [job.id] });
    await dispatch(deleteFavorite({ favoriteJobs: job }));
  };
export const deleteFavoriteCampaign =
  (accountType: AccountType, campaign: Campaign): Thunkction =>
  async (dispatch) => {
    await axios.post(prefix(accountType) + "/deleteFavorites", {
      favoriteCampaigns: [campaign.uid],
    });
    await dispatch(deleteFavorite({ favoriteCampaigns: campaign }));
  };

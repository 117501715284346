import { useState } from "react";
import { Box, Center, Flex, Image } from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  DataAppTool,
  DigitalInternalSkill,
  DigitalOutreachSkill,
  ExecutionSkill,
  GeneralTechTool,
  ManagementAppTool,
  ManagementSkill,
  OutreachAppTool,
  JobListing,
} from "types";
import EditJobListing from "./EditProfile/EditJobListing";
import ApplyToJob from "./EditProfile/ApplyToJob";
import useIsCampaign from "../../hooks/useIsCampaign";
import { auth } from "../../firebase";
import FavButton from "../Favorite/FavButton";
import { addFavoriteJob, deleteFavoriteJob } from "../../app-state/actions/favorites";
import useAppDispatch from "../../hooks/useAppDispatch";
import useCampaign from "../../hooks/useCampaign";
import logo from "../../images/Dollar.png";
function formatDate(date) {
  return new Date(date).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
}

export default function Job({
  info,
  city,
  state,
  initialFavorited,
}: {
  info: JobListing;
  city: string;
  state: string;
  initialFavorited?: boolean;
}) {
  const dispatch = useAppDispatch();
  // console.log(info?.id, initialFavorited);
  const [clicked, setClicked] = useState(false);
  const isCampaign = useIsCampaign();
  const { campaign, isCurrentCampaign } = useCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteJob(accountType, info));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteJob(accountType, info));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  return (
    <Flex mt="0.75rem" direction="column" px={8} py={6} borderRadius="lg" bg="white">
      <Flex direction="row" justifyContent="space-between" w="100%">
        <Box fontWeight="bold" fontSize="1.3em" textAlign="left">
          {info.title}
        </Box>
        <Box textAlign="right">
          {!isCurrentCampaign && isCampaign !== undefined && (
            <FavButton
              type="job"
              initialFavorited={initialFavorited}
              customOnClick={toggleFavorite}
              id={info.id as string}
            ></FavButton>
          )}
        </Box>
        <EditJobListing
          jobListing={info}
          city={info.city || city}
          state={info.state || state}
          maxSkillsOrTools={5}
          categories={{
            "Digital - Outreach": Object.values(DigitalOutreachSkill),
            "Digital - Internal": Object.values(DigitalInternalSkill),
            Management: Object.values(ManagementSkill),
            Execution: Object.values(ExecutionSkill),
            "Data Apps": Object.values(DataAppTool),
            "Outreach Apps": Object.values(OutreachAppTool),
            "Management Apps": Object.values(ManagementAppTool),
            "General Tech": Object.values(GeneralTechTool),
          }}
        />
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        w="100%"
        mt="0.5rem"
        fontSize="1rem"
      >
        <Flex textAlign="left" align="center">
          {info.pay && <Image src={logo} alt="Paid job" mr={1} />}
          {info.type} & {info.commitment}
        </Flex>
        <Box>
          {info.city || city}, {info.state || state}
        </Box>
      </Flex>
      <Flex w="100%" direction="row" justify="space-between" mt="0.2em">
        <Flex justify="flex-start" wrap="wrap" w="90%">
          {info.skills.map((skill, i) => (
            <Box
              as="span"
              key={i}
              bg="politiq.lightgray"
              borderRadius="full"
              px="1em"
              pt="0.3em"
              fontSize="0.8em"
              mr="1em"
              mt="0.4em"
            >
              {skill}
            </Box>
          ))}
        </Flex>
        {clicked ? (
          <ChevronUpIcon
            cursor="pointer"
            boxSize={8}
            onClick={() => {
              setClicked(!clicked);
            }}
          />
        ) : (
          <ChevronDownIcon
            cursor="pointer"
            boxSize={8}
            onClick={() => {
              setClicked(!clicked);
            }}
          />
        )}
      </Flex>
      {clicked && (
        <Box mt="1rem" textAlign="left">
          Duration:{" "}
          {formatDate(info.startDate) + (info.endDate ? " - " + formatDate(info.endDate) : "")}
        </Box>
      )}
      {clicked && (
        <Box mt="1rem" textAlign="left" whiteSpace="pre-wrap">
          {info.description}
        </Box>
      )}
      <Center w="100%">
        {clicked && !isCampaign && auth.currentUser?.uid && <ApplyToJob jobListing={info} />}
      </Center>
    </Flex>
  );
}

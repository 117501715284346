import { useContext } from "react";
import { Box, Text, Image, Link } from "@chakra-ui/react";
import { NewsContext } from "../News/NewsProvider";
import * as Types from "../../../../types/index";
export default function HeadlineArticle(props) {
  const { poliNews }: { poliNews: Types.newsArticles[] } = useContext(NewsContext);

  // const headline = poliNews[0];
  return poliNews[0] ? (
    <Box
      position="relative"
      overflow="hidden"
      h={{ base: "200px", lg: "455px" }} // in Figma, we have 1440 x 455; can adjust height/width as necessary
      w="100%"
      alt={props.imgAltText}
    >
      <Link isExternal href={poliNews[0].url} overflow="hidden" w="100%" mb={10}>
        <Image
          position="absolute"
          src={poliNews[0].urlToImage}
          alt={props.imgAltText}
          left={0}
          w="100%"
          zIndex={1}
          filter="brightness(60%)"
        />
        <Box
          color="white"
          w="70%"
          left="10%" // left end starts 10% offset, allows the text to be fixed w/ screen size changes
          bottom={{ base: "20%", sm: "25%", md: "30%", lg: "37%", xl: "20%" }}
          zIndex={2}
          position="absolute"
        >
          <Text
            noOfLines={2}
            // filter=
            fontWeight="bold"
            fontSize={{ base: "24px", lg: "42px" }}
          >
            {poliNews[0].title}
          </Text>

          <Text noOfLines={{ base: 4, lg: 2 }} fontSize={{ base: "12px", lg: "20px" }}>
            {poliNews[0].description}
          </Text>
        </Box>
      </Link>
    </Box>
  ) : null;
}

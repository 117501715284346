import algoliasearch from "algoliasearch/lite";
import React from "react";
import {
  connectSearchBox,
  InstantSearch,
  connectInfiniteHits,
} from "react-instantsearch-dom";
import HitProps from "./HitProps";
import {
  Box,
  Flex,
  Button,
  Center,
} from "@chakra-ui/react";
import SuggestedJobCard from "../Rotunda/SuggestedJobCard";
import SuggestedCampaignCard from "../Rotunda/SuggestedCampaignCard";
import CampaignTeamMember from "../CampaignProfile/CampaignTeamMember";
import { User } from "types";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { auth } from "../../firebase";

interface SearchProps {
  searchQuery: string;
  searchIndex: string;
  hitComponent: React.FC<HitProps>;
  favorites: Set<string>;
}

const searchClient = algoliasearch("U1LBH4RY7T", "759fd3aded19782608cc6086da99c2a4");

const VirtualSearchBox = connectSearchBox(() => <span />);

export default function Search({ searchQuery, searchIndex, hitComponent, favorites }: SearchProps) {
  // function formatDate(mdate) {
  //   const date = new Date(mdate);
  //   const now = new Date();
  //   return Math.round((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
  // }

  // <SearchBox searchAsYouType={false} />;
  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    <form noValidate action="" role="search">
      <input
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      />
      <button onClick={() => refine("")}>Reset query</button>
      {isSearchStalled ? "My search is stalled" : ""}
    </form>
  );

  const InfiniteHits = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => (
    <Box align={{ cm: "right" }}>
      <Center mt="70px">
        {searchIndex === "search-jobs" ? (
          <Flex wrap="wrap" w="80%" justifyContent="center">
            {hits.map((hit) => {
              hit.id = hit.objectID;
              return (
                <Box mr="20px">
                  <SuggestedJobCard
                    tags={hit.skills}
                    favorited={favorites.has(hit.id)}
                    // daysPosted={formatDate(hit.lastmodified)}
                    rotundapage={true}
                    job={hit}
                  />
                </Box>
              );
            })}
          </Flex>
        ) : searchIndex === "search-campaigns" ? (
          <Flex wrap="wrap" w="80%" justifyContent="center">
            {hits.map((hit) => {
              hit.uid = hit.objectID;
              return (
                <Box mr="20px" mb="40px">
                  <SuggestedCampaignCard
                    tags={hit.policyPriorities}
                    campaign={hit}
                    rotundapage={false}
                    favorite={favorites.has(hit.uid)}
                  />
                </Box>
              );
            })}
          </Flex>
        ) : 
        searchIndex === "search-users" ? (
          <Flex wrap="wrap" w="100%" justifyContent="center" mt="-27px">
            {hits.map((hit) => {
              hit.uid = hit.objectID;
              if (hit.uid === auth.currentUser?.uid) {
                return <></>;
              }
              return (
                <Box mr="20px" mb="25px">
                  <CampaignTeamMember
                    teamMember={hit as User}
                    initialFavorited={favorites.has(hit.uid)}
                  />
                </Box>
              );
            })}
          </Flex>
        ) : (
          ""
        )}
      </Center>

      <Button disabled={!hasMore} onClick={refineNext} mx="50%" left="-65px">
        Show more
      </Button>
    </Box>
  );

  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

  return (
    <Box>
      <InstantSearch
        indexName={searchIndex}
        searchClient={searchClient}
        searchState={{
          query: searchQuery,
        }}
      >
        {/* https://www.surinderbhomra.com/Blog/2021/01/17/Passing-Search-Query-Without-SearchBox-In-Algolia */}
        <VirtualSearchBox />
        <CustomInfiniteHits />
      </InstantSearch>
    </Box>
  );
}

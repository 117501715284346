import { Grid, Box, Flex, Button, Spacer, Icon } from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { useHistory } from "react-router";
import { BsFacebook, BsGoogle } from "react-icons/bs";
import React, { useState } from "react";
import {
  signInWithPopup,
  getAdditionalUserInfo,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

import { auth, googleProvider, facebookProvider } from "../../firebase";
import { loginUserCometChat } from "../../cometchat";
import { DesktopOnly } from "../DesktopOnly";
import { MobileOnly } from "../MobileOnly";

interface ProviderAuthProps {
  prefix: string;
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  redirect: string;
  enabled: boolean;
  campaign?: boolean;
  verificationCode?: string;
  login: boolean;
  setPosition?: React.Dispatch<React.SetStateAction<string>>;
}

export default function ProviderAuth({
  prefix,
  setName,
  setErrorMessage,
  redirect,
  enabled,
  campaign,
  verificationCode,
  login,
  setPosition,
}: ProviderAuthProps) {
  // renders the buttons for logging in with Google or Facebook on the login and signup pages
  // also handles the OAuth process for these providers
  const facebookEnabled = false; // CHANGE LATER
  const [loading, setLoading] = useState<GoogleAuthProvider | FacebookAuthProvider | null>(null);
  const history = useHistory();

  async function providerLogin(
    provider: GoogleAuthProvider | FacebookAuthProvider,
    firstNameProperty: string,
    lastNameProperty: string
  ) {
    // issues a POST request to /register but does not include password
    // this is to tell the server that a user doesn't have to be created with auth.createUserWithEmailAndPassword
    setLoading(provider);
    setErrorMessage("");
    try {
      const credential = await signInWithPopup(auth, provider);
      const { profile, isNewUser }: any = getAdditionalUserInfo(credential);
      if (isNewUser) {
        let postUrl = "/auth/register";
        if (campaign === true) {
          postUrl = "/auth/registerCampaign";
        }
        const res = (await axios.post(postUrl, {
          firstName: profile[firstNameProperty],
          lastName: profile[lastNameProperty],
          email: profile.email,
        })) as AxiosResponse<any>;

        const authToken = res.data.chatToken;
        setName(profile[firstNameProperty]);
        const uid = auth.currentUser?.uid;
        if (uid) {
          // only if user actually logs in
          await loginUserCometChat(authToken);
        }
      } else {
        // cometchatToken already exists in user claims
        const userAsToken = await auth.currentUser?.getIdTokenResult();
        if (userAsToken) {
          const cometchatAuthToken = userAsToken.claims.chatToken;
          if (cometchatAuthToken) {
            await loginUserCometChat(cometchatAuthToken as string);
          }
        }
        history.push(redirect);
      }
    } catch (e: any) {
      setLoading(null);
      if (e.code === "auth/account-exists-with-different-credential") {
        setErrorMessage("An account already exists with that email.");
      } else if (
        e.code !== "auth/popup-closed-by-user" &&
        e.code !== "auth/cancelled-popup-request"
      ) {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    }
  }

  return (
    <>
      <Grid templateColumns={"1fr max-content 1fr"}>
        <Box borderBottom="1.5px solid" borderColor="politiq.lavender" h="50%"></Box>
        <Box align="center" bg="white" my="3" mx={3}>
          or
          <Box as="span" d={{ base: "inline", lg: "none" }}>{` ${prefix.toLowerCase()} with`}</Box>
        </Box>
        <Box borderBottom="1.5px solid" borderColor="politiq.lavender" h="50%"></Box>
      </Grid>
      <Flex>
        <Button
          w={facebookEnabled ? "48%" : "100%"}
          // align="center"
          variant="outline"
          disabled={!enabled}
          onClick={async () => {
            if (!login && campaign && setPosition) {
              try {
                const res = (await axios.get("/auth/sendPosition", {
                  params: { code: verificationCode },
                })) as AxiosResponse<any>;
                setPosition(res.data.position);
                providerLogin(googleProvider, "given_name", "family_name");
              } catch (e: any) {
                setErrorMessage("Incorrect verification code.");
              }
            } else {
              providerLogin(googleProvider, "given_name", "family_name");
            }
          }}
          isLoading={loading === googleProvider}
        >
          {
            <>
              <DesktopOnly>{prefix + " with Google"}</DesktopOnly>
              <MobileOnly>
                <Icon as={BsGoogle} color="politiq.lavender" />
              </MobileOnly>
            </>
          }
        </Button>
        <Spacer />
        {facebookEnabled && (
          <Button
            w="48%"
            variant="outline"
            disabled={!enabled}
            onClick={async () => {
              if (!login && campaign && setPosition) {
                try {
                  const res = (await axios.get("/auth/sendPosition", {
                    params: { code: verificationCode },
                  })) as AxiosResponse<any>;
                  setPosition(res.data.position);
                  providerLogin(facebookProvider, "given_name", "family_name");
                } catch (e: any) {
                  setErrorMessage("Incorrect verification code.");
                }
              } else {
                providerLogin(facebookProvider, "given_name", "family_name");
              }
            }}
            isLoading={loading === facebookProvider}
          >
            {
              <>
                <DesktopOnly>{prefix + " with Facebook"}</DesktopOnly>
                <MobileOnly>
                  <Icon as={BsFacebook} color="politiq.lavender" />
                </MobileOnly>
              </>
            }
          </Button>
        )}
      </Flex>
    </>
  );
}

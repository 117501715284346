import { Flex, Box, Stack, HStack, Tag, useBreakpointValue } from "@chakra-ui/react";

import useCampaign from "../../hooks/useCampaign";
import SkeletonAvatar from "../SkeletonAvatar";
import EditCampaignProfile from "./EditProfile/EditCampaignProfile";
import { DesktopOnly } from "../DesktopOnly";
import { MobileOnly } from "../MobileOnly";
import FavButton from "../Favorite/FavButton";
import { deleteFavoriteCampaign, addFavoriteCampaign } from "../../app-state/actions/favorites";
import { useAppDispatch } from "../../hooks";
import useIsCampaign from "../../hooks/useIsCampaign";
import { Campaign } from "types";

export default function CampaignProfileHeader(props) {
  const { campaign, isCurrentCampaign, loading } = useCampaign();
  const dispatch = useAppDispatch();
  const isCampaign = useIsCampaign();
  const accountType = isCampaign ? "campaign" : "user";
  const numShownPolicyPriorities = useBreakpointValue({ base: 2, sm: 3, md: 5 });
  const toggleFavorite = async (selected: boolean) => {
    if (selected) {
      // we want to unselect (delete fav)
      try {
        await dispatch(deleteFavoriteCampaign(accountType, campaign as Campaign));
        // console.log(favorites);
      } catch (e: any) {}
    } else {
      try {
        await dispatch(addFavoriteCampaign(accountType, campaign as Campaign));
        // console.log(favorites);
      } catch (e: any) {}
    }
  };
  const headlineAndPolicyPriorities = (
    <Box>
      <Box overflowWrap="anywhere" fontSize="sm" mt={2}>
        {campaign?.headline}
      </Box>
      <Box mt={{ base: 0, lg: 2 }}>
        {campaign?.policyPriorities &&
          numShownPolicyPriorities &&
          [
            ...campaign.policyPriorities.slice(0, numShownPolicyPriorities),
            ...(campaign.policyPriorities.length > numShownPolicyPriorities
              ? [`... ${campaign.policyPriorities.length - numShownPolicyPriorities} more`]
              : []),
          ].map((policyPriority) => (
            <Tag
              bg="politiq.brightgray"
              mt={2}
              mr={2}
              px={2}
              py={0.5}
              fontSize="xs"
              borderRadius="full"
              color="black"
            >
              {policyPriority}
            </Tag>
          ))}
      </Box>
    </Box>
  );

  const editOrStar =
    !loading &&
    (isCurrentCampaign ? (
      <EditCampaignProfile />
    ) : (
      <FavButton
        type="campaign"
        customOnClick={toggleFavorite}
        id={campaign?.uid}
        initialFavorited={props.isCampaignFavorited}
      />
    ));

  return (
    <Stack
      direction="column"
      px={{ base: 6, lg: 100 }}
      pt="5%"
      pb="5%"
      {...(campaign?.bannerPic && {})}
      bg={
        campaign?.bannerPic
          ? `url(${campaign?.bannerPic}?${new Date().getTime()})`
          : "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 71.35%)"
      }
      bgSize="cover"
      color="white"
    >
      <DesktopOnly>
        <Box
          position="absolute"
          float="right"
          top={3}
          bottom="calc(100% - 50px)"
          left="calc(100% - 60px)"
        >
          {editOrStar}
        </Box>
      </DesktopOnly>
      <HStack spacing={{ base: 6, lg: 10 }}>
        <SkeletonAvatar
          outline="6px solid"
          outlineColor="#222"
          w={{ base: "80px", lg: "150px" }}
          minW={{ base: "80px", lg: "150px" }}
          h={{ base: "80px", lg: "150px" }}
          {...(campaign?.profilePic && {
            src: campaign.profilePic + "?" + new Date().getTime(),
          })}
          alt={campaign?.campaignName}
        />
        <Box>
          <Box
            as="span"
            overflowWrap="anywhere"
            fontSize={{ base: "2xl", lg: "4xl" }}
            fontWeight="bold"
            lineHeight={{ base: 1, lg: 1.2 }}
            textTransform="uppercase"
            mr={{ base: 2, lg: 4 }}
          >
            {campaign?.campaignName}
          </Box>
          <Box
            as="span"
            position="relative"
            bottom={{ base: 1, lg: 2 }}
            bg="politiq.brightgray"
            fontSize="sm"
            mr={{ base: 3, lg: 4 }}
            px={3}
            py={0.5}
            borderRadius="full"
            color="black"
            overflowWrap="normal"
          >
            {campaign?.positionLevel}
          </Box>
          <MobileOnly>
            <Box as="span" position="relative" bottom={2}>
              {editOrStar}
            </Box>
          </MobileOnly>
          <Flex direction={{ base: "column", lg: "row" }}>
            <Box>{campaign?.officeLevel}</Box>
            <Box ml={{ base: 0, lg: 6 }}>{campaign?.city + ", " + campaign?.state}</Box>
          </Flex>
          <DesktopOnly>{headlineAndPolicyPriorities}</DesktopOnly>
        </Box>
      </HStack>
      <MobileOnly>{headlineAndPolicyPriorities}</MobileOnly>
    </Stack>
  );
}

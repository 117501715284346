import { Favorites } from "types";
import { FavoritesActionType } from "../actions/favorites";

export type FavoritesState = {
  loading: boolean;
  data: Favorites | null;
  error: string;
  // isCurrentCampaign?: boolean;
};

export const favorites = (
  state: FavoritesState = {
    loading: false,
    data: null,
    error: "",
  },
  { type, payload }
): FavoritesState => {
  switch (type) {
    case FavoritesActionType.FETCH_FAVORITES:
      return {
        loading: true,
        data: null,
        error: "",
      };
    case FavoritesActionType.FETCHED_FAVORITES:
      return {
        loading: false,
        data: payload,
        error: "",
      };
    case FavoritesActionType.FAILED_FAVORITES:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FavoritesActionType.ADD_FAVORITE:
      // const newState: Favorites = Object.assign({}, state, payload);
      let newStateAdd: Favorites = Object.assign({}, state.data);
      if (payload.favoriteCampaigns !== undefined) {
        newStateAdd.favoriteCampaigns.push(payload.favoriteCampaigns);
      }
      if (payload.favoriteJobs !== undefined) {
        newStateAdd.favoriteJobs.push(payload.favoriteJobs);
      }
      if (payload.favoriteUsers !== undefined) {
        newStateAdd.favoriteUsers.push(payload.favoriteUsers);
      }
      // let newStateAdd = Object.assign({}, state.data, payload);
      return {
        data: newStateAdd,
        loading: false,
        error: "",
      };
    case FavoritesActionType.DELETE_FAVORITE:
      let newStateDel: Favorites = Object.assign({}, state.data);
      if (payload.favoriteCampaigns !== undefined) {
        newStateDel.favoriteCampaigns = newStateDel.favoriteCampaigns.filter(
          (campaign) => campaign.uid !== payload.favoriteCampaigns.uid
        );
      }
      if (payload.favoriteJobs !== undefined) {
        newStateDel.favoriteJobs = newStateDel.favoriteJobs.filter(
          (job) => job.id !== payload.favoriteJobs.id
        );
      }
      if (payload.favoriteUsers !== undefined) {
        newStateDel.favoriteUsers = newStateDel.favoriteUsers.filter(
          (user) => user.uid !== payload.favoriteUsers.uid
        );
      }
      return {
        data: newStateDel,
        loading: false,
        error: "",
      };
    default:
      return state;
  }
};

import {
  FormControl,
  FormLabel,
  HStack,
  Select,
  Avatar,
  Switch,
  Stack,
  Button,
  SimpleGrid,
  Box,
  Checkbox,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { CloudUploadOutline } from "react-ionicons";
import { ChangeEvent, useState } from "react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";

import EditProfileContainer from "./EditProfileContainer";
import { Experience, USState } from "types";
import useValidatedForm from "../../../hooks/useValidatedForm";
import { useAppDispatch } from "../../../hooks";
import FormInput from "../../FormInput";
import FormTextArea from "../../FormTextArea";
import { deleteUserProfile, updateUserExperience } from "../../../app-state/actions/user";
import { Formik } from 'formik';
import * as Yup from "yup";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type EditExperienceFieldValues = Omit<Experience, "id" | "image" | "startDate" | "endDate"> & {
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
};

export default function EditExperience({ experience }: { experience?: Experience }) {
  const { register, isValid, handleSubmit, formProps, reset } =
    useValidatedForm<EditExperienceFieldValues>();
  const [image, setImage] = useState(
    (experience?.image && experience?.image + "?" + new Date().getTime()) || ""
  );
  const [isOngoing, setIsOngoing] = useState(!Boolean(experience?.endDate));
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const startDate = experience && new Date(experience.startDate);
  const endDate = experience?.endDate && new Date(experience.endDate);

  async function editExperience({
    startMonth,
    startYear,
    endMonth,
    endYear,
    ...data
  }: EditExperienceFieldValues) {
    if (!isOngoing && (!endMonth || !endYear)) {
      setErrorMessage("You must enter both an end month and year.");
      return;
    } else {
      const newExperience: Experience = {
        ...data,
        startDate: new Date(Number(startYear), months.indexOf(startMonth), 1).toISOString(),
        ...(!isOngoing && { endDate: new Date(Number(endYear), months.indexOf(endMonth), 2).toISOString() }),
        ...(experience && { id: experience.id }),
        image,
      };
      if (newExperience.endDate && newExperience.startDate > newExperience.endDate) {
        return setErrorMessage("End date must be after the start date.");
      }
      setLoading(true);
      setErrorMessage("");
      try {
        await dispatch(updateUserExperience(newExperience, image));
        reset();
        if (!experience) {
          setImage("");
          setIsOngoing(true);
        }
      } catch (e: any) {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
      setLoading(false);
    }
  }

  async function deleteExperience() {
    setLoading(true);
    setErrorMessage("");
    try {
      await dispatch(deleteUserProfile({ experiences: [experience?.id as string] }));
    } catch {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  function changeImage(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  const monthItems = months.map((month) => <option key={month}>{month}</option>);

  const currentYear = new Date().getFullYear();
  const yearItems = Array.from({ length: 100 }, (_, i) => (
    <option key={i}>{currentYear - i}</option>
  ));

  return (
    <EditProfileContainer
      name={(experience ? "Edit" : "Add") + " Experience"}
      {...(!experience && {
        openButton: (onOpen) => (
          <Button
            onClick={onOpen}
            w={1}
            bg="white"
            _hover={{ bg: "rgba(0, 0, 0, 0.05)" }}
            borderRadius="full"
            position="absolute"
            left="calc(100% - 24px)"
          >
            <AddIcon boxSize={5} />
          </Button>
        ),
      })}
      {...(experience && {
        deleteDialogName: "Experience",
        onDelete: deleteExperience,
        top: -2,
      })}
      saveBtnSide="right"
      onSave={handleSubmit(editExperience)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Stack spacing={4}>
        <FormInput
          name="title"
          label="Title"
          defaultValue={experience?.title}
          rules={{
            required: true,
            maxLength: 50,
          }}
          {...formProps}
        />

        <FormInput
          name="employer"
          label="Employer"
          defaultValue={experience?.employer}
          rules={{
            required: true,
            maxLength: 50,
          }}
          {...formProps}
        />

        <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={3}>
          <FormInput
            name="city"
            label="City"
            defaultValue={experience?.city}
            {...formProps}
            rules={{
              maxLength: 50,
              pattern: /^[a-z ,."-]+$/i,
            }}
          />

          <Box>
            <FormLabel>State</FormLabel>
            <Select
              placeholder=" "
              focusBorderColor="politiq.lavender"
              defaultValue={experience?.state}
              {...register("state")}
            >
              {Object.values(USState).map((state) => (
                <option key={state}>{state}</option>
              ))}
            </Select>
          </Box>
        </SimpleGrid>

        <FormControl isRequired>
          <FormLabel>Start Date</FormLabel>
          <HStack spacing={3}>
            <Select
              placeholder="Month"
              focusBorderColor="politiq.lavender"
              defaultValue={startDate && months[startDate.getMonth()]}
              {...register("startMonth", { required: true })}
            >
              {monthItems}
            </Select>

            <Select
              placeholder="Year"
              focusBorderColor="politiq.lavender"
              defaultValue={startDate && startDate.getFullYear()}
              {...register("startYear", { required: true })}
            >
              {yearItems}
            </Select>
          </HStack>
        </FormControl>

        <Box>
          <Flex>
            <FormLabel>End Date</FormLabel>
            <Spacer />
            <Checkbox
              colorScheme="purple"
              defaultChecked={!experience?.endDate}
              onChange={(e) => setIsOngoing(!isOngoing)}
            >
              Ongoing Experience
            </Checkbox>
          </Flex>

          <HStack spacing={3}>
            <Select
              placeholder="Month"
              focusBorderColor="politiq.lavender"
              {...(isOngoing && { value: "Month" })}
              defaultValue={endDate && months[endDate.getMonth()]}
              isDisabled={isOngoing}
              {...register("endMonth")}
            >
              {monthItems}
            </Select>

            <Select
              placeholder="Year"
              focusBorderColor="politiq.lavender"
              {...(isOngoing && { value: "Year" })}
              defaultValue={endDate && endDate.getFullYear()}
              isDisabled={isOngoing}
              {...register("endYear")}
            >
              {yearItems}
            </Select>
          </HStack>
        </Box>

        <FormTextArea
          name="description"
          label="Description"
          defaultValue={experience?.description}
          rules={{ maxLength: 300 }}
          {...formProps}
        />

        <Box>
          <FormLabel>Image</FormLabel>
          {image ? (
            <HStack>
              <Avatar size="xl" bg="white" d="block" mr={2} border="2px" src={image} />
              <Button variant="outline" borderRadius="full" onClick={() => setImage("")}>
                <HStack>
                  <CloseIcon color="politiq.lavender" ml={1} boxSize={3} /> :<Box>Clear Image</Box>
                </HStack>
              </Button>
            </HStack>
          ) : (
            <FormLabel
              border="1px"
              borderColor="politiq.lavender"
              borderRadius="full"
              d="inline-block"
              px={3}
              py={2}
              cursor="pointer"
              color="politiq.lavender"
              _hover={{ bg: "purple.50" }}
            >
              <HStack>
                <CloudUploadOutline color="politiq.lavender" />
                <Box>Upload Image</Box>
              </HStack>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                onChange={changeImage}
              />
            </FormLabel>
          )}
        </Box>

        <Box>
          <FormLabel>Is this experience Politiq earned?</FormLabel>
          <Switch
            colorScheme="purple"
            size="lg"
            defaultChecked={experience?.politiqEarned}
            {...register("politiqEarned")}
          />
        </Box>
      </Stack>
    </EditProfileContainer>
  );
}

import { User } from "types";
import { UserActionType } from "../actions/user";

export type UserState = {
  loading: boolean;
  data: User | null;
  error: string;
  isCurrentUser?: boolean;
};

export const user = (
  state: UserState = {
    loading: false,
    data: null,
    error: ""
  },
  { type, payload }
): UserState => {
  switch (type) {
    case UserActionType.FETCH_USER:
      return {
        loading: true,
        data: null,
        error: ""
      };
    case UserActionType.FETCHED_USER:
      return {
        loading: false,
        data: payload,
        error: ""
      };
    case UserActionType.FAILED_USER:
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};

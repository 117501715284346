import { ChangeEvent, useState } from "react";
import { Box, HStack, Button, FormLabel, Link, Tooltip } from "@chakra-ui/react";
import { CloseIcon, AttachmentIcon } from "@chakra-ui/icons";
import { CloudUploadOutline } from "react-ionicons";

import EditProfileContainer from "./EditProfileContainer"
import { User } from "types";
import FormInput from "../../FormInput";
import useValidatedForm from "../../../hooks/useValidatedForm";
import useUser from "../../../hooks/useUser";
import { useAppDispatch } from "../../../hooks";
import { updateUserProfile, updateUserResume } from "../../../app-state/actions/user";

type EditMoreDetailsFieldValues = Pick<User, "portfolioLink">;

const linkPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export default function EditMoreDetails() {
  const { user } = useUser();
  const { isValid, handleSubmit, formProps } = useValidatedForm<EditMoreDetailsFieldValues>();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [resumeLink, setResumeLink] = useState(user?.resumeLink);
  const dispatch = useAppDispatch();

  async function editMoreDetails(data: EditMoreDetailsFieldValues) {
    setLoading(true);
    setErrorMessage("");
    try {
      if (resumeLink) {
        await dispatch(updateUserResume(resumeLink));
      }
      await dispatch(updateUserProfile({
        ...data,
        ...(resumeLink === "" && { resumeLink })
      }));
    } catch (e: any) {
      setErrorMessage("An unknown error occurred. Please try again.");
    }
    setLoading(false);
  }

  function changeResumeLink(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setResumeLink(URL.createObjectURL(e.target.files[0]));
    }
  }

  return (
    <EditProfileContainer
      name="Edit Details"
      saveBtnSide="right"
      top={-2}
      onSave={handleSubmit(editMoreDetails)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Box mb={4}>
        <FormLabel>Resume</FormLabel>
        {resumeLink ?
          <HStack>
            <Link
              href={resumeLink}
              px={4}
              py={2}
              borderRadius={6}
              color="white"
              bg="politiq.darkgray"
              mr={2}
            >
              <HStack>
                <AttachmentIcon />
                <Box>Uploaded Resume</Box>
              </HStack>
            </Link>
            <Button
              variant="outline"
              borderRadius="full"
              onClick={() => setResumeLink("")}
            >
              <HStack>
                <CloseIcon color="politiq.lavender" ml={1} boxSize={3} /> :
                <Box>Remove Resume</Box>
              </HStack>
            </Button>
          </HStack> :
          <Box>
            <FormLabel
              border="1px"
              borderColor="politiq.lavender"
              borderRadius="full"
              d="inline-block"
              px={3}
              py={2}
              cursor="pointer"
              color="politiq.lavender"
              _hover={{ bg: "purple.50" }}
            >
              <HStack>
                <CloudUploadOutline color="politiq.lavender" />
                <Box>Upload Resume</Box>
              </HStack>

              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                onChange={changeResumeLink}
              />
            </FormLabel>
          </Box>
        }
      </Box>
      <Tooltip
      label='Portfolio links must be formatted "https://www.politiq.org" '
      placement="auto-start"
      aria-label="A tooltip">
        <FormInput
          name="portfolioLink"
          label='Portfolio Link (ex: "https://www.politiq.org") '
          defaultValue={user?.portfolioLink}
          rules={{ pattern: linkPattern }}
          {...formProps}
        />
      </Tooltip>
    </EditProfileContainer>
  );
}

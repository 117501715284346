import { Box, Grid, GridItem, Text, Image } from "@chakra-ui/react";
import { PlatformPoint } from "types";
import EditPlatformPoint from "./EditProfile/EditPlatformPoint";

export default function PolicyPlatform({ platform }: { platform: PlatformPoint }) {
  return (
    <Box bg="#D4D4D4" borderRadius="lg" pos="relative" top="15px" left="15px">
      <Box w="100%" borderRadius="lg" py={8} bg="white" pos="relative" top="-15px" left="-15px">
        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
          <GridItem colSpan={[2, 1, 1]} pl={[2.5, 5, 5]} align="center">
            <Image boxSize="70px" src={platform.icon} objectFit="scale-down" />
          </GridItem>
          <GridItem colSpan={[3, 4, 4]} align="left" pr="5%">
            <Text fontSize={"lg"} fontWeight={800}>
              {platform.title}
            </Text>
            <Text fontSize="md" style={{ whiteSpace: "pre-wrap" }}>
              {platform.description}
            </Text>
          </GridItem>
          <GridItem align="center" colSpan={1} mr={5}>
            <EditPlatformPoint platformPoint={platform} />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

import {
  Box,
  HStack,
  Button,
  Heading,
  Tooltip,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  chakra,
} from "@chakra-ui/react";
import { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import * as Types from "types";
import * as Router from "react-router-dom";
import { auth } from "../../firebase";

const ChakraLink = chakra(Router.Link);

export default function AccountType() {
  const history = useHistory();
  const [activePositionLevel, setActivePositionLevel] = useState("Local");
  const [type, setType] = useState("individual");
  const [loading, setLoading] = useState(false);

  const checkout = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("/auth/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscription_type: activePositionLevel,
      }),
    })
      .then((response) => response.json())
      .then(async (session) => {
        const stripe = await loadStripe(
          "pk_live_51KHEX6EGQvQ9Xll4laLMueegeNg0g6lAucElWSTy4SMspmlOpj2wMptLWbNSaBGOj0ir0l2RqWiExz5XQWHILosF00yTwDei5y"
        );
        stripe?.redirectToCheckout({ sessionId: session.id });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const nextStep = () => {
    history.push("/signup");
  };

  return auth.currentUser ? (
    <Redirect to="/rotunda" />
  ) : (
    <Box position="relative" height="100%">
      <Tooltip
        label="A campaign account has a price associated with it. With it, your organization can post job listings, interact with candidates, and advertise your campaign on our platform."
        placement="auto-start"
        aria-label="A tooltip"
      >
        <Heading color="politiq.lavender" fontSize="2xl" pb={6}>
          Select your account type
        </Heading>
      </Tooltip>
      <RadioGroup name="accountType" value={type} onChange={setType}>
        <HStack>
          <Radio value="individual">Individual</Radio>
          <Radio value="campaign">Campaign</Radio>
        </HStack>
      </RadioGroup>
      {type === "campaign" && (
        <HStack mb={5} mt={4} spacing="5%">
          <FormControl isRequired>
            <FormLabel>Position Level</FormLabel>
            <Select
              focusBorderColor="politiq.lavender"
              backgroundColor="politiq.lightgray"
              borderRadius={0}
              w="100%"
              onChange={(e) => {
                setActivePositionLevel(e.target.value);
              }}
            >
              {Object.values(Types.PositionLevel).map((positionLevel) => (
                <option key={positionLevel}>{positionLevel}</option>
              ))}
            </Select>
          </FormControl>
        </HStack>
      )}
      <Box right="0" pos={{ lg: "absolute" }} bottom="0" w="100%" mt={{ sm: 4, md: 4, lg: 0 }}>
        <Button
          onClick={(e) => {
            if (type === "campaign") {
              checkout(e);
            } else {
              nextStep();
            }
          }}
          variant="lavender"
          width="100%"
          isLoading={loading}
        >
          {type === "campaign" ? "Submit Payment" : "Next"}
        </Button>
        <Box align="center" mt={6}>
          Have an account already?{" "}
          <ChakraLink color="politiq.lavender" to="/login">
            Sign in.
          </ChakraLink>
        </Box>
      </Box>
    </Box>
  );
}

import { onAuthStateChanged } from "@firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

export default function useIsCampaign() {
  let [isCampaign, setIsCampaign] = useState<boolean>();
  useEffect(() => {
    onAuthStateChanged(auth, () => {
      auth.currentUser?.getIdTokenResult().then(({ claims }) => {
        setIsCampaign(Boolean(claims.isCampaign));
      });
    });
  }, []);
  return isCampaign;
}

import {
  FormControl,
  FormLabel,
  SimpleGrid,
  Avatar,
  Center,
  Select,
  Stack,
  Button,
  Wrap,
  Box,
  Text,
  Image,
  HStack,
  VStack,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, ChangeEvent} from "react";

import EditCampaignProfileContainer from "./EditCampaignProfileContainer";
import { Campaign, USState } from "types";
import * as Types from "types";
import FormInput from "../../FormInput";
import FormTextArea from "../../FormTextArea";
import useValidatedForm from "../../../hooks/useValidatedForm";
import useCampaign from "../../../hooks/useCampaign";
import { useAppDispatch } from "../../../hooks";
import {
  updateCampaignProfilePic,
  updateCampaignBannerPic,
  updateCampaignMedia,
  updateCampaignProfile,
  getCampaign,
  deleteCampaignProfile,
} from "../../../app-state/actions/campaign";
import { CloudUploadOutline } from "react-ionicons";
import { auth } from "../../../firebase";

type EditCampaignProfileFieldValues = Pick<
  Campaign,
  "campaignName" | "headline" | "city" | "state" | "biography" | "websiteUrl"
>;

export default function EditCampaignProfile() {
  const { campaign } = useCampaign();
  const { register, isValid, handleSubmit, formProps } =
    useValidatedForm<EditCampaignProfileFieldValues>();
  const [profilePic, setProfilePic] = useState<string>(
    (campaign?.profilePic && campaign.profilePic + "?" + new Date().getTime()) || ""
  );
  const [changedProfilePic, setChangedProfilePic] = useState(false);

  const [bannerPic, setBannerPic] = useState<string>(
    (campaign?.bannerPic && campaign.bannerPic + "?" + new Date().getTime()) || ""
  );
  const [changedBannerPic, setChangedBannerPic] = useState(false);

  const [media, setMedia] = useState<string[]>(campaign?.media || []);
  const [selected, setSelected] = useState(campaign?.policyPriorities || []);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const [activePositionLevel, setActivePositionLevel] = useState(campaign?.positionLevel || "");
  const [activeIssue, setActiveIssue] = useState("");

  const dispatch = useAppDispatch();
  const responsiveSize = useBreakpointValue({ base: "xs", lg: "md" });

  const mappings = {
    Local: Object.values(Types.LocalPosition),
    County: Object.values(Types.CountyPosition),
    Regional: Object.values(Types.RegionalPosition),
    State: Object.values(Types.StatePosition),
    Federal: Object.values(Types.FederalPosition),
    "": [],
  };

  const issueMappings = {
    "Economic Issues": Object.values(Types.EconomicPolicyPriority),
    "Social Issues": Object.values(Types.SocialPolicyPriority),
    "Healthcare Issues": Object.values(Types.HealthcarePolicyPriority),
    "Domestic Issues": Object.values(Types.DomesticPolicyPriority),
    "Technology Issues": Object.values(Types.TechnologyPolicyPriority),
    "Infrastructure Issues": Object.values(Types.InfrastructurePolicyPriority),
    "Energy Issues": Object.values(Types.EnergyPolicyPriority),
    "Environmental Issues": Object.values(Types.EnvironmentalPolicyPriority),
    "Religious/Community Issues": Object.values(Types.ReligiousAndCommunityPolicyPriority),
    "International Issues": Object.values(Types.InternationalPolicyPriority),
    "State Issues": Object.values(Types.StatePolicyPriority),
    "": [],
  };

  const maxItems = 5;

  // const checkForChanges = ({ policyPriorities, ...newData }: Partial<Campaign>) => {
  //   return (
  //     !campaign ||
  //     policyPriorities!.length !== campaign.policyPriorities.length ||
  //     !policyPriorities!.every((priority, i) => priority === campaign.policyPriorities[i]) ||
  //     Object.keys(newData).some((field) => newData[field] !== campaign[field])
  //   );
  // };
  const createUpdateObject = (data: Partial<Campaign>): Partial<Campaign> => {
    if (!campaign) return data;
    let newObject = {};
    for (const key in data) {
      if (data[key] !== campaign[key]) {
        newObject[key] = data[key];
      }
    }
    return newObject;
  };
  async function editProfile(data: EditCampaignProfileFieldValues) {
    setLoading(true);
    // if (activePositionLevel === "") {
    //   setErrorMessage("Invalid level of government/office.");
    //   return;
    // }
    setErrorMessage("");
    try {
      if (changedProfilePic) {
        await dispatch(updateCampaignProfilePic(profilePic));
      }
      if (changedBannerPic) {
        await dispatch(updateCampaignBannerPic(bannerPic));
      }
      const mediaSet = new Set(media);
      const campaignMediaSet = new Set(campaign!.media || []);
      const deletedMedia = campaign!.media.filter((value) => !mediaSet.has(value));
      const newMedia = media.filter((value) => !campaignMediaSet.has(value));
      if (deletedMedia.length > 0) {
        await dispatch(deleteCampaignProfile({ media: deletedMedia }, false));
      }
      for (const item of newMedia) {
        await dispatch(updateCampaignMedia(item));
      }

      let newData: Partial<Campaign> = { ...data };
      // newData.positionLevel = activePositionLevel as Types.PositionLevel;
      newData.policyPriorities = selected;
      // const remainingChanges = checkForChanges(newData); // save a bit on POST operations with this check!
      const updateObject = createUpdateObject(newData);
      if (updateObject) {
        // ensure that city and state are always coupled in updates (for geolocation updating)
        if (updateObject.city && !updateObject.state) {
          updateObject.state = campaign?.state as USState;
        } else if (updateObject.state && !updateObject.city) {
          updateObject.city = campaign?.city as string;
        }
        await dispatch(updateCampaignProfile(updateObject));
      } else if (
        changedProfilePic ||
        changedBannerPic ||
        deletedMedia.length > 0 ||
        newMedia.length > 0
      ) {
        await dispatch(getCampaign(auth.currentUser?.uid as string));
      }
    } catch (e: any) {
      if (e.response?.status === 409) {
        setErrorMessage("The username you've entered is already in use.");
      } else {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    }
    setChangedProfilePic(false);
    setChangedBannerPic(false);
    setLoading(false);
  }

  function changeProfilePic(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setProfilePic(URL.createObjectURL(e.target.files[0]));
      setChangedProfilePic(true);
    }
  }

  function changeBannerPic(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setBannerPic(URL.createObjectURL(e.target.files[0]));
      setChangedBannerPic(true);
    }
  }

  function addMedia(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setMedia([...media, URL.createObjectURL(e.target.files[0])]);
    }
  }

  function addIssue(item) {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
      setErrorMessage("");
    } else if (selected.length < maxItems) {
      setSelected([item, ...selected]);
    } else {
      setErrorMessage(`You can only select up to ${maxItems} issues.`);
    }
  }

  return (
    <EditCampaignProfileContainer
      name="Edit Campaign Profile"
      saveBtnSide="right"
      position="static"
      size={responsiveSize}
      mt={2}
      iconBoxSize={{ base: 4, lg: 6 }}
      onSave={handleSubmit(editProfile)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
    >
      <Center>
        <SimpleGrid my={4} columns={[1, 2, 2]} spacing={50}>
          <Center>
            <Stack textAlign="center">
              <Text>Profile Picture</Text>
              <label htmlFor="profile-upload">
                <Avatar
                  size="2xl"
                  _hover={{ opacity: 0.8 }}
                  transition="0.2s"
                  bg={profilePic || campaign?.profilePic ? "white" : undefined}
                  src={profilePic || campaign?.profilePic}
                  cursor="pointer"
                >
                  <AddIcon
                    position="absolute"
                    bottom={0}
                    right="10px"
                    borderRadius="full"
                    bg="politiq.gray"
                    boxSize={7}
                    p={1}
                  />
                </Avatar>
              </label>
              <input
                id="profile-upload"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                onChange={changeProfilePic}
              />
            </Stack>
          </Center>
          <Center>
            <Stack textAlign="center">
              <Text>Banner Picture</Text>
              <label htmlFor="banner-upload">
                <Avatar
                  size="2xl"
                  _hover={{ opacity: 0.8 }}
                  style={{ width: "200px", borderRadius: 10 }}
                  transition="0.2s"
                  border="2px solid black"
                  borderRadius="10"
                  bg="white"
                  src={bannerPic || campaign?.bannerPic}
                  cursor="pointer"
                >
                  <AddIcon
                    position="absolute"
                    bottom={2}
                    right="10px"
                    borderRadius="full"
                    bg="politiq.gray"
                    boxSize={7}
                    p={1}
                  />
                </Avatar>
              </label>
              <input
                id="banner-upload"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                onChange={changeBannerPic}
              />
            </Stack>
          </Center>
        </SimpleGrid>
      </Center>
      <Stack>
        <FormInput
          name="campaignName"
          label="Campaign Name"
          defaultValue={campaign?.campaignName}
          rules={{
            required: true,
            maxLength: 50,
          }}
          {...formProps}
        />
        <FormInput
          name="username"
          label="Username"
          defaultValue={campaign?.username}
          rules={{
            required: true,
            pattern: /^[a-z\d_.-]+$/i,
          }}
          {...formProps}
        />
        <FormInput
          name="headline"
          label="Headline"
          defaultValue={campaign?.headline}
          rules={{ maxLength: 100 }}
          {...formProps}
        />
      </Stack>

      <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
        <FormInput
          name="city"
          label="City"
          defaultValue={campaign?.city}
          {...formProps}
          rules={{
            required: true,
            pattern: /^[a-z ,."-]+$/i,
          }}
        />
        <FormControl isRequired>
          <FormLabel>State</FormLabel>
          <Select
            placeholder=" "
            focusBorderColor="politiq.lavender"
            defaultValue={campaign?.state}
            {...register("state", { required: true })}
          >
            {Object.values(USState).map((state) => (
              <option key={state}>{state}</option>
            ))}
          </Select>
        </FormControl>
      </SimpleGrid>

      {/* <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
        <FormControl isRequired>
          <FormLabel>Level of Government</FormLabel>
          <Select
            placeholder=""
            focusBorderColor="politiq.lavender"
            defaultValue={campaign?.positionLevel}
            onChange={(e) => setActivePositionLevel(e.target.value)}
          >
            {Object.values(PositionLevel).map((positionLevel) => (
              <option key={positionLevel}>{positionLevel}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Level of Office</FormLabel>
          <Select
            placeholder=""
            focusBorderColor="politiq.lavender"
            defaultValue={campaign?.officeLevel}
            {...register("officeLevel", { required: true })}
          >
            {Object.values(mappings[activePositionLevel]).map((officeLevel) => (
              <option key={officeLevel as Types.OfficeLevel}>
                {officeLevel as Types.OfficeLevel}
              </option>
            ))}
          </Select>
        </FormControl>
      </SimpleGrid> */}
      <SimpleGrid my={4} columns={{ base: 1, xl: 2 }} spacing={3}>
        <Box>
          <Text mt={1}>Issue Categories</Text>
          <Select
            maxW="300px"
            placeholder="Select category"
            onChange={(e) => setActiveIssue(e.target.value)}
          >
            {Object.keys(issueMappings).map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          {issueMappings[activeIssue].length > 0 && (
            <>
              <Text>Issues</Text>
              <Box>
                <Wrap mt={1}>
                  {issueMappings[activeIssue].map((item: Types.PolicyPriority) => (
                    <Button
                      key={item}
                      size="xs"
                      bg={selected.includes(item) ? "politiq.darkgray" : "white"}
                      color={selected.includes(item) ? "white" : "politiq.darkgray"}
                      _hover={{
                        bg: selected.includes(item) ? "politiq.gray" : "politiq.brightgray",
                      }}
                      border="1px"
                      borderColor="politiq.darkgray"
                      borderRadius="full"
                      mr={2}
                      mb={2}
                      onClick={() => addIssue(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </Wrap>
              </Box>
            </>
          )}
        </Box>
      </SimpleGrid>
      <FormTextArea
        label="Campaign Biography"
        name="biography"
        defaultValue={campaign?.biography}
        rules={{
          required: true,
          maxLength: 1000,
        }}
        {...formProps}
      />
      <FormInput
        name="websiteUrl"
        label="Website"
        defaultValue={campaign?.websiteUrl}
        rules={{
          pattern:
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
        }}
        {...formProps}
      />
      <Box>
        <FormLabel>Media</FormLabel>
        <FormLabel
          border="1px"
          borderColor="politiq.lavender"
          borderRadius="full"
          d="inline-block"
          px={3}
          py={2}
          cursor="pointer"
          color="politiq.lavender"
          _hover={{ bg: "purple.50" }}
        >
          <HStack>
            <CloudUploadOutline color="politiq.lavender" />
            <Box>Upload Image</Box>
          </HStack>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
            onChange={addMedia}
          />
        </FormLabel>
        <Wrap spacing={5} mt={2}>
          {media.map((mediaItem) => {
            return (
              <WrapItem key={mediaItem}>
                <VStack spacing={3}>
                  <Image
                    boxSize={{ base: "120px", md: "150px" }}
                    bg="white"
                    border="2px"
                    objectFit="cover"
                    src={mediaItem}
                  />
                  <Button
                    w={{ base: "120px", md: "150px" }}
                    variant="outline"
                    borderRadius="full"
                    onClick={() => {
                      setMedia(media.filter((item) => item !== mediaItem));
                    }}
                  >
                    <HStack>
                      <CloseIcon color="politiq.lavender" ml={1} boxSize={3} /> :
                      <Box fontSize="sm">Clear Image</Box>
                    </HStack>
                  </Button>
                </VStack>
              </WrapItem>
            );
          })}
        </Wrap>
      </Box>
    </EditCampaignProfileContainer>
  );
}

import {
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

import * as Types from "types";
import useValidatedForm from "../../../hooks/useValidatedForm";
import { useAppDispatch } from "../../../hooks";
import FormTextArea from "../../FormTextArea";
import InteractWithCampaignProfileContainer from "./InteractWithCampaignProfileContainer";
import { sendMessageCometChat } from "../../../cometchat";
import { useHistory } from "react-router";
import { auth } from "../../../firebase";
import { sendJobApp } from "../../../app-state/actions/user";

type ApplyToJobFieldValues = {
  message: string;
};

const FRONTEND_URL = "https://politiq.org" // "https://devversion-dot-politiq-fbee2.uc.r.appspot.com" // "https://aayush-davetx.github.io/politiq-v1"; // "https://www.politiq.org"; //"http://localhost:3000"; // "https://politiq.org"

export default function ApplyToJob({ jobListing }: { jobListing: Types.JobListing }) {
  const { isValid, handleSubmit, formProps, reset } = useValidatedForm<ApplyToJobFieldValues>();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const applicantId = auth.currentUser?.uid as string;
  async function applyToJob({ message }: ApplyToJobFieldValues) {
    if (message === undefined || message === null || message.length === 0) {
      setErrorMessage("You must enter a message.");
      return;
    } else {
      const campaign = jobListing.campaign;
      setLoading(true);
      setErrorMessage("");
      try {
        jobListing.applicants.push(applicantId);
        await dispatch(sendJobApp(jobListing.id as string, campaign));
        const profileLink = `${FRONTEND_URL}/profile/${applicantId}`;
        const template = `Application for the role: ${jobListing.title}. \n\n${message}\n\nProfile Link: ${profileLink}`;
        await sendMessageCometChat(campaign, template);
        history.push("/chat/" + campaign);
        reset();
      } catch (e: any) {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
      setLoading(false);
    }
  }
  const initialText = jobListing.applicants.includes(applicantId) ? "Reapply" : "Apply";
  return (
    <InteractWithCampaignProfileContainer
      name={jobListing ? initialText + " to " + jobListing.title : ""}
      saveBtnSide="right"
      onSave={handleSubmit(applyToJob)}
      loading={loading}
      errorMessage={errorMessage}
      isValid={isValid}
      noSelfView={true}
      buttonName={initialText}
    >
      <Stack spacing={4}>
        <Text>Your user profile will be attached to your application automatically.</Text>
        <FormTextArea name="message" label="Message" rules={{ maxLength: 10000 }} {...formProps} />
      </Stack>
    </InteractWithCampaignProfileContainer>
  );
}

import { Box, Text, Flex, Image } from "@chakra-ui/react";

const NewsArticleTab = ({
  articleSource,
  articleTitle,
  articleImg,
  articleText,
  publishDate,
  genre,
}) => {
  const date = new Date(publishDate);

  return (
    <Box
      w="100%"
      display="flex"
      mb="25px"
      _hover={{ boxShadow: "0 2px 4px rgba(0,0,0,0.25)" }}
      backgroundColor="white"
      justifyContent="space-between"
      flexDirection={{ base: "column", cm: "row" }}
    >
      <Flex direction="column" mt="18px" mb="25px" mx="30px">
        <Flex direction="row">
          <Text color="#747474" fontSize="14px">
            {articleSource}
          </Text>
        </Flex>
        <Text mt="3px" fontSize="22px" fontWeight="bold">
          {articleTitle}
        </Text>
        <Flex alignItems="center">
          <Text fontSize="12px" fontWeight="bold" color="#747474">
            {date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })}
          </Text>
          <Text color="#C4C4C4" mx="5px" fontSize="24px">
            ·
          </Text>
          <Text
            backgroundColor="#D7D7D7"
            borderRadius="10px"
            color="#968D8D"
            fontSize="10px"
            px="12px"
            py="3px"
            textTransform="capitalize"
          >
            {genre}
          </Text>
        </Flex>
        <Text mt="5px" fontSize="14px" color="#7C7C7C">
          {articleText}
        </Text>
      </Flex>
      <Image
        src={articleImg}
        alt={articleTitle}
        maxH={{ base: "auto", cm: "260px" }}
        ml={{ base: "0px", cm: "10px" }}
      />
    </Box>
  );
};

export default NewsArticleTab;

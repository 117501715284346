import { useAppSelector } from ".";
import { auth } from "../firebase";

export default function useCampaign() {
  const { data, ...campaignState } = useAppSelector(state => state.campaign);
  return {
    campaign: data,
    isCurrentCampaign: data?.uid === auth.currentUser?.uid,
    ...campaignState
  }
}

import {
  Box,
  Flex,
  Spacer,
  Image,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  useTheme,
  useDisclosure,
  useBreakpointValue,
  BoxProps,
} from "@chakra-ui/react";
import { SearchIcon, HamburgerIcon } from "@chakra-ui/icons";
import { NavLink, NavLinkProps, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { auth } from "../firebase";
import { DesktopOnly } from "./DesktopOnly";
import { MobileOnly } from "./MobileOnly";
import useUser from "../hooks/useUser";
import { logoutUserCometChat } from "../cometchat";
import useCampaign from "../hooks/useCampaign";
import useIsCampaign from "../hooks/useIsCampaign";
import logo from "../images/transparent-logo.png";

function HighlightedLink(props: NavLinkProps) {
  const theme = useTheme();
  const fontSize = theme.fontSizes[useBreakpointValue({ lg: "md" }) ?? "2xl"];
  return (
    <NavLink
      activeStyle={{ color: theme.colors.politiq.lavender, fontWeight: 700 }}
      style={{ fontSize, fontFamily: "Yantramanav, sans-serif" }}
      {...props}
    />
  );
}

function Navbar() {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const { user } = useUser();
  const { campaign } = useCampaign();
  const isCampaign = useIsCampaign();
  function handleSearch({ search }) {
    history.push("/explore?searchQuery=" + search);
  }

  async function logout() {
    await auth.signOut();
    await logoutUserCometChat();
    history.push("/login");
  }
  const profilePrefix = isCampaign ? "/campaign/" : "/profile/";

  function isCurrentProfile(match, { pathname }: { pathname: string }) {
    return (
      isCampaign !== undefined &&
      pathname.startsWith(profilePrefix) &&
      (pathname.endsWith(auth.currentUser?.uid as string) ||
        (isCampaign ? campaign : user)?.uid === auth.currentUser?.uid)
    );
  }

  const links = (
    <>
      <HighlightedLink to="/rotunda" onClick={onClose}>
        Rotunda
      </HighlightedLink>
      <HighlightedLink to="/explore" onClick={onClose}>
        Explore
      </HighlightedLink>
      <HighlightedLink to="/chat" onClick={onClose}>
        Messages
      </HighlightedLink>
      <HighlightedLink to="/favorites" onClick={onClose}>
        Favorites
      </HighlightedLink>
      {auth.currentUser ? (
        <HStack spacing={5}>
          <HighlightedLink
            to={profilePrefix + auth.currentUser?.uid}
            isActive={isCurrentProfile}
            onClick={onClose}
          >
            Profile
          </HighlightedLink>
          <Button variant="outline" onClick={async () => await logout()} mr={4}>
            Log Out
          </Button>
        </HStack>
      ) : (
        <HStack spacing={0}>
          <Button
            variant="outline"
            onClick={() => history.push("/login?redirect=" + pathname)}
            mr={4}
          >
            Log In
          </Button>
          {/* history.push("/intro") when we fix Stripe */}
          <Button variant="lavender" onClick={() => history.push("/intro")}>
            Join Politiq
          </Button>
        </HStack>
      )}
    </>
  );

  return (
    <Box minH="100vh">
      <Box
        w="100%"
        bg="white"
        h="80px"
        px={{ base: "3%", lg: "max(1%, min(12%, 50% - 500px))" }}
        top={0}
        position="fixed"
        zIndex="sticky"
        boxShadow="md"
      >
        <Box position="relative" top="50%" transform="translateY(-50%)">
          <Flex>
            <HStack spacing={{base: "4", sm: "6" }} mr={{base: "4", sm: "0" }}>
              <NavLink to="/">
                <Image src={logo} w={{base: "16", sm: "24" }} />
              </NavLink>
              <form onSubmit={handleSubmit(handleSearch)}>
                <InputGroup>
                  <InputLeftElement>
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    bg="politiq.lightgray"
                    align={{base: "center", sm: "none"}}
                    px={2}
                    w={{base: "60", sm: "72" }}
                    focusBorderColor="politiq.lavender"
                    placeholder="Search"
                    {...register("search")}
                  />
                </InputGroup>
              </form>
            </HStack>
            <Spacer />
            <DesktopOnly>
              <HStack spacing={8}>{links}</HStack>
            </DesktopOnly>
            <MobileOnly>
              <HamburgerIcon
                boxSize={12}
                color="politiq.darkgray"
                onClick={onOpen}
                cursor="pointer"
                _focus={{ outline: "0 !important" }}
              />
              <Drawer size="full" isOpen={isOpen} onClose={onClose}>
                <DrawerContent>
                  <DrawerCloseButton size="lg" />
                  <DrawerBody pt={4}>
                    <VStack align="left">
                      <Image src={logo} w={24} />
                      {links}
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </MobileOnly>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export default function NavbarPage(props: BoxProps) {
  // renders a navbar and also the component's children underneath the navbar
  return (
    <>
      <Navbar />
      <Box
        bg="politiq.brightgray"
        position="absolute"
        top="80px"
        w="100%"
        bottom={0}
        overflowX="hidden"
        overflowY="auto"
        {...props}
      />
    </>
  );
}

import React, { useContext } from "react";
import NewsArticleTab from "./NewsArticleTab";
import { NewsContext } from "./NewsProvider";
import { Box, Link, Text, Flex } from "@chakra-ui/react";
import * as Types from "../../../../types/index";

export default function NewsSuggest() {
  const { bizNews, poliNews } = useContext(NewsContext);

  // headline political article does not repeat
  const slicePoliNews = poliNews[0] ? poliNews.slice(1) : poliNews;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box alignItems="start">
        <Text
          fontSize="34px"
          w="90%"
          ml={{ base: "30px", sm: "0%" }}
          fontWeight="bold"
          alignSelf="flex-start"
          mb="1em"
        >
          News
        </Text>
        <Flex
          position="relative"
          flexDirection="column"
          justifyContent="space-between"
          w={{ cm: "1280px" }}
        >
          {slicePoliNews.map((article: Types.newsArticles) => (
            <Link
              href={article.url}
              style={{ textDecoration: "none" }}
              target="_blank"
              w={{ base: "400px", cm: "100%" }}
            >
              <NewsArticleTab
                articleSource={article.source.name}
                articleTitle={article.title}
                articleImg={article.urlToImage}
                articleText={article.content}
                publishDate={article.publishedAt}
                genre="politics"
              ></NewsArticleTab>
            </Link>
          ))}
          {bizNews.map((article: Types.newsArticles) => (
            <Link
              href={article.url}
              style={{ textDecoration: "none" }}
              target="_blank"
              w={{ base: "400px", cm: "100%" }}
            >
              <NewsArticleTab
                articleSource={article.source.name}
                articleTitle={article.title}
                articleImg={article.urlToImage}
                articleText={article.content}
                publishDate={article.publishedAt}
                genre="business"
              ></NewsArticleTab>
            </Link>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}

import axios from "axios";

import { Experience, User } from "types";
import { Thunkction } from "..";
import { auth } from "../../firebase";
import { getCampaign } from "./campaign";

export enum UserActionType {
  FETCH_USER = "FETCH_USER",
  FETCHED_USER = "FETCHED_USER",
  FAILED_USER = "FAILED_USER",
}

export type UserAction =
  | {
      type: UserActionType.FETCH_USER;
    }
  | {
      type: UserActionType.FETCHED_USER;
      payload: User;
    }
  | {
      type: UserActionType.FAILED_USER;
      payload: string;
    };

const fetchUser = (): UserAction => {
  return {
    type: UserActionType.FETCH_USER,
  };
};

const fetchedUser = (payload: User): UserAction => {
  return {
    type: UserActionType.FETCHED_USER,
    payload: payload,
  };
};

const failedFetchUser = (payload): UserAction => {
  return {
    type: UserActionType.FAILED_USER,
    payload: payload,
  };
};

export const getUser =
  (id: string, setLoading: boolean = false): Thunkction =>
  async (dispatch) => {
    if (setLoading) dispatch(fetchUser());
    try {
      const response = await axios.get("/user", {
        params: { uid: id, detailLevel: 1 },
      });
      await dispatch(fetchedUser(response.data as User));
    } catch (err: any) {
      dispatch(failedFetchUser(err.message));
    }
  };

// export const getFavorites =
//   (user: User): Thunkction =>
//   async () => {
//     try {
//       const response = await axios.post("/user/getFavorites", {
//         campaigns: user.favoriteCampaigns,
//         jobListings: user.favoriteJobs,
//         users: user.favoriteUsers,
//       });
//       user.favoriteCampaigns = response.data.campaigns;
//       user.favoriteJobs = response.data.jobListings;
//       user.favoriteUsers = response.data.users;
//     } catch (err: any) {}
//   };

export const updateUserProfile =
  (data: Partial<User>): Thunkction =>
  async (dispatch) => {
    await axios.post("/user/profile", { ...data });
    await dispatch(getUser(auth.currentUser?.uid as string));
  };

// export const addFavoriteJob =
//   (jobListing: string): Thunkction =>
//   async () => {
//     await axios.post("/user/profile", { favoriteJobs: [jobListing] });
//   };
// export const addFavoriteCampaign =
//   (campaign: string): Thunkction =>
//   async () => {
//     await axios.post("/user/profile", { favoriteCampaigns: [campaign] });
//   };
// export const addFavoriteUser =
//   (user: string): Thunkction =>
//   async () => {
//     await axios.post("/user/profile", { favoriteUsers: [user] });
//   };
// export const deleteFavoriteCampaign =
//   (campaign: string): Thunkction =>
//   async () => {
//     await axios.post("/user/delete", { favoriteCampaigns: [campaign] });
//   };
// export const deleteFavoriteJob =
//   (jobListing: string): Thunkction =>
//   async () => {
//     await axios.post("/user/delete", { favoriteJobs: [jobListing] });
//   };
// export const deleteFavoriteUser =
//   (user: string): Thunkction =>
//   async () => {
//     await axios.post("/user/delete", { favoriteUsers: [user] });
//   };
export const updateUserProfilePic =
  (url: string): Thunkction =>
  async () => {
    const data = new FormData();
    data.append("profilePic", await (await fetch(url)).blob());
    await axios.post("/user/profilePic", data);
  };

export const updateUserResume =
  (resumeLink: string): Thunkction =>
  async () => {
    const data = new FormData();
    data.append("resume", await (await fetch(resumeLink)).blob());
    await axios.post("/user/resume", data);
  };

export const updateUserExperience =
  (experience: Experience, imageURL?: string): Thunkction =>
  async (dispatch) => {
    const data = new FormData();
    if (imageURL) {
      data.append("experienceImage", await (await fetch(imageURL)).blob());
    }
    for (const field in experience) {
      data.append(field, experience[field]);
    }
    await axios.post("/user/experience", data);
    await dispatch(getUser(auth.currentUser?.uid as string));
  };

export const deleteUserProfile =
  (data: { [field: string]: string[] }): Thunkction =>
  async (dispatch) => {
    await axios.post("/user/delete", data);
    await dispatch(getUser(auth.currentUser?.uid as string));
  };

export const addUserEndorsement =
  (
    recipientId: string,
    visibleEndorsements: number,
    endorsement?: {
      relationship: string;
      message: string;
    }
  ): Thunkction =>
  async (dispatch) => {
    await axios.post("/user/addUserEndorsement", {
      ...(endorsement && {
        endorsement: {
          relationship: endorsement.relationship ?? "",
          message: endorsement.message,
          endorser: auth.currentUser?.uid,
          dateGiven: new Date().toISOString(),
          isHidden: visibleEndorsements >= 3,
        },
      }),
      recipientId,
    });
    await dispatch(getUser(recipientId));
  };

export const sendJobApp =
  (jobId: string, campaign: string): Thunkction =>
  async (dispatch) => {
    const data = { job: jobId };
    await axios.post("/user/applyjob", data);
    dispatch(getCampaign(campaign));
  };

export const joinCampaignTeam =
  (campaign: string): Thunkction =>
  async (dispatch) => {
    const data = { campaign };
    await axios.post("/user/joinCampaignTeam", data);
    await dispatch(getCampaign(campaign));
  };

export const leaveCampaignTeam =
  (campaign: string): Thunkction =>
  async (dispatch) => {
    const data = { campaign };
    await axios.post("/user/leaveCampaignTeam", data);
    await dispatch(getCampaign(campaign));
  };

import {
    Box,
    Button,
    Heading,
    Tooltip,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    chakra
} from "@chakra-ui/react";
import { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import * as Router from "react-router-dom";
import { auth } from "../../firebase";

const ChakraLink = chakra(Router.Link);

export default function ForgotPassword() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const nextStep = () => {
        passwordReset(input);
    };

    function passwordReset(email) {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                setIsSuccess(true)
                history.push("/login");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode + "," + errorMessage + ", email not sent");
            });
    };

    const [input, setInput] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const handleInputChange = (e) => setInput(e.target.value)

    const isError = input === ''

    return auth.currentUser ? (
        <Redirect to="/rotunda" />
    ) : (
        <Box position="relative" height="100%">
            <Tooltip
                label="Forgot your password? Send a password reset email here."
                placement="auto-start"
                aria-label="A tooltip"
            >
                <Heading color="politiq.lavender" fontSize="2xl" pb={6}>
                    Enter the email associated with your Politiq account to reset your password.
                </Heading>
            </Tooltip>
            <FormControl isInvalid={isError}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                    id='email'
                    type='email'
                    value={input}
                    onChange={handleInputChange}
                />
                {isSuccess ? (
                    <FormHelperText>
                        Password reset email sent! Check your email to reset your password.
                    </FormHelperText>
                ) : !isError ? (
                    <FormHelperText>
                        Enter the email for your Politiq account.
                    </FormHelperText>
                ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                )}
            </FormControl>
            <Box right="0" pos={{ lg: "absolute" }} bottom="0" w="100%" mt={{sm: 4, md: 4, lg: 0}}>
                <Button
                    onClick={() => {
                        nextStep();
                    }}
                    variant="lavender"
                    width="100%"
                    isLoading={loading}
                >
                    Send password reset email
                </Button>
                <Box align="center" mt={6}>
                    Don't want to reset your password?{" "}
                    <ChakraLink color="politiq.lavender" to="/login">
                        Sign in.
                    </ChakraLink>
                </Box>
            </Box>
        </Box>
    );
}
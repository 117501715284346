import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import { Box } from "@chakra-ui/react";
import { auth } from "../../firebase";

export default function FavButton({
  type,
  initialFavorited = false,
  customOnClick,
  id,
  overrideButton = false,
}: {
  type: string;
  initialFavorited?: boolean;
  customOnClick?: (selected: boolean) => void;
  id?: string;
  overrideButton?: boolean;
}) {
  const [favorited, setFavorited] = useState(initialFavorited);
  // console.log(id, " started at ", initialFavorited);
  const handleChange = (event) => {
    // event.preventDefault();
    if (!overrideButton) {
      setFavorited(event.target.checked);
    }
    if (customOnClick !== undefined) {
      customOnClick(favorited);
    }
  };
  return auth.currentUser?.uid ? (
    <Box margin="auto" display="block" width="fit-content">
      <Checkbox
        icon={<StarBorder style={{ fill: "#5918f2" }} />}
        checkedIcon={<Star style={{ fill: "#5918f2" }} />}
        name="checkedH"
        inputProps={{ "aria-label": "controlled" }}
        defaultChecked={initialFavorited}
        checked={favorited}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      {/* <FormControlLabel
        onChange={(e) => {
          console.log("on change");
          // e.preventDefault();
          handleOnClick();
          // e.persi
        }}
        control={
          <Checkbox
            icon={<StarBorder style={{ fill: "#5918f2" }} />}
            checkedIcon={<Star style={{ fill: "#5918f2" }} />}
            name="checkedH"
            // checked={favorited}
          />
        }
        label=""
      /> */}
    </Box>
  ) : (
    <></>
  );
}

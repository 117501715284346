import { Action, configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import reducer from "./reducers";

export const store = configureStore({
  reducer,
  middleware: (getDefMiddle) => getDefMiddle().prepend(thunkMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type Thunkction = (dispatch: AppDispatch) => Promise<unknown>;

export type ActionCreator<T> = (payload?: T) => Action;
